/* eslint-disable no-undef */
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Cookies from 'cookies-js';
import mixpanel from 'mixpanel-browser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as moment from 'moment';
import Identicon from 'identicon.js';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Flex, Image, Paragraph, Textarea } from 'theme-ui';
import { actions as userActions } from '../store/reducers/users';
import { NotificationsIcon } from './notifications';
import NotificationsPanel from './notifications-panel';
import { ChatBot } from './chat/chatbot';
import theme from '../theme';
import Overlay from './overlay/overlay';
import Divider from './divider';
import SendMessage from './portal/send-message';
import MainLogo from './main-logo';
import BookTime from './time/book';
import ManagePlanModal from './manage-plan-modal';

const loggedOutState = () => {
    if (
        window.location.href.includes('reset-password') ||
        window.location.pathname === '/' ||
        window.location.href.includes('verify') ||
        window.location.href.includes('magic-links')
    ) {
        return true;
    }

    return false;
};

const CookieBanner = ({ toggleCookiesEnabled }) => (
    <Flex
        sx={{
            background: '#2B373B',
            height: [120, 100],
            fontSize: 18,
            position: 'fixed',
            bottom: 0,
            width: '100%',
            zIndex: 99999999999,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 20,
        }}
    >
        <Paragraph sx={{ ml: 20, width: ['60%', '80%'], color: '#FFFFFF', fontSize: '14px' }}>
            This website uses cookies to enhance the user experience.
        </Paragraph>
        <Button
            data-testid="cookie-accept"
            sx={{ borderRadius: 12, color: 'primary' }}
            onClick={() => {
                localStorage.setItem('AFCookieConsent', 'yes', {});
                toggleCookiesEnabled(true);
                mixpanel.init('58f66d6f268affd34aaab754636a18ae', {
                    debug: true,
                    track_pageview: true,
                    persistence: 'localStorage',
                });
            }}
            variant="secondary"
        >
            Accept
        </Button>
    </Flex>
);

export const queryDocument = async (state, updateState, stateRef) => {
    const newState = {
        ...stateRef?.current,
        loading: true,
        chatBotAnswer: '',
        chatBotError: '',
        documentToQuery: {
            ...stateRef?.current.documentToQuery,
            history: [
                ...stateRef.current.documentToQuery.history,
                {
                    type: 'question',
                    text: stateRef?.current.documentQuery,
                    timestamp: moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                },
            ],
        },
    };

    updateState({ ...newState, documentQuery: '', error: null });

    try {
        let [
            {
                data: { answer, resultsSetType, recordType, record_ids, results },
            },
            { data: organisation },
        ] = await Promise.all([
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_AQRU_AI_API}/chats/talk`,
                data: {
                    chat_group_id: stateRef?.current.chat_group_id,
                    question: stateRef?.current.documentQuery,
                },
            }),
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            }),
        ]);

        if (!answer?.length) {
            answer = "I've not found anything for that, trying rephrasing the question";
        }

        let answerContainsComponent = false;
        if (
            answer?.length === 1 &&
            answer[0]?.[0] === null &&
            resultsSetType === 'single' &&
            recordType === 'clients'
        ) {
            answer = (
                <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                    <Paragraph sx={{ fontSize: 13 }}>There is no value stored for that currently</Paragraph>
                    <a target="_blank" rel="noreferrer" href={`/clients?id=${record_ids?.[0]}`}>
                        <Button sx={{ width: 150, mt: 10, height: 40, fontSize: 14 }}>Click here to Add</Button>
                    </a>
                </Flex>
            );
            answerContainsComponent = true;
        }

        if (
            answer?.[0] &&
            recordType === 'open_client_record' &&
            !answer?.[0]?.includes('currently have an answer for that') &&
            !answer?.[0]?.includes('undefined')
        ) {
            const clientId = answer?.[0];
            answer = (
                <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                    <a target="_blank" rel="noreferrer" href={`/clients?id=${clientId}`}>
                        <Button sx={{ width: 150, height: 40, fontSize: 14 }}>Open Client</Button>
                    </a>
                </Flex>
            );
            answerContainsComponent = true;
            const a = document.createElement('a');
            a.target = '_blank';
            a.href = `/clients?id=${clientId}`;

            a.click();
        }

        updateState({
            ...newState,
            loading: false,
            chatBotError: '',
            documentQuery: '',
            chatBotAnswer: answer,
            documentToQuery: {
                ...newState.documentToQuery,
                history: [
                    ...newState.documentToQuery.history,
                    {
                        type: 'answer',
                        text: answer,
                        answerContainsComponent,
                        organisation,
                        resultsSetType,
                        recordType,
                        timestamp: moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                    },
                ],
            },
        });
    } catch (e) {
        const error =
            "I don't seem to have an answer for that right now, if this problem persists please contact support";
        updateState({
            ...newState,
            loading: false,
            chatBotError: '',
            documentQuery: '',
            chatBotAnswer: error,
            documentToQuery: {
                ...newState.documentToQuery,
                history: [
                    ...newState.documentToQuery.history,
                    {
                        type: 'answer',
                        text: error,
                        timestamp: moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                    },
                ],
            },
        });
    }
};

const AppWrapper = ({ children, resize, timestampOfLastDataLoad, organisation, user }) => {
    const [cookiesEnabled, toggleCookiesEnabled] = useState(
        window.localStorage && window.localStorage.getItem('AFCookieConsent') === 'yes'
    );

    const lastLoad = localStorage.getItem('last_load');

    let showNotificationsByDefault = false;

    const twoHoursInMillis = 1000 * 60 * 60 * 2;

    if (!lastLoad || new Date().getTime() - parseInt(lastLoad, 10) > twoHoursInMillis) {
        showNotificationsByDefault = true;
        localStorage.setItem('last_load', new Date().getTime() + '');
    }

    const [showNotificationsPanel, toggleNotificationsPanel] = useState(showNotificationsByDefault);
    const [showChatMini, toggleChatMini] = useState(false);

    window.toggleChatMini = () => toggleChatMini(!showChatMini);

    const [state, updateState] = useState({
        organisation,
        isLoading: false,
        notifications: [],
        documentToQuery: {
            history: [
                {
                    text: `<div><p>Hey there! I'm your friendly AI assistant.</p>
                    <p>I'm here to make your life easier – no paperwork, no waiting on hold, just good ol' instant answers and support. You can ask me anything, but here are a few starters to break the ice:</p>
                    <ul class="no-bullets">
                        <li>🔍 "Find client info faster than you can say 'spreadsheet' – just ask to open up {clientName}."</li>
                        <li>🏢 "Need a company number for {clientName}? I've got it in a jiffy!"</li>
                        <li>💼 "Curious about VAT obligations? I can pull that up too – and no, I won’t judge your VAT history."</li>
                    </ul></div>
                    `
                        .replace(/\s+/g, ' ')
                        .trim(),
                },
            ],
        },
    });

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const handleFocus = () => {
        if (window.location.href?.includes('magic-links')) return;

        const secondsSinceLastRefresh = timestampOfLastDataLoad
            ? moment.utc().diff(timestampOfLastDataLoad, 'seconds')
            : 0;

        const THIRTY_MINS_IN_SECONDS = 60 * 30;
        if (secondsSinceLastRefresh > THIRTY_MINS_IN_SECONDS) {
            // ping to cause 401 failure and redirect if token expired
            (async () => {
                try {
                    await axios({
                        url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
                    });
                } catch (e) {
                    try {
                        await Auth.signOut();
                    } catch (ex) {
                        // swallow
                    }
                    window.location.assign('/');
                }
            })();
        }
    };

    useEffect(() => {
        window.addEventListener('focus', handleFocus);
        return () => {
            return () => {
                window.removeEventListener('focus', handleFocus);
            };
        };
    }, [timestampOfLastDataLoad]);

    useEffect(() => {
        if (cookiesEnabled) {
            mixpanel.init('58f66d6f268affd34aaab754636a18ae', {
                debug: true,
                track_pageview: true,
                persistence: 'localStorage',
            });
        }

        window.onerror = function (message, source, lineno, colno, error) {
            mixpanel.track('default_onerror', {
                message,
                source,
                lineno,
                colno,
                error,
            });

            return true;
        };

        window.onunhandledrejection = function (event) {
            mixpanel.track('onunhandledrejection', {
                reason: event.reason,
            });

            return true;
        };

        window.addEventListener('resize', resize);
        return () => window.addEventListener('resize', resize);
    }, []);

    let userLabel = '';
    if (organisation?.user?.first_name && organisation?.user?.last_name) {
        userLabel = `${organisation?.user?.first_name} ${organisation?.user?.last_name}`;
    }

    useEffect(() => {
        (async () => {
            if (organisation?.user && organisation?.user_type === 'org_user') {
                const { data } = await axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/notifications`,
                });
                updateState({ ...state, notifications: data.notifications });
            }
        })();
        (async () => {
            if (organisation?.user && organisation?.user_type === 'client_user') {
                const { data } = await axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/notifications/portal`,
                });
                updateState({ ...state, notifications: data.notifications });
            }
        })();
    }, [organisation, showNotificationsPanel]);

    const avatarSet = organisation?.avatarData || organisation?.user?.email_address;

    useEffect(() => {
        if (state.showSendMsgModal || state.showBookTimeModal) {
            // Prevent background scrolling
            document.body.style.overflow = 'hidden';
            document.body.classList.add('no-scroll'); // Fallback for additional enforcement
        } else {
            // Restore background scrolling
            document.body.style.overflow = '';
            document.body.classList.remove('no-scroll');
        }

        // Cleanup when modal closes
        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('no-scroll');
        };
    }, [state.showSendMsgModal, state.showBookTimeModal]);

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            {state.showSendMsgModal && (
                <Overlay
                    copy={{}}
                    maxWidth={500}
                    maxHeight={380}
                    embeddedComponent={<SendMessage state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showSendMsgModal: false })}
                />
            )}
            {state.showBookTimeModal && (
                <Overlay
                    copy={{}}
                    maxWidth={630}
                    maxHeight={820}
                    embeddedComponent={<BookTime organisation={organisation} state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showBookTimeModal: false })}
                />
            )}

            {!loggedOutState() && !organisation ? (
                <Flex
                    sx={{
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        right: 0,
                        zIndex: 10000000000,
                        backgroundColor: 'greyBackground',
                        height: 100,
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        alignItems: 'center',
                    }}
                >
                    <Flex sx={{ ml: 20 }}>
                        <MainLogo organisation={organisation} />
                    </Flex>
                </Flex>
            ) : null}

            {state.showManagePlanModal && (
                <Overlay
                    copy={{}}
                    maxWidth={900}
                    maxHeight={705}
                    embeddedComponent={
                        <ManagePlanModal onClose={() => updateState({ ...state, showManagePlanModal: false })} />
                    }
                    updateOverlay={() => updateState({ ...state, showManagePlanModal: false })}
                />
            )}

            {organisation?.user_type === 'client_user' && (
                <>
                    <Flex
                        sx={{
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            right: 0,
                            zIndex: 10000000000,
                            height: 100,
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            alignItems: 'center',
                            backgroundColor: 'background',
                        }}
                    >
                        <Flex sx={{ ml: 20 }}>
                            <MainLogo organisation={organisation} />
                        </Flex>
                        <Flex sx={{ mr: 20 }}>
                            <Image
                                sx={{ width: 40, height: 40, marginRight: 10 }}
                                onClick={() => {
                                    toggleChatMini(false);
                                    toggleNotificationsPanel(!showNotificationsPanel);
                                }}
                                src={'/buttons/notification.png'}
                            />
                            <Image
                                sx={{ width: 40, height: 40, marginRight: 10 }}
                                onClick={() => updateState({ ...state, showSendMsgModal: true })}
                                src={'/buttons/message.png'}
                            />
                            <Image
                                sx={{ width: 40, height: 40, marginRight: 10 }}
                                onClick={async () => {
                                    try {
                                        await Auth.signOut();
                                    } catch (ex) {
                                        // swallow
                                    }
                                    window.location.assign('/');
                                }}
                                src={'/buttons/sign-out.png'}
                            />
                        </Flex>
                    </Flex>
                    {showNotificationsPanel && (
                        <Flex sx={{ position: 'fixed', bottom: 0, right: '10px', zIndex: 10000000 }}>
                            <NotificationsPanel
                                userType={organisation?.user_type}
                                toggleNotificationsPanel={() => toggleNotificationsPanel(!toggleNotificationsPanel)}
                                state={state}
                                updateState={updateState}
                            />
                        </Flex>
                    )}
                </>
            )}
            {organisation?.user_type === 'org_user' ? (
                <>
                    <Flex
                        id="logo_wrapper"
                        sx={{
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            right: 0,
                            zIndex: 1000000,
                            backgroundColor: 'greyBackground',
                            height: 100,
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            alignItems: 'center',
                        }}
                    >
                        <Flex sx={{ ml: 20 }}>
                            <MainLogo organisation={organisation} />
                        </Flex>
                        <Flex sx={{ alignItems: 'center' }}>
                            {organisation?.user?.role === 'admin' && !organisation.is_paying_customer ? (
                                <Button
                                    sx={{ mr: 10 }}
                                    onClick={() => updateState({ ...state, showManagePlanModal: true })}
                                    variant="light"
                                >
                                    <i
                                        className="fal fa-question"
                                        style={{
                                            marginRight: '7px',
                                        }}
                                    />
                                    Manage Plan
                                </Button>
                            ) : null}
                            <Button sx={{ mr: 10 }} onClick={() => window.location.assign('/help')} variant="light">
                                <i
                                    className="fal fa-question"
                                    style={{
                                        marginRight: '7px',
                                    }}
                                />
                                Help
                            </Button>
                            <Button
                                sx={{ mr: 10 }}
                                onClick={() => updateState({ ...state, showBookTimeModal: true })}
                                variant="light"
                            >
                                <i
                                    className="fal fa-plus-circle"
                                    style={{
                                        marginRight: '7px',
                                    }}
                                />
                                Time
                            </Button>
                            <Button
                                variant="light"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    toggleNotificationsPanel(false);
                                    toggleChatMini(!showChatMini);
                                }}
                                sx={{ mt: '5px', mr: 20 }}
                            >
                                <i className="fa fa-comments" style={{ marginRight: 7 }} />
                                Chat
                            </Button>
                            <NotificationsIcon
                                avatarSet={avatarSet}
                                customWrapperSx={{ mt: '5px' }}
                                onClick={() => {
                                    toggleChatMini(false);
                                    toggleNotificationsPanel(!showNotificationsPanel);
                                }}
                                count={state.notifications?.filter((x) => x.status === 'new')?.length || 0}
                            />

                            {avatarSet && (
                                <Image
                                    onClick={() => window.location.assign('/settings')}
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        mt: '0px',
                                        mr: 20,
                                        border: `1px solid ${theme.colors.gold}`,
                                        borderRadius: 50,
                                    }}
                                    src={
                                        organisation?.avatarData ||
                                        `data:image/png;base64,${new Identicon(organisation?.user?.email_address)}`
                                    }
                                    title={userLabel}
                                    alt="avatar"
                                />
                            )}
                        </Flex>
                    </Flex>
                    {showChatMini && (
                        <Flex
                            sx={{
                                height: '600px',
                                position: 'fixed',
                                bottom: '0',
                                right: '10px',
                                backgroundColor: 'white',
                                border: '1px solid #E0E0E0',
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20,
                                pb: '30px',
                                zIndex: 99999,
                            }}
                        >
                            <i
                                onClick={() => toggleChatMini(false)}
                                style={{
                                    zIndex: 99999,
                                    right: '15px',
                                    top: '15px',
                                    cursor: 'pointer',
                                    color: theme.colors.primary,
                                    position: 'absolute',
                                }}
                                className="fa fa-window-close"
                            />
                            <ChatBot
                                state={state}
                                updateState={updateState}
                                docIds={[]}
                                user={{
                                    ...user,
                                    email: organisation?.user?.email_address,
                                }}
                                stateRef={stateRef}
                                queryDocument={queryDocument}
                                type="clients"
                            />
                        </Flex>
                    )}

                    {showNotificationsPanel && (
                        <Flex sx={{ position: 'fixed', bottom: 0, right: '10px', zIndex: 10000000 }}>
                            <NotificationsPanel
                                userType={organisation?.user_type}
                                toggleNotificationsPanel={() => toggleNotificationsPanel(!toggleNotificationsPanel)}
                                state={state}
                                updateState={updateState}
                            />
                        </Flex>
                    )}
                </>
            ) : null}
            <Flex sx={{ mt: organisation?.user_type === 'client_user' || loggedOutState() ? 0 : 100 }}>
                {children}
                <ToastContainer style={{ zIndex: 1000000000000 }} />
            </Flex>
            {!cookiesEnabled && <CookieBanner toggleCookiesEnabled={toggleCookiesEnabled} />}
        </Flex>
    );
};

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        organisation: account.organisation,
        user: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
    };
};

const mapDispatchToProps = (dispatch) => ({
    resize: () => dispatch({ type: userActions.RESIZE }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
