/* eslint-disable no-undef */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment';
import { sortOptions, sortTasks } from './filters';
import { toast } from 'react-toastify';

export const saveComment = async (state, updateState, page = 'task') => {
    try {
        if (page === 'task' && !state.fullTaskData?.title) {
            return updateState({
                ...state,
                loading: false,
                commentError: 'Please provide a title first for the task',
            });
        }

        if (!state.newCommentText) {
            return updateState({
                ...state,
                loading: false,
                commentError: 'Please add some text for your comment',
            });
        }

        updateState({
            ...state,
            loading: true,
            overlayError: null,
            commentError: null,
        });

        if (page === 'task' && !state.task?.id) {
            const {
                data: { id },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/tasks`,
                method: 'POST',
                data: {
                    title: state.fullTaskData?.title,
                    description: state.fullTaskData?.description,
                },
            });

            updateState({
                ...state,
                loading: true,
                commentError: null,
                task: {
                    id,
                },
            });
            state.task = { id };
        }

        const entity = page === 'task' ? 'tasks' : 'clients';
        const id = page === 'task' ? state.task.id : state.clientInView.id;

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/${entity}/${id}/comments`,
            method: 'POST',
            data: {
                content: state.newCommentText,
            },
        });

        const {
            data: { comments, total },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/${entity}/${id}/comments`,
            params: { limit: state.commentsLimit, offset: state.taskCommentsOffset || 0 },
        });

        updateState({
            ...state,
            loading: false,
            overlayError: null,
            showSubmitCommentForm: false,
            newCommentText: '',
            comments,
            totalComments: total,
        });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            overlayError: 'Uh oh, there has been an error, please try again later',
        });
    }
};

export const deleteComment =
    (id, state, updateState, page = 'task') =>
    async () => {
        try {
            updateState({
                ...state,
                loading: true,
                overlayError: null,
            });

            const entity = page === 'task' ? 'tasks' : 'clients';
            const entityId = page === 'task' ? state.task.id : state.clientInView.id;

            await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/${entity}/${entityId}/comments/${id}`,
                method: 'DELETE',
            });

            const {
                data: { comments, total },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/${entity}/${entityId}/comments`,
                params: { limit: state.commentsLimit, offset: state.taskCommentsOffset || 0 },
            });

            updateState({
                ...state,
                loading: false,
                overlayError: null,
                showSubmitCommentForm: false,
                showDeleteCommentConfirmation: false,
                newCommentText: '',
                comments,
                taskCommentsOffset: total <= state.commentsLimit ? 0 : state.taskCommentsOffset,
                totalComments: total,
            });
        } catch (e) {
            console.log(e);
            updateState({
                ...state,
                loading: false,
                overlayError: 'Uh oh, there has been an error, please try again later',
            });
        }
    };

export const deleteTask = (state, updateState, refreshCallback) => async () => {
    try {
        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/tasks/${state.task.id}`,
            method: 'DELETE',
        });

        window.location.assign('/tasks');
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            overlayError: 'Uh oh, there has been an error, please try again later',
        });
    }
};

export const completeDrag = async (state, updateState, taskId, newStatus, refreshCallback) => {
    updateState({
        ...state,
        loading: true,
        overlayError: null,
    });

    try {
        const url = `${process.env.REACT_APP_AQRU_AI_API}/tasks/${taskId}`;

        const { data: existingData } = await axios({
            url,
        });

        await axios({
            url,
            method: 'PUT',
            data: {
                ...existingData,
                client_id: existingData?.client?.raw_id,
                status: newStatus,
            },
        });

        const tempState = {
            ...state,
            mode: 'list',
            comments: [],
            overlayError: null,
        };

        updateState(tempState);

        await refreshCallback(
            {
                ...tempState,
            },
            updateState
        );
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            overlayError: 'Uh oh, there has been an error, please try again later',
        });
    }
};

export const fireAndForgetSave = async (state, updateState, keyValuePairs) => {
    try {
        const payloadStringified = JSON.stringify(state.fullTaskData);

        if (state.lastPayload && state.lastPayload === payloadStringified) {
            return;
        }

        const url =
            state.mode === 'new'
                ? `${process.env.REACT_APP_AQRU_AI_API}/tasks`
                : `${process.env.REACT_APP_AQRU_AI_API}/tasks/${state.task.id}`;

        const { data: apiResponse } = await axios({
            url,
            method: state.mode === 'new' ? 'POST' : 'PUT',
            data: {
                ...keyValuePairs,
            },
        });

        const newState = {
            ...state,
            lastPayload: state.mode === 'new' ? null : payloadStringified,
            mode: 'update',
            lastAutoSaved: moment().format('HH:mm:ss'),
        };

        if (state.mode === 'new') {
            newState.task = {
                id: apiResponse.id,
            };
        }

        updateState(newState);
    } catch (e) {
        console.log('Error', e);
    }
};

export const updateFields = async (state, updateState, keyValuePairs, refreshCallback) => {
    updateState({
        ...state,
        loading: true,
        error: null,
        overlayError: null,
    });

    if (!keyValuePairs?.title) {
        return updateState({
            ...state,
            loading: false,
            overlayError: 'Please enter a title',
        });
    }

    try {
        const url =
            state.mode === 'new'
                ? `${process.env.REACT_APP_AQRU_AI_API}/tasks`
                : `${process.env.REACT_APP_AQRU_AI_API}/tasks/${state.task.id}`;

        const res = await axios({
            url,
            method: state.mode === 'new' ? 'POST' : 'PUT',
            data: {
                ...keyValuePairs,
            },
        });

        const tempState = {
            ...state,
            fullTaskData: {
                ...state.fullTaskData,
                ...keyValuePairs,
            },
            error: null,
            lastAutoSaved: moment().format('HH:mm:ss'),
            ...(state.mode === 'new'
                ? {
                      task: {
                          id: res.data.id,
                      },
                      mode: 'update',
                  }
                : {}),
        };

        tempState.lastPayload = JSON.stringify(tempState.fullTaskData);

        updateState(tempState);
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            overlayError: 'Uh oh, there has been an error, please try again later',
        });
    }
};

export const cloneTask = async (state, updateState, keyValuePairs) => {
    updateState({
        ...state,
        loading: true,
        overlayError: null,
    });

    if (!keyValuePairs?.title) {
        return updateState({
            ...state,
            loading: false,
            overlayError: 'Please enter a title',
        });
    }

    try {
        const res = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/tasks`,
            method: 'POST',
            data: {
                ...keyValuePairs,
                ...(keyValuePairs.title ? { title: `${keyValuePairs.title} - CLONE` } : {}),
            },
        });

        window.location.assign(`/tasks?id=${res.data.id}`);
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            overlayError: 'Uh oh, there has been an error, please try again later',
        });
    }
};

export const loadFullTaskData = async (state, updateState) => {
    updateState({ ...state, loading: true, overlayError: null });

    try {
        const [
            { data: task },
            {
                data: { documents, total: totalDocuments },
            },
            {
                data: { comments, total },
            },
            {
                data: { tasks },
            },
        ] = await Promise.all([
            axios.get(`${process.env.REACT_APP_AQRU_AI_API}/tasks/${state.task.id}`, {}),
            axios.get(`${process.env.REACT_APP_AQRU_AI_API}/tasks/${state.task.id}/documents`, {
                params: { limit: state.documentsLimit, offset: state.taskDocumentsOffset || 0 },
            }),
            axios.get(`${process.env.REACT_APP_AQRU_AI_API}/tasks/${state.task.id}/comments`, {
                params: { limit: state.commentsLimit, offset: state.taskCommentsOffset || 0 },
            }),
            axios.get(`${process.env.REACT_APP_AQRU_AI_API}/organisations/workflow-tasks`, {}),
        ]);

        task.assigned_to_for_component =
            task.relations
                ?.filter((x) => x.type === 'assigned_to')
                ?.map((x) => ({
                    value: x.id,
                    label: x.email_address,
                })) || [];

        task.reviewer_for_component =
            task.relations
                ?.filter((x) => x.type === 'reviewer')
                ?.map((x) => ({
                    value: x.id,
                    label: x.email_address,
                })) || [];

        task.partner_for_component =
            task.relations
                ?.filter((x) => x.type === 'partner')
                ?.map((x) => ({
                    value: x.id,
                    label: x.email_address,
                })) || [];

        if (task.client) {
            task.client_for_component = {
                value: task.client.raw_id,
                label: task.client.name,
                services: task.client.services,
            };
        } else {
            task.client_for_component = {
                value: 'unassigned',
                label: 'Unassigned',
            };
        }

        // if (task.team) {
        //     task.team_for_component = {
        //         value: task.team.id,
        //         label: task.team.name,
        //     };
        // } else {
        //     task.team_for_component = {
        //         value: 'unassigned',
        //         label: 'Unassigned',
        //     };
        // }

        if (task.status) {
            task.status_for_component = {
                value: task.status,
                label: task.organisation.default_visualisation.statuses.find((x) => x.key === task.status)?.label,
            };
        } else {
            task.status_for_component = {
                value: task.organisation.default_visualisation.statuses[0].key,
                label: task.organisation.default_visualisation.statuses[0].label,
            };
        }

        if (task.priority) {
            task.priority_for_component = {
                value: task.priority,
                label: task.organisation.default_visualisation.priorities.find((x) => x.key === task.priority)?.label,
            };
        } else {
            task.priority_for_component = {
                value: task.organisation.default_visualisation.priorities[0].key,
                label: task.organisation.default_visualisation.priorities[0].label,
            };
        }

        let extraData = {};
        if (task.additional_data?.service) {
            const {
                data: { tasks },
            } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/organisations/workflow-tasks`, {
                params: { service: task.additional_data?.service, frequency: 'on_creation' },
            });
            extraData = {
                workflows: tasks?.map((x) => ({
                    value: x.id,
                    label: x.definition?.title,
                })),
                workflowTasks: tasks,
            };
        }

        const fullTaskData = {
            ...task,
            ...extraData,
            workflows: tasks?.map((x) => ({
                value: x.id,
                label: x.definition?.title,
            })),
            workflowTasks: tasks,
        };

        updateState({
            ...state,
            loading: false,
            fullTaskData,
            lastPayload: JSON.stringify(fullTaskData),
            comments,
            overlayError: null,
            totalComments: total,
            documents,
            totalDocuments,
            task_loaded_at: moment(),
        });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            overlayError: 'Uh oh, there has been an error loading your task',
        });
    }
};

export const splitUpTasks = (organisation, tasks, filters) => {
    return organisation?.default_visualisation?.swimlanes?.map((swimlane) => {
        let tasksForSwimlane = [];

        swimlane.statuses.forEach((status) => {
            tasksForSwimlane = [...tasksForSwimlane, ...tasks.filter((task) => task.status === status)];
            if (filters) {
                tasksForSwimlane = sortTasks(tasksForSwimlane, filters, organisation);
            }
        });

        return {
            ...swimlane,
            statuses: organisation?.default_visualisation?.statuses,
            tasks: tasksForSwimlane,
        };
    });
};

export const loadTaskData = async (state, updateState, download = false, clientId, taskId, navigate, userId) => {
    updateState({ ...state, loading: true, error: null });

    const params = { show_my_tasks: state.show_my_tasks === '1', limit: state.maxTasks?.value || 500 };

    if (Array.isArray(state.assignedTo)) {
        params.preparers = state.assignedTo?.map((x) => x.value);
    }

    if (Array.isArray(state.serviceFilter)) {
        params.services = state.serviceFilter?.map((x) => x.value);
    }

    if (Array.isArray(state.reviewers)) {
        params.reviewers = state.reviewers?.map((x) => x.value);
    }

    if (Array.isArray(state.partners)) {
        params.partners = state.partners?.map((x) => x.value);
    }

    if (Array.isArray(state.clientSelected)) {
        params.client_ids = state.clientSelected?.map((x) => x.value);
    }

    if (clientId) {
        params.client_ids = [clientId];
    }

    if (
        state.statutory_due_date &&
        state.statutory_due_date_comparator &&
        state.statutory_due_date !== 'Invalid date'
    ) {
        params.statutory_due_date = state.statutory_due_date;
        params.statutory_due_date_operator = state.statutory_due_date_comparator?.value;
    }

    if (state.internal_due_date && state.internal_due_date_comparator && state.internal_due_date !== 'Invalid date') {
        params.due_date = state.internal_due_date;
        params.due_date_operator = state.internal_due_date_comparator?.value;
    }

    if (state.filter === 'overdue_tasks') {
        params.due_date = moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]');
        params.due_date_operator = '<=';
    }

    if (Array.isArray(state.priority)) {
        params.priority = state.priority?.map((x) => x.value);
    }

    if (Array.isArray(state.statuses)) {
        params.status = state.statuses?.map((x) => x.value);
    }

    if (download) {
        params.download_as_csv = true;
    }

    if (state.show_tasks_with_cloning_setup === '1') {
        params.show_tasks_with_cloning_setup = true;
    }

    if (state.searchByTitle) {
        params.title = state.searchByTitle;
    }

    let additionalStateData = {};

    if (userId) {
        params.preparers = [userId];
        params.reviewers = [userId];
        params.partners = [userId];

        const { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/organisations/users/${userId}?raw_id=1`);

        additionalStateData = {
            assignedTo: [
                {
                    value: userId,
                    label: data.email_address,
                },
            ],
            partners: [
                {
                    value: userId,
                    label: data.email_address,
                },
            ],
            reviewers: [
                {
                    value: userId,
                    label: data.email_address,
                },
            ],
        };
    }

    let extraStateData = {};
    const fakeUpdateState = (updatedState) => {
        extraStateData = {
            showTaskOverlay: true,
            task: updatedState.fullTaskData,
            mode: 'update',
            ...updatedState,
        };
    };

    params.sort_by = [];
    params.sort_by_directions = [];

    if (state.filters?.length) {
        state.filters.forEach((filter) => {
            let sortClause = sortOptions.find((x) => x.value === filter.value)?.sortClause;

            if (!sortClause && filter.isClientKey) {
                if (filter.dataType === 'date') {
                    sortClause = `_cdate_ata_${filter.clientDataKey}`;
                }
                sortClause = `_cdata_${filter.clientDataKey}`;
            }

            if (sortClause) {
                params.sort_by.push(sortClause);
                params.sort_by_directions.push(filter.value.includes('asc') ? 'ASC' : 'DESC');
            }
        });
    }

    if (state.customColumn && state.customColumnFilterValue) {
        params.custom_column = state.customColumn.value;
        params.custom_column_operator = state.customColumnOperator?.value || '=';
        params.custom_column_filter_value = state.customColumnFilterValue.value || state.customColumnFilterValue;
    }

    let statuses = state.statuses;

    try {
        let { data: organisation } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/organisations`);

        if (clientId) {
            statuses = (
                organisation?.default_visualisation?.statuses?.map((x) => ({
                    value: x.key,
                    label: x.label,
                })) || []
            )?.filter((x) => x.value !== 'closed' && x.value !== 'done');

            window.localStorage.setItem('statuses', statuses ? JSON.stringify(statuses) : '');

            params.status = statuses?.map((x) => x.value);
        }

        let [
            {
                data: { tasks, url, download_via_url },
            },
            {
                data: { users },
            },
            currentAuthenticatedUser,
        ] = await Promise.all([
            axios.post(`${process.env.REACT_APP_AQRU_AI_API}/tasks/search`, params),
            axios.get(`${process.env.REACT_APP_AQRU_AI_API}/organisations/users?limit=200`),
            Auth.currentAuthenticatedUser(),
            taskId
                ? loadFullTaskData(
                      {
                          task: {
                              id: taskId,
                          },
                      },
                      fakeUpdateState
                  )
                : Promise.resolve(),
        ]);

        if (download) {
            const { data } = await axios.get(url);

            updateState({ ...state, loading: false, error: null, ...additionalStateData });

            const encoded = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);

            const a = document.createElement('a');
            a.href = encoded;
            a.download = 'Tasks.csv';
            return a.click();
        }

        if (download_via_url) {
            const { data } = await axios.get(url);
            const s3Payload = data;
            tasks = s3Payload.tasks;
            organisation = s3Payload.organisation;
        }

        let clientName;

        if (clientId) {
            const { data: client } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}`, {
                params: {
                    raw_id: '1',
                },
            });
            clientName = client.name;
        }

        const statusOrder = {};

        organisation?.default_visualisation?.statuses?.forEach((x, idx) => {
            statusOrder[x.key] = idx + 1;
        });

        updateState({
            ...state,
            statuses,
            loading: false,
            organisation,
            userIdOfLoggedInUser: currentAuthenticatedUser?.username,
            users,
            tasks,
            clientSelected: clientId ? [{ value: clientId, label: clientName }] : state.clientSelected,
            error: null,
            swimlanes: splitUpTasks(organisation, tasks, state.filters || []),
            tasks_for_list: sortTasks(tasks, state.filters || [], organisation),
            currentAuthenticatedUser: {
                id: currentAuthenticatedUser?.username,
                email_address: currentAuthenticatedUser?.attributes?.email,
            },
            ...extraStateData,
            ...additionalStateData,
        });
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false });
        toast('Uh oh, there has been an error loading your tasks', { type: 'error' });
    }
};

function extractTextFromHtml(htmlString) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || '';
}

export const downloadTasksToCsv = (tasks, organisation) => {
    const lines = [
        `Title,Description,Created At,Updated At,Status,Partners,Reviewer,Assigned To,Client,Client Reference,VAT No,Company Number,Internal Due Date,Statutory Due Date,Relevant Period End,Service,Team,Priority,Expected Fee,Expected Date Billed,${Object.entries(
            organisation?.schema
        )
            .map((entry) => entry[1]?.label)
            .join(',')}`,
    ];

    for (const task of tasks || []) {
        const assignees = task?.relations
            ?.filter((x) => x.type === 'assigned_to')
            ?.map((x) => x.email_address)
            .join(' ');

        const partners = task?.relations
            ?.filter((x) => x.type === 'partner')
            ?.map((x) => x.email_address)
            .join(' ');

        const reviewers = task?.relations
            ?.filter((x) => x.type === 'reviewer')
            ?.map((x) => x.email_address)
            .join(' ');

        lines.push(
            `"${task.title}","${task.description ? extractTextFromHtml(task.description) : ''}","${moment
                .utc(task.created_at)
                .format('DD-MM-YYYY')}","${moment.utc(task.updated_at).format('DD-MM-YYYY')}","${
                organisation?.default_visualisation?.statuses?.find((x) => x.key === task.status)?.label
            }","${partners || ''}","${reviewers || ''}","${assignees || ''}","${task.client?.name || ''}","${
                task.client?.internal_reference_code || ''
            }","${task.client?.vat_number || ''}","${task.client?.company_number || ''}","${
                task.due_date ? moment.utc(task.due_date).format('DD-MM-YYYY') : ''
            }","${
                task.additional_data?.statutory_due_date
                    ? moment.utc(task.additional_data?.statutory_due_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    : ''
            }","${
                task.additional_data?.relevant_period_end
                    ? moment.utc(task.additional_data?.relevant_period_end, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    : ''
            }","${
                task.additional_data?.service
                    ? organisation?.workflow_definition?.services?.[task.additional_data?.service]?.label
                    : ''
            }","${task.team?.name || ''}","${
                organisation?.default_visualisation?.priorities?.find((x) => x.key === task.priority)?.label
            }","${task.additional_data?.expected_fee || ''}","${
                task.additional_data?.expected_date_billed
                    ? moment.utc(task.additional_data?.expected_date_billed, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    : ''
            }",${Object.entries(organisation?.schema)
                .map((entry) => {
                    if (entry[1].type === 'dropdown') {
                        return entry[1].dropdownOptions?.find((x) => x.id === task.client?.client_data?.[entry[0]])
                            ?.label;
                    }
                    return `"${task.client?.client_data?.[entry[0]] || ''}"`;
                })
                .join(',')}`
        );
    }

    const hiddenElement = document.createElement('a');

    const href = encodeURI(lines.join('\n'));

    hiddenElement.href = `data:text/csv;charset=utf-8,${href}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Tasks.csv';

    return hiddenElement.click();
};

export const updateTaskAndSort = async (task, dataToUpdate, state, updateState) => {
    try {
        updateState({ ...state, loading: true, error: null });

        const url = `${process.env.REACT_APP_AQRU_AI_API}/tasks/${task.id}`;

        const { data: existingData } = await axios({
            url,
        });

        await axios({
            url,
            method: 'PUT',
            data: { ...existingData, client_id: existingData?.client?.raw_id, ...dataToUpdate },
        });

        const { data: newTaskData } = await axios({
            url,
        });

        state.tasks_for_list = state.tasks_for_list.map((item) => {
            if (item.id === task.id) {
                return newTaskData;
            }
            return item;
        });

        const statusOrder = {};

        state?.organisation?.default_visualisation?.statuses?.forEach((x, idx) => {
            statusOrder[x.key] = idx + 1;
        });

        updateState({
            ...state,
            loading: false,
            error: null,
            fieldChanged: null,
            tasks_for_list: sortTasks(state.tasks_for_list, state.filters, state.organisation),
        });
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'Uh oh, there has been an error updating your task' });
    }
};

export const checkForDuplicateTask = async (taskId, client_id, service, relevant_period_end) => {
    try {
        if (service === 'ad_hoc' || client_id === 'unassigned' || !client_id || !service || !relevant_period_end) {
            return null;
        }
        const {
            data: { tasks },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/tasks`, {
            params: {
                task_id: taskId,
                duplicate_check: 1,
                client_id,
                service,
                relevant_period_end,
            },
        });

        return tasks?.[0]?.id;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const loadBoards = async (state, updateState) => {
    updateState({ ...state, loading: true, error: null });

    try {
        const {
            data: { boards },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/boards`, {});

        updateState({
            ...state,
            loading: false,
            boards,
        });
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'Uh oh, there has been an error loading your boards' });
    }
};

export const saveBoard = async (state, updateState) => {
    if (!state.boardName) {
        return updateState({
            ...state,
            boardsError: null,
            boardsValidationError: 'Please provide a name for your task board',
        });
    }

    updateState({ ...state, loading: true, boardsError: null, boardsValidationError: null });

    try {
        const {
            data: { id },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/boards${
                state.boardIdBeingUpdated ? `/${state.boardIdBeingUpdated}` : ''
            }`,
            method: state.boardIdBeingUpdated ? 'PUT' : 'POST',
            data: {
                name: state.boardName,
            },
        });

        const {
            data: { boards },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/boards`, {});

        await loadTaskData(
            {
                ...state,
                boards,
                boardMode: 'list',
                boardsError: null,
                boardsValidationError: null,
                boardIdBeingUpdated: null,
                board_id: id,
                boardName: '',
            },
            updateState
        );
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'Uh oh, there has been an error saving your board' });
    }
};

export const deleteDocument = (id, state, updateState, refreshCallback) => async () => {
    try {
        updateState({ ...state, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/documents/${id}`,
            method: 'DELETE',
        });

        await refreshCallback({ ...state, error: null }, updateState);
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'Uh oh, there has been an error deleting your document' });
    }
};

export const downloadDocument = async (id, state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        const {
            data: { url },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/documents/${id}`, {});

        updateState({ ...state, loading: false });

        console.log('url', url);

        window.open(url);
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'Uh oh, there has been an error downloading your document' });
    }
};

export const deleteBoard = (boardId, state, updateState) => async () => {
    try {
        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/boards/${boardId}`,
            method: 'DELETE',
        });

        const {
            data: { boards },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/boards`, {});

        await loadTaskData(
            {
                ...state,
                boards,
                boardMode: 'list',
                boardName: '',
            },
            updateState
        );
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'Uh oh, there has been an error deleting your board' });
    }
};

export const attachFile = async (state, updateState, page = 'task') => {
    try {
        if (page === 'task' && !state.fullTaskData?.title) {
            return updateState({
                ...state,
                loading: false,
                attachError: 'Please provide a title and description first for the task',
            });
        }

        if (!state.filesToUpload.length) {
            return updateState({
                ...state,
                loading: false,
                attachError: 'Please select a file to upload',
            });
        }

        if (state.filesToUpload.length > 5) {
            return updateState({
                ...state,
                loading: false,
                attachError: 'Please upload a maximum of 5 files',
            });
        }

        updateState({
            ...state,
            loading: true,
            attachError: null,
        });

        if (page === 'task' && !state.task?.id) {
            const {
                data: { id },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/tasks`,
                method: 'POST',
                data: {
                    title: state.fullTaskData?.title,
                    description: state.fullTaskData?.description,
                },
            });

            updateState({
                ...state,
                loading: true,
                attachError: null,
                task: {
                    id,
                },
            });
            state.task = { id };
        }

        const { data } = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_AQRU_AI_API}/uploads`,
            data: {
                description: `Attachment to ${page} ${page === 'task' ? state.task.id : state.clientInView.id}`,
                files: state.filesToUpload.map((x) => ({
                    content_type: x?.type,
                    file_name: x?.name,
                    labels: [],
                })),
                ...(page === 'task' ? { task_id: state.task.id } : { client_id: state.clientInView.id }),
            },
        });

        let counter = 0;
        for (const url of data.urls) {
            await axios.put(url, state.filesToUpload[counter], {
                headers: { 'Content-Type': state.filesToUpload[counter].type },
            });
            counter++;
        }

        if (page === 'task') {
            await loadFullTaskData(
                {
                    ...state,
                    attachError: null,
                    showAttachFileForm: false,
                },
                updateState
            );
        }

        if (page === 'client') {
            await loadClientDocuments(state, updateState);
        }
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, attachError: 'Uh oh, there has been an error uploading your file' });
    }
};

export const loadClientDocuments = async (state, updateState) => {
    const {
        data: { documents, total: totalDocuments },
    } = await axios({
        url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView.id}/documents`,
        params: { offset: 0, limit: state.documentsLimit },
    });

    updateState({
        ...state,
        loading: false,
        showAttachFileForm: false,
        attachError: null,
        documents,
        totalDocuments,
    });
};

export const loadClientComments = async (state, updateState) => {
    const {
        data: { comments, total },
    } = await axios({
        url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView.id}/comments`,
    });

    updateState({
        ...state,
        loading: false,
        overlayError: null,
        showSubmitCommentForm: false,
        newCommentText: '',
        comments,
        totalComments: total,
    });
};
