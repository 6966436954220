/* eslint-disable no-undef */
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Flex, Paragraph } from 'theme-ui';
import theme from '../../theme';
import Divider from '../divider';
import ConfirmDialog from '../tasks/confirm-dialog';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const loadInvoices = async (state, updateState, setInvoices, initialLoad = false, navigate = null) => {
    if (!state.clientInView.id) return;
    updateState({ ...state, loading: true });

    try {
        const {
            data: { invoices },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/invoices`, {
            params: {
                client_uuid: state.clientInView.id,
            },
        });

        updateState({ ...state, loading: false });
        setInvoices(invoices);

        if (initialLoad && navigate) {
            const invoice_id = new URLSearchParams(window.location.search).get('invoice_id');
            const invoiceToOpen = invoices.find((i) => i.uuid === invoice_id);
            if (invoiceToOpen) {
                updateState({
                    ...state,
                    view: 'generate_invoice',
                    error: null,
                    invoice: invoiceToOpen,
                    setInvoices,
                    loading: false,
                });
                // navigate(`/clients?id=${state.clientInView.id}&tab=invoices`, { replace: true });
            }
        }
    } catch (e) {
        updateState({ ...state, loading: false });
        setInvoices([]);
    }

    return { ...state, loading: false };
};

const Invoices = ({ state, updateState }) => {
    const [invoices, setInvoices] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        loadInvoices(state, updateState, setInvoices, true, navigate);
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20, ml: 20 }}>
            <Paragraph sx={{ fontWeight: 600, fontSize: 20 }}>Invoices</Paragraph>
            <Divider width={'95%'} />
            <Button
                onClick={() =>
                    updateState({
                        ...state,
                        view: 'generate_invoice',
                        error: null,
                        setInvoices,
                    })
                }
                sx={{ width: 100, height: 40, fontSize: 14 }}
            >
                <i
                    style={{
                        marginRight: '7px',
                    }}
                    className={`fas fa-plus-circle`}
                />
                New
            </Button>

            {state.showDeleteInvoiceConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteInvoiceConfirmation"
                    text="Are you sure you wish to delete this invoice?"
                />
            )}

            <table
                style={{
                    borderCollapse: 'collapse',
                    width: '1300px',
                    marginTop: 20,
                    marginRight: 20,
                    fontSize: 14,
                }}
            >
                <thead
                    style={{
                        backgroundColor: theme.colors.primary,
                        opacity: 0.9,
                        height: 50,
                        color: 'white',
                    }}
                >
                    <tr style={{ borderTop: '0px solid lightGrey' }}>
                        <th style={{ width: 150, textAlign: 'center', paddingLeft: 20 }}>Invoice Number</th>
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Invoice Date</th>
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Total Amount</th>
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Recurring</th>
                        {state.organisation?.quickbooks_status === 'connected' ? (
                            <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Created in Quickbooks</th>
                        ) : null}
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Created At</th>
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Emailed At</th>
                        <th style={{ width: 250 }}></th>
                    </tr>
                </thead>
                <tbody style={{ borderCollapse: 'collapse' }}>
                    {invoices?.map((invoice, idx) => (
                        <tr key={`invoice_${idx}`}>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {invoice.invoice_number}
                            </td>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {moment(invoice.invoice_date).format('DD/MM/YYYY')}
                            </td>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {new Intl.NumberFormat('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(invoice.total || 0)}
                            </td>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {invoice.recurring ? 'Yes' : 'No'}
                            </td>
                            {state.organisation?.quickbooks_status === 'connected' ? (
                                <td
                                    style={{
                                        padding: 25,
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        textAlign: 'center',
                                    }}
                                >
                                    {invoice.quickbooks_invoice_id ? 'Yes' : 'No'}
                                </td>
                            ) : null}
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {moment(invoice.created_at).format('HH:mm DD/MM/YYYY')}
                            </td>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {invoice.last_sent_at ? moment(invoice?.last_sent_at).format('HH:mm DD/MM/YYYY') : ''}
                            </td>
                            <td style={{ padding: 25 }}>
                                <Flex sx={{ flexDirection: 'column' }}>
                                    <Button
                                        onClick={async () => {
                                            navigate(
                                                `/clients?id=${state.clientInView.id}&tab=invoices&invoice_id=${invoice.uuid}`,
                                                {
                                                    replace: true,
                                                }
                                            );
                                            updateState({
                                                ...state,
                                                view: 'generate_invoice',
                                                error: null,
                                                invoice,
                                                setInvoices,
                                            });
                                        }}
                                        variant="light"
                                    >
                                        <i
                                            style={{
                                                cursor: 'pointer',
                                                color: 'orange',
                                                marginRight: '7px',
                                            }}
                                            className="fas fa-pencil"
                                            aria-hidden="true"
                                        />
                                        Edit
                                    </Button>
                                    <Button
                                        onClick={async () => {
                                            const {
                                                data: { url },
                                            } = await axios.get(
                                                `${process.env.REACT_APP_AQRU_AI_API}/documents/${invoice.finalised_doc_uuid}`,
                                                {}
                                            );

                                            const a = window.document.createElement('a');

                                            a.href = url;
                                            a.target = '_blank';
                                            a.download = 'Invoice.pdf';

                                            a.click();
                                        }}
                                        variant="light"
                                        sx={{ mt: 10 }}
                                    >
                                        <i
                                            style={{
                                                cursor: 'pointer',
                                                color: 'green',
                                                marginRight: '7px',
                                            }}
                                            className="fas fa-download"
                                            aria-hidden="true"
                                        />
                                        Download
                                    </Button>
                                    {invoice.finalised_doc_uuid ? (
                                        <Button
                                            onClick={async () => {
                                                try {
                                                    updateState({ ...state, loading: true });

                                                    const {
                                                        data: { id: invoiceId },
                                                    } = await axios.post(
                                                        `${process.env.REACT_APP_AQRU_AI_API}/quickbooks/invoices`,
                                                        { uuid: invoice.uuid }
                                                    );
                                                    toast.success('Invoice pushed to Quickbooks');

                                                    setInvoices(
                                                        invoices.map((i) => {
                                                            if (i.uuid === invoice.uuid) {
                                                                return { ...i, quickbooks_invoice_id: invoiceId };
                                                            }
                                                            return i;
                                                        })
                                                    );
                                                    updateState({ ...state, loading: false });
                                                } catch (e) {
                                                    console.error(e);
                                                    toast.error(
                                                        e?.response?.data?.message ||
                                                            'Failed to push invoice to Quickbooks'
                                                    );
                                                    updateState({ ...state, loading: false });
                                                }
                                            }}
                                            variant="light"
                                            sx={{ mt: 10 }}
                                        >
                                            <Flex sx={{ alignItems: 'center' }}>
                                                <img width={16} style={{ marginRight: '5px' }} src="/buttons/qb.png" />
                                                Push to Quickbooks
                                            </Flex>
                                        </Button>
                                    ) : null}
                                </Flex>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Flex>
    );
};

export default Invoices;
