/* eslint-disable no-useless-escape */
import moment from 'moment';
import { Button, Flex, Paragraph } from 'theme-ui';

function replaceTokens(str, obj) {
    return str.replace(/\{([^}]+)\}/g, (_, path) => {
        return (
            path.split('.').reduce((current, key) => {
                return current ? current[key] : undefined;
            }, obj) || _
        );
    });
}

const TemplateSuggestion = ({ state, updateState }) => {
    return (
        <Flex>
            <Flex sx={{ flexDirection: 'column', ml: 20, mt: 25, overflowY: 'hidden' }}>
                <Paragraph sx={{ mb: 20 }}>Here is a suggested email template you could use</Paragraph>

                <Button
                    onClick={() => {
                        navigator.clipboard.writeText(
                            document.getElementById('template-suggestion').innerText.replace(/\n\n\n/g, '')
                        );
                    }}
                    sx={{ fontSize: 14, width: 100, mb: 20, height: 40 }}
                >
                    Copy
                </Button>

                <Flex sx={{ flexDirection: 'column', overflowY: 'scroll', height: 700 }}>
                    <Paragraph
                        id="template-suggestion"
                        sx={{
                            padding: 15,
                            lineHeight: '20px',
                            fontSize: 14,
                            border: '1px solid #ccc',
                            width: '500px',
                            borderRadius: 5,
                            height: 440,
                            mb: 20,
                            overflowY: 'auto',
                        }}
                        dangerouslySetInnerHTML={{
                            __html: replaceTokens(state.template.content, {
                                todaysDate: moment().format('Do MMMM YYYY'),
                                task: {
                                    relevant_period_end: state.fullTaskData?.additional_data?.relevant_period_end
                                        ? moment(
                                              state.fullTaskData?.additional_data?.relevant_period_end,
                                              'YYYY-MM-DD'
                                          ).format('Do MMMM YYYY')
                                        : '',
                                },
                                client: state.clientForEmailTemplate,
                            }),
                        }}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default TemplateSuggestion;
