/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Button, Card, Flex, Heading, Text, Badge, Paragraph, Input, Label } from 'theme-ui';
import axios from 'axios';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe - you would replace this with your actual publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const ManagePlanModal = ({ onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedTier, setSelectedTier] = useState(null);
    const [userCount, setUserCount] = useState('');
    const [showUserCountForm, setShowUserCountForm] = useState(false);

    const handleSelectPlan = (tier) => {
        setSelectedTier(tier);
        setShowUserCountForm(true);
    };

    const handleSubmitUserCount = async () => {
        // Basic validation
        if (!userCount || isNaN(parseInt(userCount)) || parseInt(userCount) <= 0) {
            setError('Please enter a valid number of users');
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            console.log(`Selected plan: ${selectedTier} with ${userCount} users`);

            // Send the selected tier and user count to your backend
            const response = await axios.post(`${process.env.REACT_APP_AQRU_AI_API}/stripe`, {
                tier: selectedTier,
                userCount: parseInt(userCount, 10),
            });

            // Get the session ID from the response
            const { id } = response.data;

            if (!id) {
                throw new Error('No session ID returned from server');
            }

            // Load Stripe
            const stripe = await stripePromise;

            // Redirect to Stripe Checkout
            const { error } = await stripe.redirectToCheckout({
                sessionId: id,
            });

            if (error) {
                throw new Error(error.message);
            }
        } catch (err) {
            console.error('Payment error:', err);
            setError('There was an error processing your payment. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleBack = () => {
        setShowUserCountForm(false);
        setSelectedTier(null);
        setUserCount('');
        setError(null);
    };

    return (
        <Box
            bg="white"
            p={6}
            paddingBottom={3}
            sx={{
                borderRadius: '12px',
                width: '100%',
                height: '100%',
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1)',
                maxWidth: '900px',
                margin: '0 auto',
            }}
        >
            <Box sx={{ position: 'relative', mb: 4 }}>
                <Heading
                    as="h2"
                    mt="-80px"
                    sx={{
                        textAlign: 'center',
                        fontSize: ['28px', '32px', '36px'],
                        fontWeight: 700,
                        color: 'primary',
                    }}
                >
                    Choose Your Plan
                </Heading>

                <Paragraph
                    sx={{
                        mt: 3,
                        textAlign: 'center',
                        mb: 4,
                        fontSize: ['14px', '16px'],
                        color: 'gray',
                        maxWidth: '600px',
                        mx: 'auto',
                        lineHeight: '1.6',
                    }}
                >
                    Implementation is free and we aim to get you live in under 48 hours. Select the plan that works best
                    for your organization.
                </Paragraph>
            </Box>

            {error && (
                <Box
                    sx={{
                        backgroundColor: 'rgba(220, 53, 69, 0.1)',
                        color: '#dc3545',
                        p: 3,
                        borderRadius: '6px',
                        mb: 4,
                        textAlign: 'center',
                    }}
                >
                    {error}
                </Box>
            )}

            {!showUserCountForm ? (
                <Flex
                    sx={{
                        justifyContent: 'center',
                        flexDirection: ['column', null, 'row'],
                        gap: [4, null, 5],
                        width: '100%',
                    }}
                >
                    <PricingPlan
                        tier="standard"
                        price="50"
                        users="1 - 100 users"
                        popular={true}
                        features={['Full platform access', 'Dedicated account manager', 'Priority email support']}
                        onSelect={handleSelectPlan}
                        isLoading={isLoading}
                    />

                    <PricingPlan
                        tier="enterprise"
                        price="25"
                        users="100+ users"
                        features={['Everything in Standard', 'Volume discount']}
                        onSelect={handleSelectPlan}
                        isLoading={isLoading}
                    />
                </Flex>
            ) : (
                <Box
                    sx={{
                        maxWidth: '500px',
                        mx: 'auto',
                        width: '100%',
                        p: 4,
                        border: '1px solid',
                        borderColor: 'primary',
                        borderRadius: '12px',
                    }}
                >
                    <Heading
                        as="h3"
                        sx={{
                            fontSize: '22px',
                            fontWeight: 600,
                            color: 'primary',
                            mb: 4,
                            textAlign: 'center',
                        }}
                    >
                        How many users will you have?
                    </Heading>

                    <Box sx={{ mb: 4 }}>
                        <Label htmlFor="userCount" sx={{ mb: 2, fontSize: '16px' }}>
                            Number of Users
                        </Label>
                        <Input
                            id="userCount"
                            name="userCount"
                            type="number"
                            min="1"
                            value={userCount}
                            onChange={(e) => setUserCount(e.target.value)}
                            placeholder="Enter number of users"
                            sx={{
                                border: '1px solid #EFEFEF',
                                borderRadius: '6px',
                                p: 2,
                                fontSize: '16px',
                                '&:focus': {
                                    borderColor: 'primary',
                                    outline: 'none',
                                },
                            }}
                        />
                    </Box>

                    <Flex sx={{ gap: 3, justifyContent: 'center' }}>
                        <Button
                            onClick={handleBack}
                            sx={{
                                bg: 'white',
                                color: 'text',
                                border: '2px solid',
                                borderColor: '#EFEFEF',
                                borderRadius: '6px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                px: 3,
                                py: 2,
                                '&:hover': {
                                    bg: 'rgba(0, 0, 0, 0.05)',
                                },
                            }}
                        >
                            Back
                        </Button>

                        <Button
                            onClick={handleSubmitUserCount}
                            disabled={isLoading}
                            sx={{
                                bg: 'primary',
                                color: 'white',
                                border: '2px solid',
                                borderColor: 'primary',
                                borderRadius: '6px',
                                fontWeight: 'bold',
                                cursor: isLoading ? 'not-allowed' : 'pointer',
                                opacity: isLoading ? 0.7 : 1,
                                px: 4,
                                py: 2,
                                '&:hover': {
                                    bg: 'primary',
                                    opacity: 0.9,
                                },
                            }}
                        >
                            {isLoading ? 'Processing...' : 'Continue to Payment'}
                        </Button>
                    </Flex>
                </Box>
            )}

            <Paragraph
                sx={{
                    fontSize: '14px',
                    color: 'gray',
                    textAlign: 'center',
                    mt: 4,
                }}
            >
                Have questions? Contact our sales team at sales@accountingflow.io
            </Paragraph>
        </Box>
    );
};

const PricingPlan = ({ tier, price, users, features = [], popular = false, onSelect, isLoading }) => (
    <Card
        sx={{
            border: '1px solid',
            borderColor: popular ? 'primary' : '#EFEFEF',
            borderRadius: '12px',
            padding: '30px',
            bg: 'white',
            width: '100%',
            maxWidth: '400px',
            boxShadow: popular ? '0 8px 30px rgba(0, 0, 0, 0.12)' : '0 4px 10px rgba(0, 0, 0, 0.05)',
            flexDirection: 'column',
            position: 'relative',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 12px 30px rgba(0, 0, 0, 0.15)',
            },
        }}
    >
        {popular && (
            <Badge
                sx={{
                    position: 'absolute',
                    top: '-12px',
                    right: '20px',
                    bg: 'primary',
                    color: 'white',
                    px: 3,
                    py: 1,
                    borderRadius: '20px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                Most Popular
            </Badge>
        )}

        <Flex sx={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Heading
                as="h3"
                sx={{
                    fontSize: '22px',
                    fontWeight: 600,
                    color: popular ? 'primary' : 'text',
                    textTransform: 'capitalize',
                    mb: 1,
                }}
            >
                {tier}
            </Heading>

            <Text
                sx={{
                    fontSize: '16px',
                    mb: 3,
                    color: 'gray',
                    fontWeight: 500,
                }}
            >
                {users}
            </Text>

            <Box
                sx={{
                    width: '80%',
                    height: '1px',
                    bg: '#EFEFEF',
                    my: 3,
                }}
            ></Box>

            <Flex sx={{ alignItems: 'baseline', mb: 1 }}>
                <Text sx={{ fontSize: '16px', fontWeight: 'bold', mr: 1 }}>£</Text>
                <Heading
                    as="h2"
                    sx={{
                        fontSize: '48px',
                        fontWeight: 'bold',
                        lineHeight: 1,
                        color: popular ? 'primary' : 'text',
                    }}
                >
                    {price}
                </Heading>
            </Flex>

            <Text
                sx={{
                    mb: 4,
                    fontSize: '14px',
                    color: 'gray',
                }}
            >
                Per user per month + VAT
            </Text>

            <Box sx={{ width: '100%', mb: 2, minHeight: 110 }}>
                {features.map((feature, index) => (
                    <Flex
                        key={index}
                        sx={{
                            alignItems: 'center',
                            mb: 2,
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: '18px',
                                height: '18px',
                                borderRadius: '50%',
                                bg: 'rgba(0, 128, 0, 0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 2,
                                color: 'green',
                                fontSize: '12px',
                                fontWeight: 'bold',
                            }}
                        >
                            ✓
                        </Box>
                        <Text sx={{ fontSize: '14px' }}>{feature}</Text>
                    </Flex>
                ))}
            </Box>

            <Button
                sx={{
                    bg: popular ? 'primary' : 'white',
                    color: popular ? 'white' : 'primary',
                    border: '2px solid',
                    borderColor: 'primary',
                    width: '100%',
                    borderRadius: '6px',
                    fontWeight: 'bold',
                    cursor: isLoading ? 'not-allowed' : 'pointer',
                    transition: 'all 0.2s ease',
                    opacity: isLoading ? 0.7 : 1,
                    '&:hover': {
                        bg: popular && !isLoading ? 'primary' : 'rgba(0, 0, 0, 0.05)',
                        transform: !isLoading ? 'translateY(-2px)' : 'none',
                    },
                }}
                onClick={() => !isLoading && onSelect(tier)}
                disabled={isLoading}
            >
                {isLoading ? 'Processing...' : popular ? 'Get Started Now' : 'Select Plan'}
            </Button>
        </Flex>
    </Card>
);

export default ManagePlanModal;
