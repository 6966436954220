/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-undef */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Flex, Heading, Input, Label, Paragraph, Switch, Textarea } from 'theme-ui';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { actions as menuActions } from '../store/reducers/menu';
import { actions as userActions } from '../store/reducers/users';
import Divider from '../components/divider';
import ReactSelect from 'react-select';
import ProgressIndicator from '../components/tasks/progress-indicator';
import axios from 'axios';
import { loadClientsAsync, loadTasksAsync } from '../components/tasks/filters';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import * as uuid from 'uuid';
import theme from '../theme';
import ConfirmDialog from '../components/tasks/confirm-dialog';
import { toast } from 'react-toastify';
import Overlay from '../components/overlay/overlay';
import ExpandableFlexLayout from '../components/timesheets/expandable-flex-layout';

export const defaultState = {
    loading: true,
    initialLoad: true,
    partialLoading: false,
    errors: {},
    summaryGrouping: 'employee',
};

export const timeStatuses = [
    { value: 'locked', label: 'Approved' },
    { value: 'open', label: 'Unapproved' },
    { value: 'written_off', label: 'Written off' },
];

export const activityTypes = [
    {
        value: 'task_for_client',
        label: 'Task for client',
        billable: true,
    },
    {
        value: 'holiday',
        label: 'Holiday',
        billable: false,
    },
    {
        value: 'training',
        label: 'Training',
        billable: false,
    },
    {
        value: 'bus_dev',
        label: 'Business Development',
        billable: false,
    },
];

export const defaultErrorState = { selectedDocs: false, description: false, shareEmails: false };

export function convertMinutesToHours(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) return `${remainingMinutes.toFixed(0)} minutes`;

    return `${hours} Hour${hours > 1 ? 's' : ''} ${remainingMinutes.toFixed(0)} mins`;
}

const Title = ({ state, updateState, updateClientBilledTime }) => (
    <Flex
        sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            mb: '10px',
        }}
    >
        <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
            <Heading
                sx={{
                    fontWeight: '400',
                    fontSize: '25px',
                    color: 'text',
                    textAlign: 'left',
                }}
            >
                Timesheets
            </Heading>
            {state.organisation?.user?.role === 'admin' && (
                <Flex sx={{ alignItems: 'center', ml: 20, mt: '3px' }}>
                    <Label sx={{ width: 200 }}>Week view</Label>
                    <Switch
                        defaultChecked={state.view === 'summary'}
                        onClick={async (e) => {
                            const newState = { ...state, view: state.view === 'week' ? 'summary' : 'week' };
                            updateState(newState);
                            if (newState.view === 'week') {
                                await loadTimeEntries(updateClientBilledTime, newState, updateState);
                            } else {
                                await loadSummaryData(newState, updateState);
                            }
                        }}
                    />
                    <Label sx={{ ml: -70, textAlign: 'left' }}>Summary view</Label>
                </Flex>
            )}
            {state.organisation?.user?.role === 'admin' && state.view === 'summary' && (
                <Flex sx={{ alignItems: 'center', ml: 20, mt: '3px' }}>
                    <Label sx={{ width: 260 }}>By Employee</Label>
                    <Switch
                        defaultChecked={state.summaryGrouping === 'client'}
                        onClick={async (e) => {
                            const newState = {
                                ...state,
                                summaryGrouping: state.summaryGrouping === 'client' ? 'employee' : 'client',
                            };
                            updateState(newState);
                            await loadSummaryData(newState, updateState);
                        }}
                    />
                    <Label sx={{ ml: -60, textAlign: 'left' }}>By Client</Label>
                </Flex>
            )}

            {state.view === 'week' && (
                <Flex sx={{ mt: -10, ml: 20 }}>
                    <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                        <Label sx={{ mt: 0 }} id="weeks-label">
                            Weeks
                        </Label>
                        <ReactSelect
                            aria-labelledby="weeks-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '5px',
                                    borderRadius: 10,
                                    marginBottom: '5px',
                                    fontSize: '14px',
                                    border: '1px solid #a3a69b',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 10000000000000000,
                                }),
                            }}
                            onChange={async (c) => {
                                const newState = {
                                    ...state,
                                    week: c,
                                };
                                updateState(newState);
                                await loadTimeEntries(updateClientBilledTime, newState, updateState);
                            }}
                            placeholder="Select..."
                            value={state.week}
                            options={state.weekOptions}
                        />
                    </Flex>

                    {state.organisation?.user?.role === 'admin' && (
                        <Flex sx={{ flexDirection: 'column' }}>
                            <Label sx={{ mt: 0 }} id="org-users-label">
                                Accountant
                            </Label>
                            <ReactSelect
                                aria-labelledby="org-users-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '400px',
                                        minHeight: '40px',
                                        marginTop: '5px',
                                        borderRadius: 10,
                                        marginBottom: '5px',
                                        fontSize: '14px',
                                        border: '1px solid #a3a69b',
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 10000000000000000,
                                    }),
                                }}
                                onChange={async (c) => {
                                    const newState = {
                                        ...state,
                                        org_user: c,
                                    };
                                    updateState(newState);
                                    await loadTimeEntries(updateClientBilledTime, newState, updateState);
                                }}
                                placeholder="Select..."
                                value={state.org_user}
                                options={[
                                    ...(state?.users?.map((x) => ({
                                        value: x.raw_id,
                                        user_id: x.user_id,
                                        additional_data: x?.additional_data,
                                        label: x.first_name
                                            ? `${x.first_name} ${x.last_name} (${x.email_address})`
                                            : x.email_address,
                                    })) || []),
                                ]}
                            />
                        </Flex>
                    )}
                </Flex>
            )}
        </Flex>
    </Flex>
);

const updateStatus = async (entries, state, updateState) => {
    try {
        updateState({ ...state, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/bulk-update`,
            method: 'PUT',
            data: {
                entries,
            },
        });

        updateState({ ...state, loading: false });
        // if (state.view === 'drill_in') {
        //     await loadDetailedList(state, updateState);
        // } else {
        //     await loadSummaryData(state, updateState);
        // }
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

const loadSummaryData = async (state, updateState) => {
    try {
        updateState({ ...state, loading: true, error: null });

        let { fromDate, toDate } = buildDateRange(state);

        const summaryGrouping = state.summaryGrouping || 'employee';

        const {
            data: { entries: summaryEntries, count_of_outstanding_work_before_to_date, locked_to },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/summary`,
            params: {
                summary_grouping: summaryGrouping,
                from: fromDate,
                to: toDate,
                status: state.statusSelected?.value || 'locked,open,written_off',
                billing_status: state.billingStatus?.value || 'unbilled',
                services: state.services?.map((x) => x.value)?.join(','),
            },
        });

        const uniqueEntries = {};

        const key = summaryGrouping === 'employee' ? 'org_user_id' : 'uuid';

        summaryEntries.forEach((entry) => {
            if (uniqueEntries[entry[key]]) {
                uniqueEntries[entry[key]].total =
                    parseFloat(uniqueEntries[entry[key]].total || 0) + parseFloat(entry.sum);
            } else {
                uniqueEntries[entry[key]] = entry;
                uniqueEntries[entry[key]].total = parseFloat(entry.sum);
            }
        });

        updateState({
            ...state,
            loading: false,
            error: null,
            count_of_outstanding_work_before_to_date,
            locked_to,
            summaryEntries: summaryEntries?.map((x) => ({ ...x, uiKey: uuid.v4() })),
            allEntriesSelected: false,
            uniqueEntries: Object.values(uniqueEntries),
            detailedTimeEntries: summaryEntries
                ?.filter((x) => parseFloat(x.timeSpent) > 0)
                ?.map((x) => ({ ...x, uiKey: uuid.v4() })),
        });
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

const loadBilledTime = async (updateClientBilledTime, state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        const {
            data: { results },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/invoices/billed-time`,
            params: {
                client_ids: state.timeEntries?.map((x) => x.client?.id)?.join(','),
            },
        });

        updateState({
            ...state,
            loading: false,
        });
        updateClientBilledTime(results);
    } catch (e) {
        updateState({ ...state, loading: false });
    }
};

const loadDetailedList = async (state, updateState, returnState = false) => {
    try {
        updateState({ ...state, loading: true, error: null });

        let { fromDate, toDate } = buildDateRange(state);

        const summaryGrouping = state.summaryGrouping || 'employee';

        const {
            data: { entries: summaryEntries },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/summary`,
            params: {
                summary_grouping: summaryGrouping,
                from: fromDate,
                to: toDate,
                status: state.statusSelected?.value || 'locked,open,written_off',
                group_by_service: '1',
                ...(summaryGrouping === 'client'
                    ? { client_uuid: state.client }
                    : { org_user_id: state.user.org_user_id }),
                billing_status: state.billingStatus?.value || 'unbilled',
            },
        });

        const newState = {
            ...state,
            loading: false,
            error: null,
            detailedTimeEntries: summaryEntries
                ?.filter((x) => parseFloat(x.timeSpent) > 0)
                ?.map((x) => ({ ...x, uiKey: uuid.v4() })),
            allEntriesSelected: false,
        };

        if (returnState) {
            return newState;
        }

        updateState(newState);
    } catch (e) {
        updateState({ ...state, loading: false });
        toast.error(e?.response?.data?.message);
    }
};

const save = async (updateClientBilledTime, state, updateState) => {
    try {
        const errors = {};

        state.timeEntries?.forEach((entry, idx) => {
            if (entry.activity_type === 'task_for_client' && !entry.client?.id) {
                errors[`${idx}_client`] = true;
            }
            if (!entry.description) {
                errors[`${idx}_description`] = true;
            }
            if (
                entry.activity_type === 'task_for_client' &&
                entry.additional_data?.billable &&
                !entry.billable_amount
            ) {
                errors[`${idx}_billable_amount`] = true;
            }
        });

        if (Object.keys(errors)?.length === 0 && state.timeEntries?.length) {
            const data = {
                belongs_to: state.org_user.value,
                entries: state.timeEntries?.map((item) => ({
                    uuid: item.uuid,
                    description: item.description,
                    additional_data: {
                        ...item.additional_data,
                        collapsed: item.collapsed,
                    },
                    billable_amount: parseFloat(item.billable_amount || 0),
                    service: item.service,
                    subtasks_used_from_ai: item.subtasks_used_from_ai,
                    pending_subtasks_used_from_ai: item.pending_subtasks_used_from_ai,
                    time_spent: item.time_spent,
                    belongs_to: state.org_user.value,
                    task: item.task,
                    client: item.client,
                    activity_type: item.activity_type,
                    week_start_date: moment(state.week?.value, 'DD_MM_YYYY').format('YYYY-MM-DD'),
                    time_day_entries: [0, 1, 2, 3, 4, 5, 6, 7].map((x) => ({
                        time_spent: item.additional_data?.days?.[x] || 0,
                        status: item.additional_data?.locked_days?.includes(x) ? 'locked' : 'open',
                    })),
                })),
            };

            updateState({ ...state, loading: true, errors: {} });

            await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries`,
                method: 'POST',
                data,
            });

            await loadTimeEntries(updateClientBilledTime, state, updateState);
        } else {
            updateState({
                ...state,
                errors,
                timeEntries: state.timeEntries?.map((item, idx) => ({
                    ...item,
                    collapsed: Object.keys(errors)?.find((key) => parseInt(key.split('_')[0], 10) === idx)
                        ? false
                        : item.collapsed,
                })),
            });
        }
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

const importRecentActivity = async (state, updateState, useSearchDates = false) => {
    try {
        updateState({ ...state, loading: true });

        let from = moment(state.week.value.replace(/_/g, '-'), 'DD-MM-YYYY').startOf('day');
        let to = moment(state.week.value.replace(/_/g, '-'), 'DD-MM-YYYY').add(7, 'days').startOf('day');

        if (useSearchDates) {
            from = moment(state.searchFromDate, 'YYYY-MM-DD').startOf('day');
            to = moment(state.searchToDate, 'YYYY-MM-DD').endOf('day');
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_AQRU_AI_API}/time-entries/activity?from=${from.format(
                'YYYY-MM-DD[T]HH:mm:ss[Z]'
            )}&to=${to.format('YYYY-MM-DD[T]HH:mm:ss[Z]')}&org_user_id=${state.org_user.user_id}`
        );

        const newEntries = [];

        let moreActivityFound = false;
        const taskWorkItems = [];

        for (const task of data.tasks) {
            const existingEntryForTask = state.timeEntries?.find((x) => x.task?.id === task.id);
            if (existingEntryForTask) {
                if (
                    existingEntryForTask?.subtasks_used_from_ai?.length &&
                    existingEntryForTask?.subtasks_used_from_ai?.length < task.subtasks?.length
                ) {
                    existingEntryForTask.moreActivityFound = true;
                    existingEntryForTask.pending_subtasks_used_from_ai = task.subtasks.slice(
                        existingEntryForTask?.subtasks_used_from_ai?.length
                    );
                    moreActivityFound = true;
                }
                continue;
            }

            const taskItems = [];
            task.subtasks.forEach((subtask) => {
                const completedAt = moment.utc(subtask.task_completed_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]');
                if (
                    subtask.status === 'done' &&
                    subtask.task_completed_by === state.org_user.user_id &&
                    completedAt.isSameOrAfter(from) &&
                    completedAt.isSameOrBefore(to)
                ) {
                    taskItems.push({
                        subtask_title: subtask.title,
                        task_title: task.title,
                        client_name: task.client.name,
                    });
                }
            });

            if (taskItems.length) {
                taskWorkItems.push({
                    task,
                    taskItems,
                });
            }
        }

        if (taskWorkItems?.length) {
            return updateState({
                ...state,
                loading: false,
                showImportActivityDatePicker: false,
                showSelectWorkItemsToIncludeModal: true,
                taskWorkItems,
            });
        }

        if (!newEntries.length && !moreActivityFound) {
            toast("We've not found any recent task activity", { type: 'success' });
            return updateState({
                ...state,
                loading: false,
                showImportActivityDatePicker: false,
            });
        }

        let existingEntries = state.timeEntries;

        if (existingEntries.length === 1 && !existingEntries[0].description) {
            existingEntries = [];
        }

        updateState({
            ...state,
            loading: false,
            showImportActivityDatePicker: false,
            timeEntries: [...(existingEntries || []), ...newEntries],
        });

        if (!moreActivityFound) {
            setTimeout(() => {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                });
            }, 500);
        }
    } catch (e) {
        updateState({ ...state, loading: false });
        toast('There has been an error fetching activity', { type: 'error' });
    }
};

const summariseNewSubTasks = async (workItems, entryIndex, state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        const { data: aiResponse } = await axios.post(`${process.env.REACT_APP_AQRU_AI_API}/ai/query`, {
            messages: [
                {
                    role: 'assistant',
                    content: `
                    We have recently completed the following work:
                    ${workItems.map((subtask) => `Completed subtask: ${subtask.title}`).join('\n')}
                    Please summarise the work done so that I can complete a timesheet for the accountant.
                    Please keep it succinct summarising only the work done.
                    Make it past tense. Return it as a bullet point
                    `,
                },
            ],
        });

        updateState({
            ...state,
            loading: false,
            timeEntries: state.timeEntries?.map((entry, idx) => {
                if (idx === entryIndex) {
                    entry.description += '\n' + aiResponse.data?.choices[0]?.message?.content;
                    entry.moreActivityFound = false;
                    entry.subtasks_used_from_ai = entry.pending_subtasks_used_from_ai;
                }
                return entry;
            }),
        });

        setTimeout(() => {
            const textarea = document.getElementById(`row_${entryIndex}_description`);
            textarea.scrollTop = textarea.scrollHeight;
        }, 400);
    } catch (e) {
        updateState({ ...state, loading: false });
        toast('There has been an error summarising your activity', { type: 'error' });
    }
};

const summariseDescription = async (entryIndex, state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        const { data: aiResponse } = await axios.post(`${process.env.REACT_APP_AQRU_AI_API}/ai/query`, {
            messages: [
                {
                    role: 'assistant',
                    content: `
                    Please summarise the following description of the work done so that I can complete a timesheet for the accountant.
                    Description: 
                    "${state.timeEntries?.[entryIndex]?.description}"
                    Please keep it succinct summarising only the work done.
                    Make it past tense. Don't prefix response with Summary:
                    `,
                },
            ],
        });

        updateState({
            ...state,
            loading: false,
            timeEntries: state.timeEntries?.map((entry, idx) => {
                if (idx === entryIndex) {
                    entry.previousDescription = entry.description;
                    entry.description = aiResponse.data?.choices[0]?.message?.content;
                }
                return entry;
            }),
        });

        setTimeout(() => {
            const textarea = document.getElementById(`row_${entryIndex}_description`);
            textarea.scrollTop = textarea.scrollHeight;
        }, 400);
    } catch (e) {
        updateState({ ...state, loading: false });
        toast('There has been an error summarising your activity', { type: 'error' });
    }
};

const lockTimeRecordingToSpecificDate = async (state, updateState) => {
    try {
        updateState({ ...state, loading: true });
        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/lock-time`,
            method: 'PUT',
            data: {
                to: state.toDate,
            },
        });
        await loadSummaryData(state, updateState);
    } catch (e) {
        updateState({ ...state, loading: false });
        toast(e?.response?.data?.message, { type: 'error' });
    }
};

const loadInitialData = async (updateClientBilledTime, state, updateState) => {
    try {
        const [
            {
                data: { users },
            },
            { data: organisation },
        ] = await Promise.all([
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/users`,
                params: {
                    limit: 300,
                    offset: 0,
                },
            }),
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            }),
        ]);

        const newState = {
            ...state,
            loading: false,
            users,
            organisation,
            defaultIncrements: organisation?.default_visualisation?.timesheets?.defaultIncrements || 15,
            org_user: {
                value: organisation?.user?.raw_id,
                user_id: organisation?.user?.user_id,
                additional_data: organisation?.user?.additional_data,
                label: organisation?.user?.first_name
                    ? `${organisation?.user?.first_name} ${organisation?.user?.last_name}`
                    : organisation?.user?.email_address,
            },
        };

        if (newState.week && newState.org_user?.value && newState.org_user?.label) {
            await loadTimeEntries(updateClientBilledTime, newState, updateState);
        } else {
            updateState(newState);
        }
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

export const buildIncrementOptions = (defaultIncrements = 15) => {
    const options = [{ value: 0, label: '0 minutes' }];

    let position = parseInt(defaultIncrements, 10);

    while (position <= 60 * 8) {
        options.push({
            value: position,
            label: position > 60 ? convertMinutesToHours(position) : `${position} minutes`,
        });

        position += parseInt(defaultIncrements, 10);
    }

    return options;
};

const generateWeekOptions = () => {
    const startDate = '30_06_2024';
    const endDate = '27_12_2028';

    const dateArray = [];
    let currentDate = moment(startDate, 'DD_MM_YYYY');
    const now = moment();

    while (currentDate.isSameOrBefore(moment(endDate, 'DD_MM_YYYY'))) {
        const value = currentDate.format('DD_MM_YYYY');
        const labelStart = currentDate.format('DD/MM/YYYY');

        const defaultToThis = currentDate.isSameOrBefore(now) && moment(currentDate).add(6, 'days').isSameOrAfter(now);

        const labelEnd = currentDate.add(6, 'days').format('DD/MM/YYYY');
        const label = `${labelStart} - ${labelEnd}`;

        dateArray.push({
            value,
            label,
            defaultToThis,
        });

        currentDate.add(1, 'days');
    }

    return dateArray;
};

const loadTimeEntries = async (updateClientBilledTime, state, updateState) => {
    try {
        const {
            data: { entries: timeEntries, locked_to, count_of_outstanding_work_before_to_date },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries`,
            params: {
                belongs_to: state.org_user.value,
                week_start_date: state.week?.value,
            },
        });

        const newState = {
            ...state,
            loading: false,
            locked_to,
            count_of_outstanding_work_before_to_date,
            timeEntries: timeEntries?.length
                ? timeEntries?.map((x) => ({
                      ...x,
                      collapsed: x.additional_data?.collapsed,
                      additional_data: {
                          ...x.additional_data,
                          days: [0, 1, 2, 3, 4, 5, 6, 7].map((y) => parseFloat(x.time_day_entries?.[y]?.timeSpent)),
                          locked_days: [0, 1, 2, 3, 4, 5, 6, 7].filter(
                              (y) => x.time_day_entries?.[y]?.status === 'locked'
                          ),
                      },
                  }))
                : [templateRecord(state)],
            totalTimeSpent: timeEntries.reduce((accum, value) => {
                accum = parseInt(accum || 0, 10) + parseInt(value.time_spent || 0, 10);
                return accum;
            }, 0),
        };

        updateState(newState);

        loadBilledTime(updateClientBilledTime, newState, updateState);
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

const templateRecord = (state) => ({
    time_spent: 0,
    activity_type: 'task_for_client',
    billable_amount: state.org_user?.additional_data?.billable_hourly_amount || '',
    additional_data: { billable: true, days: [0, 0, 0, 0, 0, 0, 0] },
});

const addRow = (state, updateState) => {
    updateState({
        ...state,
        timeEntries: [...(state.timeEntries || []), templateRecord(state)],
    });
};

const Timesheet = ({ refreshCoreData, userData, accountSummary, isMobile, organisation, logout }) => {
    const weekOptions = generateWeekOptions();

    const [clientBilledTime, updateClientBilledTime] = useState([]);

    const [state, updateState] = useState({
        ...defaultState,
        organisation,
        weekOptions: weekOptions,
        week: weekOptions?.find((x) => x.defaultToThis),
        view: 'week',
        timePeriod: { value: 'this_month', label: 'This Month' },
        statusSelected: { value: 'open,locked', label: 'All' },
        billingStatus: { value: 'unbilled', label: 'Unbilled' },
    });

    const [errorState, updateErrorState] = useState(defaultErrorState);
    const [user, updateUser] = useState(userData);
    const navigate = useNavigate();

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    useEffect(() => {
        const handleEvent = () => {
            console.log('Received Event timebooked:');
            loadTimeEntries(updateClientBilledTime, stateRef.current, updateState);
        };

        window.addEventListener('timebooked', handleEvent);

        return () => {
            window.removeEventListener('timebooked', handleEvent);
        };
    }, []);

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         (async () => {
    //             await save(stateRef.current, updateState);
    //         })();
    //     }, 5000);

    //     return () => clearInterval(intervalId);
    // }, [state.fullTaskData]);

    useEffect(() => {
        window.addEventListener('scroll', function () {
            const tableHeader = document.getElementById('table_head');
            if (!tableHeader) return;

            const stickyPosition = tableHeader.getBoundingClientRect().top;

            if (stickyPosition <= 101) {
                tableHeader.style.top = '101px';
            } else {
                tableHeader.style.top = 'unset';
            }
        });
    }, []);

    useEffect(() => {
        (async () => {
            // const queryParams = new URLSearchParams(window.location.search);
            refreshCoreData();
            await loadInitialData(updateClientBilledTime, state, updateState);
        })();
    }, []);

    const billableTime = state.timeEntries?.reduce((accum, value) => {
        if (value.additional_data?.billable) {
            accum = parseInt(accum || 0, 10) + parseInt(value.time_spent || 0, 10);
        }
        return accum;
    }, 0);

    const billableTimePct =
        billableTime > 0 && state.totalTimeSpent > 0 ? (billableTime / state.totalTimeSpent) * 100 : 0;

    const timeRecordingOptions = buildIncrementOptions(state.defaultIncrements || 15);

    useEffect(() => {
        if (state.showSelectWorkItemsToIncludeModal) {
            // Prevent background scrolling
            document.body.style.overflow = 'hidden';
            document.body.classList.add('no-scroll'); // Fallback for additional enforcement
        } else {
            // Restore background scrolling
            document.body.style.overflow = '';
            document.body.classList.remove('no-scroll');
        }

        // Cleanup when modal closes
        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('no-scroll');
        };
    }, [state.showSelectWorkItemsToIncludeModal]);

    return (
        <>
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    // width: '100vw',
                    width: 'auto',
                    flexDirection: 'column',
                    position: 'relative',
                    backgroundSize: 'cover',
                }}
                data-testid="home-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: 'auto',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar currentPage="Timesheets" accountSummary={accountSummary} user={user} copy={{}} />

                    {(state.loading || state.dataLoading) && <Spinner />}

                    {state.showDeleteItemConfirmation && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            stateKey="showDeleteItemConfirmation"
                            text="Are you sure you want to delete this row?"
                        />
                    )}

                    {state.showConfirmServiceChangeDialog && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            stateKey="showConfirmServiceChangeDialog"
                            text="Are you sure you want to change the service to one different to that of the selected task?"
                        />
                    )}

                    <Flex
                        sx={{
                            width: 'fit-content',
                            minHeight: 'calc(100vh)',
                            borderTopLeftRadius: '25px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            mt: '0px',
                            border: `1px solid ${theme.colors.whiteBlockBorder}`,
                            borderRadius: 20,
                            padding: '20px 20px',
                            minWidth: 370,
                            backgroundColor: 'white',
                            ml: 260,
                            mr: 20,
                        }}
                    >
                        <>
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    position: 'relative',
                                    width: 'auto',
                                }}
                            >
                                <Flex sx={{ flexDirection: 'column' }}>
                                    <Title
                                        updateClientBilledTime={updateClientBilledTime}
                                        state={state}
                                        updateState={updateState}
                                    />
                                    <Divider mb={'10px'} mt={'-0px'} width="1000px" />

                                    {state.showImportActivityDatePicker && (
                                        <Overlay
                                            copy={{}}
                                            maxWidth={500}
                                            maxHeight={380}
                                            embeddedComponent={
                                                <Flex sx={{ flexDirection: 'column', ml: 20, mt: 20 }}>
                                                    <Paragraph>Select date range</Paragraph>

                                                    <Divider width="95%" />

                                                    <Paragraph>From</Paragraph>
                                                    <Input
                                                        value={state.searchFromDate}
                                                        sx={{ width: 200, mt: 10 }}
                                                        type="date"
                                                        onChange={(e) =>
                                                            updateState({ ...state, searchFromDate: e.target.value })
                                                        }
                                                    />

                                                    <Paragraph sx={{ mt: 20 }}>To</Paragraph>
                                                    <Input
                                                        value={state.searchToDate}
                                                        onChange={(e) =>
                                                            updateState({ ...state, searchToDate: e.target.value })
                                                        }
                                                        sx={{ width: 200, mt: 10 }}
                                                        type="date"
                                                    />

                                                    <Button
                                                        sx={{ mt: 30, width: 160 }}
                                                        onClick={async () => {
                                                            const from = moment(state.searchFromDate, 'YYYY-MM-DD');
                                                            const to = moment(state.searchToDate, 'YYYY-MM-DD');

                                                            if (from.isAfter(to)) {
                                                                return toast('From date cannot be after the to date', {
                                                                    type: 'error',
                                                                });
                                                            }

                                                            await importRecentActivity(state, updateState, true);
                                                        }}
                                                    >
                                                        Search for activity
                                                    </Button>
                                                </Flex>
                                            }
                                            updateOverlay={() =>
                                                updateState({
                                                    ...state,
                                                    showImportActivityDatePicker: false,
                                                })
                                            }
                                        />
                                    )}
                                    {state.showSelectWorkItemsToIncludeModal && (
                                        <Overlay
                                            copy={{}}
                                            maxWidth={500}
                                            customSx={{ height: 'auto' }}
                                            embeddedComponent={
                                                <Flex
                                                    sx={{
                                                        flexDirection: 'column',
                                                        ml: 20,
                                                        mt: 20,
                                                        maxHeight: '500px',
                                                    }}
                                                >
                                                    <Paragraph>Select which tasks to include work from</Paragraph>

                                                    <Divider width="95%" />

                                                    {state.loading ? <Spinner /> : null}

                                                    {state.taskWorkItems?.map((workItem, idx) => (
                                                        <Flex
                                                            key={`workitem_${idx}`}
                                                            sx={{ flexDirection: 'column', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                updateState({
                                                                    ...state,
                                                                    taskWorkItems: state.taskWorkItems?.map((x, i) => {
                                                                        if (i === idx) {
                                                                            return { ...x, selected: !x.selected };
                                                                        }
                                                                        return x;
                                                                    }),
                                                                });
                                                            }}
                                                        >
                                                            <Flex>
                                                                <Paragraph sx={{ width: 400 }}>
                                                                    {workItem.task.title}
                                                                </Paragraph>
                                                                <Checkbox checked={workItem.selected} />
                                                            </Flex>
                                                            <Divider width="95%" />
                                                        </Flex>
                                                    ))}

                                                    <Button
                                                        sx={{ mt: 30, width: 160, mb: 30 }}
                                                        onClick={async () => {
                                                            const newEntries = [];
                                                            const existingEntries = state.timeEntries;
                                                            const selectedWorkItems = state.taskWorkItems?.filter(
                                                                (x) => x.selected
                                                            );

                                                            if (!selectedWorkItems.length) {
                                                                return toast('Please select an item to include', {
                                                                    type: 'error',
                                                                });
                                                            }

                                                            updateState({
                                                                ...state,
                                                                loading: true,
                                                            });

                                                            try {
                                                                for (const selectedItem of selectedWorkItems) {
                                                                    const { task, taskItems } = selectedItem;

                                                                    const { data: aiResponse } = await axios.post(
                                                                        `${process.env.REACT_APP_AQRU_AI_API}/ai/query`,
                                                                        {
                                                                            messages: [
                                                                                {
                                                                                    role: 'assistant',
                                                                                    content: `
                                                                We have recently completed the following work:
                                                                ${taskItems?.map((x) => x.subtask_title).join('\n')}
                                                                Please summarise the work done so that I can complete a timesheet for the accountant.
                                                                Please keep it succinct summarising only the work done.
                                                                Make it past tense
                                                                `,
                                                                                },
                                                                            ],
                                                                        }
                                                                    );
                                                                    newEntries.push({
                                                                        ...templateRecord(state),
                                                                        client: {
                                                                            id: task?.client?.id,
                                                                            uuid: task?.client?.uuid,
                                                                            name: task?.client?.name,
                                                                        },
                                                                        subtasks_used_from_ai: [...task.subtasks],
                                                                        description:
                                                                            aiResponse.data?.choices[0]?.message
                                                                                ?.content,
                                                                        service: task.service,
                                                                        task: {
                                                                            id: task.id,
                                                                            uuid: task.uuid,
                                                                            service: task.service,
                                                                            title: task.title,
                                                                        },
                                                                    });
                                                                }
                                                                updateState({
                                                                    ...state,
                                                                    loading: false,
                                                                    showImportActivityDatePicker: false,
                                                                    showSelectWorkItemsToIncludeModal: false,
                                                                    timeEntries: [
                                                                        ...(existingEntries || []),
                                                                        ...newEntries,
                                                                    ],
                                                                });
                                                            } catch (e) {
                                                                updateState({
                                                                    ...state,
                                                                    loading: false,
                                                                });
                                                                toast.error(
                                                                    'Something has gone wrong, please try again'
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <i
                                                            style={{
                                                                fontSize: '14px',
                                                                cursor: 'pointer',
                                                                marginRight: '7px',
                                                            }}
                                                            className="fas fa-sync"
                                                            aria-hidden="true"
                                                        />
                                                        Summarise
                                                    </Button>
                                                </Flex>
                                            }
                                            updateOverlay={() =>
                                                updateState({
                                                    ...state,
                                                    showSelectWorkItemsToIncludeModal: false,
                                                })
                                            }
                                        />
                                    )}

                                    {['drill_in', 'summary'].includes(state.view) && (
                                        <Flex sx={{ flexDirection: 'column' }}>
                                            <Flex>
                                                <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                    <Label sx={{ mt: 0, mb: 10 }} id="tp-label">
                                                        Date Range
                                                    </Label>
                                                    <ReactSelect
                                                        aria-labelledby="tp-label"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                width: '200px',
                                                                minHeight: '40px',
                                                                marginTop: '0px',
                                                                borderRadius: 10,
                                                                marginBottom: '5px',
                                                                fontSize: '14px',
                                                                border: '1px solid #a3a69b',
                                                            }),
                                                        }}
                                                        onChange={async (c) => {
                                                            updateState({ ...state, timePeriod: c });
                                                            await loadSummaryData(
                                                                { ...state, timePeriod: c },
                                                                updateState
                                                            );
                                                        }}
                                                        placeholder="Select..."
                                                        value={state.timePeriod}
                                                        options={[
                                                            { value: 'today', label: 'Today' },
                                                            { value: 'this_week', label: 'This Week' },
                                                            { value: 'this_month', label: 'This Month' },
                                                            { value: 'last_week', label: 'Last Week' },
                                                            { value: 'last_month', label: 'Last Month' },
                                                            { value: 'custom', label: 'Custom' },
                                                        ]}
                                                    />
                                                </Flex>
                                                {state.timePeriod?.value === 'custom' && (
                                                    <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                        <Label sx={{ mt: 0, mb: 10 }} id="from-label">
                                                            From
                                                        </Label>
                                                        <Input
                                                            value={state.fromDate}
                                                            onChange={(e) =>
                                                                updateState({ ...state, fromDate: e.target.value })
                                                            }
                                                            onBlur={async () => {
                                                                await loadSummaryData(state, updateState);
                                                            }}
                                                            type="date"
                                                        />
                                                    </Flex>
                                                )}
                                                {state.timePeriod?.value === 'custom' && (
                                                    <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                        <Label sx={{ mt: 0, mb: 10 }} id="to-label">
                                                            To
                                                        </Label>
                                                        <Input
                                                            value={state.toDate}
                                                            onChange={(e) =>
                                                                updateState({ ...state, toDate: e.target.value })
                                                            }
                                                            onBlur={async () => {
                                                                await loadSummaryData(state, updateState);
                                                            }}
                                                            type="date"
                                                        />
                                                        {state.toDate &&
                                                        state.count_of_outstanding_work_before_to_date &&
                                                        parseInt(state.count_of_outstanding_work_before_to_date, 10) ===
                                                            0 ? (
                                                            <Button
                                                                onClick={async () => {
                                                                    await lockTimeRecordingToSpecificDate(
                                                                        state,
                                                                        updateState
                                                                    );
                                                                }}
                                                                sx={{ mt: 20 }}
                                                            >
                                                                Lock to this date
                                                            </Button>
                                                        ) : null}
                                                    </Flex>
                                                )}
                                                <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                    <Label sx={{ mt: 0, mb: 10 }} id="status-label">
                                                        Status
                                                    </Label>
                                                    <ReactSelect
                                                        aria-labelledby="status-label"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                width: '200px',
                                                                minHeight: '40px',
                                                                marginTop: '0px',
                                                                borderRadius: 10,
                                                                marginBottom: '5px',
                                                                fontSize: '14px',
                                                                border: '1px solid #a3a69b',
                                                            }),
                                                        }}
                                                        onChange={async (c) => {
                                                            updateState({ ...state, statusSelected: c });
                                                            await loadSummaryData(
                                                                { ...state, statusSelected: c },
                                                                updateState
                                                            );
                                                        }}
                                                        placeholder="Select..."
                                                        value={state.statusSelected}
                                                        options={[
                                                            { value: 'open,locked,written_off', label: 'All' },
                                                            { value: 'open', label: 'Open' },
                                                            { value: 'locked', label: 'Approved' },
                                                            { value: 'written_off', label: 'Written off' },
                                                        ]}
                                                    />
                                                </Flex>
                                                <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                    <Label sx={{ mt: 0, mb: 10 }} id="status-label">
                                                        Billing Status
                                                    </Label>
                                                    <ReactSelect
                                                        aria-labelledby="status-label"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                width: '200px',
                                                                minHeight: '40px',
                                                                marginTop: '0px',
                                                                borderRadius: 10,
                                                                marginBottom: '5px',
                                                                fontSize: '14px',
                                                                border: '1px solid #a3a69b',
                                                            }),
                                                        }}
                                                        onChange={async (c) => {
                                                            updateState({ ...state, billingStatus: c });
                                                            await loadSummaryData(
                                                                { ...state, billingStatus: c },
                                                                updateState
                                                            );
                                                        }}
                                                        placeholder="Select..."
                                                        value={state.billingStatus}
                                                        options={[
                                                            { value: 'all', label: 'All' },
                                                            { value: 'unbilled', label: 'Unbilled' },
                                                            { value: 'billed', label: 'Billed' },
                                                        ]}
                                                    />
                                                </Flex>
                                                <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                    <Label sx={{ mt: 0, mb: 10 }} id="service-label">
                                                        Services
                                                    </Label>
                                                    <ReactSelect
                                                        aria-labelledby="status-label"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                width: '200px',
                                                                minHeight: '40px',
                                                                marginTop: '0px',
                                                                borderRadius: 10,
                                                                marginBottom: '5px',
                                                                fontSize: '14px',
                                                                border: '1px solid #a3a69b',
                                                            }),
                                                        }}
                                                        isMulti
                                                        onChange={async (c) => {
                                                            updateState({ ...state, services: c });
                                                            await loadSummaryData(
                                                                { ...state, services: c },
                                                                updateState
                                                            );
                                                        }}
                                                        placeholder="Select..."
                                                        value={state.services}
                                                        options={Object.entries(
                                                            state.organisation?.workflow_definition?.services || {}
                                                        ).map(([key, value]) => ({
                                                            value: key,
                                                            label: value?.label,
                                                        }))}
                                                    />
                                                </Flex>
                                            </Flex>

                                            {state.summaryEntries?.find((x) => x.checked) ? (
                                                <Flex sx={{ mt: 10, mb: 10 }}>
                                                    <Button
                                                        onClick={async () => {
                                                            const { fromDate, toDate } = buildDateRange(state);
                                                            await updateStatus(
                                                                state.summaryEntries
                                                                    ?.filter((x) => x.checked)
                                                                    ?.map((x) => ({
                                                                        status: 'locked',
                                                                        user_id: x.org_user_id,
                                                                        from: fromDate,
                                                                        to: toDate,
                                                                    })),
                                                                state,
                                                                updateState
                                                            );

                                                            state.summaryEntries.forEach((entry, entryIdx) => {
                                                                if (entry.checked) {
                                                                    state.detailedTimeEntries?.[entryIdx]?.forEach(
                                                                        (y) => {
                                                                            y.status = 'locked';
                                                                        }
                                                                    );
                                                                }
                                                            });
                                                            updateState(state);
                                                        }}
                                                        sx={{ height: 40 }}
                                                        variant="primary"
                                                    >
                                                        Approve Selected
                                                    </Button>
                                                    <Button
                                                        onClick={async () => {
                                                            const { fromDate, toDate } = buildDateRange(state);
                                                            await updateStatus(
                                                                state.summaryEntries
                                                                    ?.filter((x) => x.checked)
                                                                    ?.map((x) => ({
                                                                        status: 'open',
                                                                        user_id: x.org_user_id,
                                                                        from: fromDate,
                                                                        to: toDate,
                                                                    })),
                                                                state,
                                                                updateState
                                                            );

                                                            state.summaryEntries.forEach((entry, entryIdx) => {
                                                                if (entry.checked) {
                                                                    state.detailedTimeEntries?.[entryIdx]?.forEach(
                                                                        (y) => {
                                                                            y.status = 'open';
                                                                        }
                                                                    );
                                                                }
                                                            });
                                                            updateState(state);
                                                        }}
                                                        variant="light"
                                                        sx={{ height: 40, ml: 20 }}
                                                    >
                                                        Unapprove Selected
                                                    </Button>
                                                </Flex>
                                            ) : null}

                                            {state.view === 'summary' && state.locked_to ? (
                                                <Paragraph sx={{ mt: 10 }}>
                                                    Time recording is currently locked to{' '}
                                                    {moment.utc(state.locked_to).format('DD/MM/YYYY')}
                                                </Paragraph>
                                            ) : null}

                                            {state.view === 'summary' && (
                                                <ExpandableFlexLayout
                                                    state={state}
                                                    updateState={updateState}
                                                    loadDetailedList={loadDetailedList}
                                                    convertMinutesToHours={convertMinutesToHours}
                                                    updateStatus={updateStatus}
                                                    buildDateRange={buildDateRange}
                                                    timeStatuses={timeStatuses}
                                                />
                                            )}
                                        </Flex>
                                    )}

                                    {state.view === 'week' && state.org_user && state.week && (
                                        <Flex sx={{ flexDirection: 'column' }}>
                                            <Flex sx={{ mt: 0, alignItems: 'center' }}>
                                                <Button
                                                    onClick={async () => {
                                                        updateState({
                                                            ...state,
                                                            showImportActivityDatePicker: true,
                                                            searchFromDate: moment
                                                                .utc(state.week?.value, 'DD_MM_YYYY')
                                                                .format('YYYY-MM-DD'),
                                                            searchToDate: moment
                                                                .utc(state.week?.value, 'DD_MM_YYYY')
                                                                .add(6, 'days')
                                                                .format('YYYY-MM-DD'),
                                                        });
                                                    }}
                                                    variant="primary"
                                                    sx={{ mr: 10, height: 40, fontSize: 14 }}
                                                >
                                                    <i
                                                        style={{
                                                            marginRight: '7px',
                                                        }}
                                                        className={`fas fa-upload`}
                                                    />
                                                    AI - Import Recent Activity
                                                </Button>
                                                <Button
                                                    onClick={() => addRow(state, updateState)}
                                                    variant="light"
                                                    sx={{ height: 40, fontSize: 14 }}
                                                >
                                                    <i
                                                        style={{
                                                            marginRight: '7px',
                                                        }}
                                                        className={`fas fa-plus-circle`}
                                                    />
                                                    Add row
                                                </Button>
                                                {state.locked_to ? (
                                                    <Paragraph sx={{ ml: 20 }}>
                                                        Time recording is currently locked to{' '}
                                                        {moment.utc(state.locked_to).format('DD/MM/YYYY')}
                                                    </Paragraph>
                                                ) : null}
                                                <Button
                                                    sx={{ ml: 20, fontSize: 14, height: 40 }}
                                                    onClick={async () => {
                                                        await save(updateClientBilledTime, state, updateState);
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            marginRight: '7px',
                                                        }}
                                                        className={`fal fa-save`}
                                                    />
                                                    Save
                                                </Button>
                                                <Paragraph sx={{ ml: 20, fontSize: 14 }}>
                                                    Total: {convertMinutesToHours(state.totalTimeSpent || 0)}
                                                </Paragraph>
                                                {Object.keys(state.errors || {}).length ? (
                                                    <Paragraph sx={{ color: 'red', ml: 20 }}>
                                                        Please complete the fields in red
                                                    </Paragraph>
                                                ) : null}
                                                {state.totalTimeSpent > 0 && (
                                                    <Flex sx={{ ml: 20 }}>
                                                        <Paragraph sx={{ mr: 10 }}>
                                                            Billable Time ({billableTimePct.toFixed(0)}%)
                                                        </Paragraph>
                                                        <ProgressIndicator
                                                            percentage={billableTimePct}
                                                            sx={{ mb: 0, width: 220 }}
                                                        />
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Flex>
                                    )}

                                    {state.view === 'drill_in' && (
                                        <Flex sx={{ mt: 30, mb: 50 }}>
                                            <Flex
                                                sx={{
                                                    flexDirection: 'column',
                                                    borderRight: '1px solid lightGrey',
                                                    minHeight: 700,
                                                }}
                                            >
                                                <Button
                                                    onClick={() => updateState({ ...state, view: 'summary' })}
                                                    sx={{ mb: 20, width: 100 }}
                                                    variant="light"
                                                >
                                                    <i className="fal fa-chevron-left" style={{ marginRight: '7px' }} />
                                                    Back
                                                </Button>
                                                {!state.uniqueEntries?.length && (
                                                    <Flex
                                                        sx={{
                                                            padding: 25,
                                                            paddingTop: 10,
                                                            flexDirection: 'column',
                                                            mb: 20,
                                                            mr: 20,
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <Paragraph>No Results Found</Paragraph>
                                                    </Flex>
                                                )}
                                                {state.uniqueEntries?.map((item, idx) => {
                                                    return (
                                                        <Flex
                                                            sx={{
                                                                padding: 25,
                                                                paddingTop: 20,
                                                                borderBottom: '1px solid lightGrey',
                                                                flexDirection: 'column',
                                                                mb: 20,
                                                                mr: 20,
                                                                width: 350,
                                                                ...(state.summaryGrouping === 'employee'
                                                                    ? {
                                                                          color:
                                                                              state.user?.org_user_id ===
                                                                              item.org_user_id
                                                                                  ? 'white'
                                                                                  : 'unset',
                                                                          backgroundColor:
                                                                              state.user?.org_user_id ===
                                                                              item.org_user_id
                                                                                  ? 'primary'
                                                                                  : 'unset',
                                                                      }
                                                                    : {
                                                                          color:
                                                                              state.client === item.uuid
                                                                                  ? 'white'
                                                                                  : 'unset',
                                                                          backgroundColor:
                                                                              state.client === item.uuid
                                                                                  ? 'primary'
                                                                                  : 'unset',
                                                                      }),

                                                                cursor: 'pointer',
                                                            }}
                                                            key={`entre_${idx}`}
                                                            onClick={async () => {
                                                                if (state.summaryGrouping === 'employee') {
                                                                    updateState({ ...state, user: item });
                                                                    await loadDetailedList(
                                                                        { ...state, user: item },
                                                                        updateState
                                                                    );
                                                                } else {
                                                                    updateState({ ...state, client: item.uuid });
                                                                    await loadDetailedList(
                                                                        { ...state, client: item.uuid },
                                                                        updateState
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Paragraph>
                                                                {state.summaryGrouping === 'employee'
                                                                    ? `${item.first_name} ${item.last_name}`
                                                                    : item.name}
                                                            </Paragraph>
                                                            <Paragraph>{`${convertMinutesToHours(
                                                                item.total
                                                            )}`}</Paragraph>
                                                        </Flex>
                                                    );
                                                })}
                                            </Flex>
                                            <Flex sx={{ ml: 20, flexDirection: 'column' }}>
                                                <Paragraph sx={{ fontSize: 24 }}>Time Recordings</Paragraph>
                                                <Divider width="900px" />
                                                {state.detailedTimeEntries?.map((entry, idx) => (
                                                    <Flex sx={{ flexDirection: 'column' }} key={`entry_${idx}`}>
                                                        <Flex sx={{ alignItems: 'center' }}>
                                                            <Flex sx={{ flexDirection: 'column' }}>
                                                                {state.summaryGrouping === 'employee' ? (
                                                                    <Paragraph sx={{ fontSize: 22, mb: '5px' }}>
                                                                        {entry.timeWeekEntry?.client?.name ||
                                                                            activityTypes?.find(
                                                                                (x) =>
                                                                                    x.value ===
                                                                                    entry.timeWeekEntry?.activityType
                                                                            )?.label ||
                                                                            ''}
                                                                    </Paragraph>
                                                                ) : null}
                                                                {entry.timeWeekEntry?.service && (
                                                                    <Paragraph sx={{ fontSize: 18, mb: '5px' }}>
                                                                        {
                                                                            state.organisation?.workflow_definition
                                                                                ?.services?.[
                                                                                entry.timeWeekEntry?.service
                                                                            ]?.label
                                                                        }
                                                                    </Paragraph>
                                                                )}
                                                                <Paragraph sx={{ fontSize: 14 }}>
                                                                    {moment
                                                                        .utc(entry.date, 'YYYY-MM-DDTHH:mm:ssZ')
                                                                        .format('Do MMMM YYYY')}
                                                                </Paragraph>
                                                                <Paragraph
                                                                    sx={{
                                                                        fontSize: 14,
                                                                        width: 550,
                                                                        mt: '5px',
                                                                        mb: '5px',
                                                                    }}
                                                                >
                                                                    {entry.timeWeekEntry?.description}
                                                                </Paragraph>
                                                                <Paragraph sx={{ fontSize: 14 }}>
                                                                    {convertMinutesToHours(entry.timeSpent)}
                                                                </Paragraph>
                                                                <Divider width="700px" />
                                                            </Flex>
                                                            <ReactSelect
                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                        width: '200px',
                                                                        minHeight: '40px',
                                                                        marginTop: '10px',
                                                                        borderRadius: 10,
                                                                        marginBottom: '5px',
                                                                        fontSize: '14px',
                                                                        border: '1px solid #a3a69b',
                                                                    }),
                                                                }}
                                                                onChange={async (c) => {
                                                                    await updateStatus(
                                                                        [
                                                                            {
                                                                                status:
                                                                                    entry.status === 'locked'
                                                                                        ? 'open'
                                                                                        : 'locked',
                                                                                id: entry.id,
                                                                            },
                                                                        ],
                                                                        state,
                                                                        updateState
                                                                    );
                                                                }}
                                                                placeholder="Set status"
                                                                value={timeStatuses?.find(
                                                                    (x) => x.value === entry.status
                                                                )}
                                                                options={timeStatuses}
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                ))}
                                            </Flex>
                                        </Flex>
                                    )}

                                    {state.view === 'week' && (
                                        <Flex sx={{ mt: 10, mb: 50 }}>
                                            <table
                                                style={{
                                                    border: '1px solid lightGrey',
                                                    borderCollapse: 'collapse',
                                                }}
                                            >
                                                <thead
                                                    id="table_head"
                                                    style={{
                                                        boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
                                                        backgroundColor: theme.colors.primary,
                                                        opacity: 0.9,
                                                        height: 50,
                                                        color: 'white',
                                                        position: 'sticky',
                                                        zIndex: 1000,
                                                    }}
                                                >
                                                    <th style={{ width: 30, padding: 0 }}>#</th>
                                                    <th style={{ width: 400 }}>DETAILS</th>
                                                    <th style={{ width: 102 }}>SUN</th>
                                                    <th style={{ width: 102 }}>MON</th>
                                                    <th style={{ width: 102 }}>TUE</th>
                                                    <th style={{ width: 102 }}>WED</th>
                                                    <th style={{ width: 102 }}>THU</th>
                                                    <th style={{ width: 102 }}>FRI</th>
                                                    <th style={{ width: 102 }}>SAT</th>
                                                    <th style={{ width: 102 }}>TOTAL</th>
                                                    <th style={{ width: 50 }}></th>
                                                </thead>

                                                {state.timeEntries?.map((item, idx) => (
                                                    <tr
                                                        style={{ borderTop: '1px solid lightGrey' }}
                                                        key={`time_entry_${idx}`}
                                                    >
                                                        <td
                                                            style={{
                                                                textAlign: 'center',
                                                                verticalAlign: 'top',
                                                            }}
                                                        >
                                                            <Paragraph sx={{ mt: 28 }}>{idx + 1}</Paragraph>
                                                        </td>
                                                        <td style={{ padding: '15px 0px', verticalAlign: 'top' }}>
                                                            {item?.collapsed ? (
                                                                <Flex
                                                                    sx={{
                                                                        mr: 20,
                                                                        width: 350,
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <Flex sx={{ flexDirection: 'column' }}>
                                                                        <Paragraph sx={{ width: 300 }}>
                                                                            {item.client?.name || 'Client name'}
                                                                        </Paragraph>
                                                                        <Paragraph sx={{ width: 300 }}>
                                                                            {item.task?.title || 'Task title'}
                                                                        </Paragraph>

                                                                        <Paragraph sx={{ width: 400 }}>
                                                                            {!item.task && item.service
                                                                                ? Object.entries(
                                                                                      state.organisation
                                                                                          ?.workflow_definition
                                                                                          ?.services || {}
                                                                                  )?.find(
                                                                                      ([key, value]) =>
                                                                                          key === item.service
                                                                                  )?.[1]?.label
                                                                                : ''}
                                                                        </Paragraph>
                                                                    </Flex>
                                                                    <i
                                                                        style={{
                                                                            marginLeft: 10,
                                                                            cursor: 'pointer',
                                                                            top: '20px',
                                                                            right: 10,
                                                                        }}
                                                                        className={`fas fa-chevron-right`}
                                                                        onClick={() => {
                                                                            const newTimeEntries = [
                                                                                ...state.timeEntries,
                                                                            ];
                                                                            newTimeEntries[idx].collapsed = false;
                                                                            updateState({
                                                                                ...state,
                                                                                timeEntries: newTimeEntries,
                                                                            });
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            ) : (
                                                                <Flex
                                                                    sx={{
                                                                        flexDirection: 'column',
                                                                        position: 'relative',
                                                                        mr: 0,
                                                                    }}
                                                                >
                                                                    <i
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '20px',
                                                                            right: 40,
                                                                            cursor: 'pointer',
                                                                            zIndex: 1000,
                                                                        }}
                                                                        className={`fas fa-chevron-down`}
                                                                        onClick={() => {
                                                                            const newTimeEntries = [
                                                                                ...state.timeEntries,
                                                                            ];
                                                                            newTimeEntries[idx].collapsed = true;
                                                                            updateState({
                                                                                ...state,
                                                                                timeEntries: newTimeEntries,
                                                                            });
                                                                        }}
                                                                    />
                                                                    <ReactSelect
                                                                        aria-labelledby="activity-type-label"
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                boxShadow:
                                                                                    '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                                width: 300,
                                                                                minHeight: '40px',
                                                                                marginTop: '5px',
                                                                                borderRadius: 10,
                                                                                marginBottom: '5px',
                                                                                border: '1px solid #a3a69b',
                                                                                fontSize: '14px',
                                                                            }),
                                                                        }}
                                                                        isClearable
                                                                        onChange={async (c) => {
                                                                            const newTimeEntries = [
                                                                                ...state.timeEntries,
                                                                            ];

                                                                            newTimeEntries[idx].activity_type =
                                                                                c?.value;
                                                                            if (newTimeEntries[idx].additional_data) {
                                                                                newTimeEntries[
                                                                                    idx
                                                                                ].additional_data.billable =
                                                                                    c?.billable;
                                                                            }

                                                                            updateState({
                                                                                ...state,
                                                                                timeEntries: newTimeEntries,
                                                                            });
                                                                        }}
                                                                        placeholder="Select..."
                                                                        value={
                                                                            item.activity_type
                                                                                ? {
                                                                                      value: item.activity_type,
                                                                                      label: activityTypes.find(
                                                                                          (x) =>
                                                                                              x.value ===
                                                                                              item.activity_type
                                                                                      )?.label,
                                                                                  }
                                                                                : null
                                                                        }
                                                                        options={activityTypes}
                                                                    />
                                                                    {(!item.activity_type ||
                                                                        item.activity_type === 'task_for_client') && (
                                                                        <Flex sx={{ flexDirection: 'column' }}>
                                                                            <Flex>
                                                                                <AsyncSelect
                                                                                    isDisabled={
                                                                                        item.additional_data
                                                                                            ?.locked_days?.length
                                                                                    }
                                                                                    isClearable
                                                                                    styles={{
                                                                                        control: (provided) => ({
                                                                                            ...provided,
                                                                                            boxShadow:
                                                                                                '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                                            width: 300,
                                                                                            minHeight: '40px',
                                                                                            marginTop: '5px',
                                                                                            borderRadius: 10,
                                                                                            marginBottom: '5px',
                                                                                            border: state.errors?.[
                                                                                                `${idx}_client`
                                                                                            ]
                                                                                                ? '1px solid red'
                                                                                                : '1px solid #a3a69b',
                                                                                            fontSize: '14px',
                                                                                        }),
                                                                                    }}
                                                                                    aria-labelledby="client-label"
                                                                                    onChange={async (c) => {
                                                                                        const newTimeEntries = [
                                                                                            ...state.timeEntries,
                                                                                        ];
                                                                                        newTimeEntries[idx].client = c
                                                                                            ? {
                                                                                                  id: c.value,
                                                                                                  uuid: c.uuid,
                                                                                                  name: c.label,
                                                                                              }
                                                                                            : null;
                                                                                        delete state.errors[
                                                                                            `${idx}_client`
                                                                                        ];

                                                                                        const newState = {
                                                                                            ...state,
                                                                                            timeEntries: newTimeEntries,
                                                                                            defaultTasksForDropDown:
                                                                                                await loadTasksAsync(
                                                                                                    state,
                                                                                                    updateState,
                                                                                                    c?.value
                                                                                                )(''),
                                                                                        };

                                                                                        loadBilledTime(
                                                                                            updateClientBilledTime,
                                                                                            newState,
                                                                                            updateState
                                                                                        );

                                                                                        updateState(newState);
                                                                                    }}
                                                                                    placeholder="Select client"
                                                                                    value={
                                                                                        item.client
                                                                                            ? {
                                                                                                  value: item.client.id,
                                                                                                  label: item.client
                                                                                                      .name,
                                                                                                  uuid: item.client
                                                                                                      .uuid,
                                                                                              }
                                                                                            : null
                                                                                    }
                                                                                    loadOptions={loadClientsAsync(
                                                                                        state,
                                                                                        updateState
                                                                                    )}
                                                                                />
                                                                                {item.client ? (
                                                                                    <a
                                                                                        href={`/clients?id=${item.client.uuid}`}
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                    >
                                                                                        <Button
                                                                                            sx={{ ml: 10, mt: '5px' }}
                                                                                        >
                                                                                            <i
                                                                                                style={{}}
                                                                                                className={`fas fa-external-link-alt`}
                                                                                            />
                                                                                        </Button>
                                                                                    </a>
                                                                                ) : null}
                                                                            </Flex>

                                                                            {item.client &&
                                                                            clientBilledTime
                                                                                ?.filter(
                                                                                    (x) =>
                                                                                        x.client_id === item.client?.id
                                                                                )
                                                                                ?.reduce((accum, item) => {
                                                                                    accum = accum + item.sum;
                                                                                    return accum;
                                                                                }, 0) >= 0 ? (
                                                                                <Paragraph
                                                                                    sx={{
                                                                                        fontSize: 14,
                                                                                        mt: 10,
                                                                                        mb: 10,
                                                                                    }}
                                                                                >
                                                                                    Client has £
                                                                                    {new Intl.NumberFormat('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                    }).format(
                                                                                        clientBilledTime
                                                                                            ?.filter(
                                                                                                (x) =>
                                                                                                    x.client_id ===
                                                                                                    item.client?.id
                                                                                            )
                                                                                            ?.reduce((accum, item) => {
                                                                                                accum =
                                                                                                    accum + item.sum;
                                                                                                return accum;
                                                                                            }, 0)
                                                                                    )}{' '}
                                                                                    of unbilled time charged to it
                                                                                </Paragraph>
                                                                            ) : null}

                                                                            <Flex>
                                                                                <AsyncSelect
                                                                                    isDisabled={
                                                                                        item.additional_data
                                                                                            ?.locked_days?.length
                                                                                    }
                                                                                    isClearable
                                                                                    styles={{
                                                                                        control: (provided) => ({
                                                                                            ...provided,
                                                                                            boxShadow:
                                                                                                '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                                            width: 300,
                                                                                            minHeight: '40px',
                                                                                            marginTop: '5px',
                                                                                            borderRadius: 10,
                                                                                            marginBottom: '5px',
                                                                                            border: '1px solid #a3a69b',
                                                                                            fontSize: '14px',
                                                                                        }),
                                                                                    }}
                                                                                    aria-labelledby="task-label"
                                                                                    onChange={async (c) => {
                                                                                        const newTimeEntries = [
                                                                                            ...state.timeEntries,
                                                                                        ];
                                                                                        if (c) {
                                                                                            newTimeEntries[idx].task = {
                                                                                                id: c.value,
                                                                                                uuid: c.uuid,
                                                                                                service: c.service,
                                                                                                title: c.label,
                                                                                            };
                                                                                            newTimeEntries[
                                                                                                idx
                                                                                            ].service = c.service;
                                                                                        } else {
                                                                                            newTimeEntries[idx].task =
                                                                                                null;
                                                                                        }

                                                                                        updateState({
                                                                                            ...state,
                                                                                            timeEntries: newTimeEntries,
                                                                                        });
                                                                                    }}
                                                                                    placeholder="Select task"
                                                                                    value={
                                                                                        item.task
                                                                                            ? {
                                                                                                  value: item.task.id,
                                                                                                  uuid: item.task.uuid,
                                                                                                  label: item.task
                                                                                                      .title,
                                                                                              }
                                                                                            : null
                                                                                    }
                                                                                    defaultOptions={
                                                                                        state.defaultTasksForDropDown ||
                                                                                        []
                                                                                    }
                                                                                    loadOptions={loadTasksAsync(
                                                                                        state,
                                                                                        updateState,
                                                                                        item.client?.id,
                                                                                        'order_by_created_at'
                                                                                    )}
                                                                                />
                                                                                {item.task ? (
                                                                                    <a
                                                                                        href={`/tasks?id=${item.task.uuid}`}
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                    >
                                                                                        <Button sx={{ ml: 10, mt: 10 }}>
                                                                                            <i
                                                                                                style={{}}
                                                                                                className={`fas fa-external-link-alt`}
                                                                                            />
                                                                                        </Button>
                                                                                    </a>
                                                                                ) : null}
                                                                            </Flex>

                                                                            <ReactSelect
                                                                                aria-labelledby="service-label"
                                                                                isDisabled={
                                                                                    item.additional_data?.locked_days
                                                                                        ?.length
                                                                                }
                                                                                isClearable
                                                                                styles={{
                                                                                    control: (provided) => ({
                                                                                        ...provided,
                                                                                        boxShadow:
                                                                                            '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                                        width: 300,
                                                                                        minHeight: '40px',
                                                                                        marginTop: '5px',
                                                                                        borderRadius: 10,
                                                                                        marginBottom: '5px',
                                                                                        border: '1px solid #a3a69b',
                                                                                        fontSize: '14px',
                                                                                    }),
                                                                                }}
                                                                                onChange={async (c) => {
                                                                                    const newTimeEntries = [
                                                                                        ...state.timeEntries,
                                                                                    ];

                                                                                    if (
                                                                                        newTimeEntries[idx].task &&
                                                                                        newTimeEntries[idx].task
                                                                                            ?.service !== c.value
                                                                                    ) {
                                                                                        const newState = {
                                                                                            ...state,
                                                                                            showConfirmServiceChangeDialog: true,
                                                                                            confirmCallback: () => {
                                                                                                newTimeEntries[
                                                                                                    idx
                                                                                                ].service = c.value;
                                                                                                updateState({
                                                                                                    ...state,
                                                                                                    timeEntries:
                                                                                                        newTimeEntries,
                                                                                                });
                                                                                            },
                                                                                        };

                                                                                        loadBilledTime(
                                                                                            updateClientBilledTime,
                                                                                            newState,
                                                                                            updateState
                                                                                        );

                                                                                        return updateState(newState);
                                                                                    }

                                                                                    newTimeEntries[idx].service =
                                                                                        c?.value;

                                                                                    const newState = {
                                                                                        ...state,
                                                                                        timeEntries: newTimeEntries,
                                                                                    };

                                                                                    loadBilledTime(
                                                                                        updateClientBilledTime,
                                                                                        newState,
                                                                                        updateState
                                                                                    );

                                                                                    updateState(newState);
                                                                                }}
                                                                                placeholder="Select..."
                                                                                value={
                                                                                    item.service
                                                                                        ? {
                                                                                              value: item.service,
                                                                                              label: Object.entries(
                                                                                                  state.organisation
                                                                                                      ?.workflow_definition
                                                                                                      ?.services || {}
                                                                                              )?.find(
                                                                                                  ([key, value]) =>
                                                                                                      key ===
                                                                                                      item.service
                                                                                              )?.[1]?.label,
                                                                                          }
                                                                                        : null
                                                                                }
                                                                                options={[
                                                                                    ...(Object.entries(
                                                                                        organisation
                                                                                            ?.workflow_definition
                                                                                            ?.services || {}
                                                                                    )?.map(([key, value]) => ({
                                                                                        value: key,
                                                                                        label: value.label,
                                                                                    })) || []),
                                                                                ]}
                                                                            />
                                                                            {clientBilledTime?.find(
                                                                                (x) =>
                                                                                    x.client_id === item.client?.id &&
                                                                                    x.service === item.service
                                                                            )?.sum > 0 ? (
                                                                                <Paragraph
                                                                                    sx={{
                                                                                        fontSize: 14,
                                                                                        mt: 10,
                                                                                        mb: 10,
                                                                                    }}
                                                                                >
                                                                                    Client has £
                                                                                    {new Intl.NumberFormat('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                    }).format(
                                                                                        clientBilledTime?.find(
                                                                                            (x) =>
                                                                                                x.client_id ===
                                                                                                    item.client?.id &&
                                                                                                x.service ===
                                                                                                    item.service
                                                                                        )?.sum
                                                                                    )}{' '}
                                                                                    of unbilled time charged to it for
                                                                                    this service
                                                                                </Paragraph>
                                                                            ) : null}
                                                                        </Flex>
                                                                    )}
                                                                    <Flex sx={{ mt: '5px' }}>
                                                                        <Flex sx={{ flexDirection: 'column' }}>
                                                                            <Flex
                                                                                sx={{
                                                                                    alignItems: 'center',
                                                                                    mb: item.description ? '10px' : 0,
                                                                                }}
                                                                            >
                                                                                {item.description ? (
                                                                                    <Button
                                                                                        sx={{ ml: 0, height: 40 }}
                                                                                        onClick={async () => {
                                                                                            await summariseDescription(
                                                                                                idx,
                                                                                                state,
                                                                                                updateState
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <i
                                                                                            style={{
                                                                                                fontSize: '14px',
                                                                                                cursor: 'pointer',
                                                                                                marginRight: '5px',
                                                                                            }}
                                                                                            className="fas fa-sync"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                        Improve with AI
                                                                                    </Button>
                                                                                ) : null}
                                                                                {item.previousDescription ? (
                                                                                    <Button
                                                                                        variant="light"
                                                                                        sx={{ ml: 10 }}
                                                                                        onClick={async () => {
                                                                                            updateState({
                                                                                                ...state,
                                                                                                loading: false,
                                                                                                timeEntries:
                                                                                                    state.timeEntries?.map(
                                                                                                        (
                                                                                                            entry,
                                                                                                            entryIndex
                                                                                                        ) => {
                                                                                                            if (
                                                                                                                idx ===
                                                                                                                entryIndex
                                                                                                            ) {
                                                                                                                entry.description =
                                                                                                                    entry.previousDescription;
                                                                                                                entry.previousDescription =
                                                                                                                    null;
                                                                                                            }
                                                                                                            return entry;
                                                                                                        }
                                                                                                    ),
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        <i
                                                                                            style={{
                                                                                                fontSize: '14px',
                                                                                                cursor: 'pointer',
                                                                                                marginRight: '5px',
                                                                                            }}
                                                                                            className="fas fa-undo"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                        Undo
                                                                                    </Button>
                                                                                ) : null}
                                                                            </Flex>
                                                                            <Textarea
                                                                                placeholder="Enter a description"
                                                                                aria-labelledby="description-label"
                                                                                rows={4}
                                                                                id={`row_${idx}_description`}
                                                                                sx={{
                                                                                    width: 300,
                                                                                    border: state.errors?.[
                                                                                        `${idx}_description`
                                                                                    ]
                                                                                        ? '1px solid red'
                                                                                        : '1px solid #a3a69b',
                                                                                }}
                                                                                value={item.description}
                                                                                onChange={(e) => {
                                                                                    const newTimeEntries = [
                                                                                        ...state.timeEntries,
                                                                                    ];
                                                                                    delete state.errors[
                                                                                        `${idx}_description`
                                                                                    ];
                                                                                    newTimeEntries[idx].description =
                                                                                        e.target.value;
                                                                                    newTimeEntries[
                                                                                        idx
                                                                                    ].previousDescription = '';
                                                                                    updateState({
                                                                                        ...state,
                                                                                        timeEntries: newTimeEntries,
                                                                                    });
                                                                                }}
                                                                            />
                                                                            <Flex
                                                                                sx={{
                                                                                    flexDirection: 'row',
                                                                                    mt: '10px',
                                                                                    alignItems: 'flex-start',
                                                                                }}
                                                                            >
                                                                                <Label
                                                                                    sx={{ mt: 10, width: 100 }}
                                                                                    id="billable_per_hr"
                                                                                >
                                                                                    Billable (/hr)
                                                                                </Label>

                                                                                <Switch
                                                                                    disabled={
                                                                                        item.additional_data
                                                                                            ?.locked_days?.length
                                                                                    }
                                                                                    aria-labelledby="billable_per_hr"
                                                                                    sx={{ mt: 10, ml: 0 }}
                                                                                    checked={
                                                                                        item.additional_data.billable
                                                                                    }
                                                                                    onClick={(e) => {
                                                                                        const newTimeEntries = [
                                                                                            ...state.timeEntries,
                                                                                        ];
                                                                                        newTimeEntries[
                                                                                            idx
                                                                                        ].additional_data.billable =
                                                                                            !newTimeEntries[idx]
                                                                                                .additional_data
                                                                                                .billable;

                                                                                        updateState({
                                                                                            ...state,
                                                                                            timeEntries: newTimeEntries,
                                                                                        });
                                                                                    }}
                                                                                />

                                                                                {item.additional_data.billable && (
                                                                                    <Input
                                                                                        type="number"
                                                                                        value={item.billable_amount}
                                                                                        placeholder="Amount billable / hour"
                                                                                        onChange={(e) => {
                                                                                            const newTimeEntries = [
                                                                                                ...state.timeEntries,
                                                                                            ];
                                                                                            newTimeEntries[
                                                                                                idx
                                                                                            ].billable_amount =
                                                                                                e.target.value;
                                                                                            delete state.errors[
                                                                                                `${idx}_billable_amount`
                                                                                            ];
                                                                                            updateState({
                                                                                                ...state,
                                                                                                timeEntries:
                                                                                                    newTimeEntries,
                                                                                            });
                                                                                        }}
                                                                                        sx={{
                                                                                            width: 100,
                                                                                            border: state.errors?.[
                                                                                                `${idx}_billable_amount`
                                                                                            ]
                                                                                                ? '1px solid red'
                                                                                                : '1px solid #a3a69b',
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                    {item.moreActivityFound ? (
                                                                        <Flex
                                                                            sx={{
                                                                                mt: 20,
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            <Paragraph sx={{ mr: 10, color: 'red' }}>
                                                                                We have found more activity on this task
                                                                            </Paragraph>
                                                                            <Button
                                                                                onClick={async () => {
                                                                                    await summariseNewSubTasks(
                                                                                        item.pending_subtasks_used_from_ai,
                                                                                        idx,
                                                                                        state,
                                                                                        updateState
                                                                                    );
                                                                                }}
                                                                                sx={{}}
                                                                            >
                                                                                <i
                                                                                    style={{
                                                                                        fontSize: '14px',
                                                                                        cursor: 'pointer',
                                                                                        marginRight: '5px',
                                                                                    }}
                                                                                    className="fas fa-plus-circle"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                Add
                                                                            </Button>
                                                                        </Flex>
                                                                    ) : null}
                                                                </Flex>
                                                            )}
                                                        </td>
                                                        {[0, 1, 2, 3, 4, 5, 6].map((day) => {
                                                            const disabled =
                                                                (state.locked_to &&
                                                                    moment
                                                                        .utc(state.locked_to)
                                                                        .endOf('day')
                                                                        .isAfter(
                                                                            moment
                                                                                .utc(state.week?.value, 'DD_MM_YYYY')
                                                                                .startOf('day')
                                                                                .add(day, 'day')
                                                                        )) ||
                                                                item.additional_data?.locked_days?.includes(day);
                                                            return (
                                                                <td
                                                                    key={`day_${idx}_${day}`}
                                                                    style={{ padding: '0px', verticalAlign: 'top' }}
                                                                >
                                                                    <Flex
                                                                        sx={{
                                                                            flexDirection: 'column',
                                                                            alignItems: 'center',
                                                                            mt: 20,
                                                                        }}
                                                                    >
                                                                        <ReactSelect
                                                                            styles={{
                                                                                control: (provided) => ({
                                                                                    ...provided,
                                                                                    boxShadow:
                                                                                        '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                                    width: '100px',
                                                                                    minHeight: '40px',
                                                                                    marginTop: '0px',
                                                                                    borderRadius: 10,
                                                                                    marginBottom: '5px',
                                                                                    fontSize: '13px',
                                                                                    border: '1px solid #a3a69b',
                                                                                }),
                                                                            }}
                                                                            onChange={(c) => {
                                                                                const newTimeEntries = [
                                                                                    ...state.timeEntries,
                                                                                ];
                                                                                newTimeEntries[
                                                                                    idx
                                                                                ].additional_data.days[day] = c.value;
                                                                                newTimeEntries[idx].time_spent =
                                                                                    item.additional_data.days.reduce(
                                                                                        (accum, value) => {
                                                                                            accum =
                                                                                                parseInt(
                                                                                                    accum || 0,
                                                                                                    10
                                                                                                ) +
                                                                                                parseInt(
                                                                                                    value || 0,
                                                                                                    10
                                                                                                );
                                                                                            return accum;
                                                                                        },
                                                                                        0
                                                                                    );

                                                                                updateState({
                                                                                    ...state,
                                                                                    timeEntries: newTimeEntries,
                                                                                    totalTimeSpent:
                                                                                        newTimeEntries.reduce(
                                                                                            (accum, value) => {
                                                                                                accum =
                                                                                                    parseInt(
                                                                                                        accum || 0,
                                                                                                        10
                                                                                                    ) +
                                                                                                    parseInt(
                                                                                                        value.time_spent ||
                                                                                                            0,
                                                                                                        10
                                                                                                    );
                                                                                                return accum;
                                                                                            },
                                                                                            0
                                                                                        ),
                                                                                });
                                                                            }}
                                                                            placeholder="Select..."
                                                                            value={
                                                                                item.additional_data?.days?.[day]
                                                                                    ? {
                                                                                          value: item.additional_data
                                                                                              ?.days?.[day],
                                                                                          label: convertMinutesToHours(
                                                                                              item.additional_data
                                                                                                  ?.days?.[day]
                                                                                          ),
                                                                                      }
                                                                                    : null
                                                                            }
                                                                            options={timeRecordingOptions}
                                                                            isDisabled={disabled}
                                                                        />

                                                                        {state.organisation?.user?.role === 'admin' ? (
                                                                            <Button
                                                                                onClick={async () => {
                                                                                    const newTimeEntries = [
                                                                                        ...state.timeEntries,
                                                                                    ];

                                                                                    if (
                                                                                        !newTimeEntries[idx]
                                                                                            .additional_data.locked_days
                                                                                    ) {
                                                                                        newTimeEntries[
                                                                                            idx
                                                                                        ].additional_data.locked_days =
                                                                                            [];
                                                                                    }

                                                                                    if (
                                                                                        newTimeEntries[
                                                                                            idx
                                                                                        ].additional_data?.locked_days?.includes(
                                                                                            day
                                                                                        )
                                                                                    ) {
                                                                                        newTimeEntries[
                                                                                            idx
                                                                                        ].additional_data.locked_days.splice(
                                                                                            newTimeEntries[
                                                                                                idx
                                                                                            ].additional_data.locked_days.findIndex(
                                                                                                (x) => x === day
                                                                                            ),
                                                                                            1
                                                                                        );
                                                                                    } else {
                                                                                        newTimeEntries[
                                                                                            idx
                                                                                        ].additional_data.locked_days.push(
                                                                                            day
                                                                                        );
                                                                                    }

                                                                                    await save(
                                                                                        updateClientBilledTime,
                                                                                        {
                                                                                            ...state,
                                                                                            timeEntries: newTimeEntries,
                                                                                        },
                                                                                        updateState
                                                                                    );
                                                                                }}
                                                                                variant="light"
                                                                                sx={{ width: 100, marginTop: 0 }}
                                                                            >
                                                                                {item.additional_data?.locked_days?.includes(
                                                                                    day
                                                                                )
                                                                                    ? 'Unapprove'
                                                                                    : 'Approve'}
                                                                            </Button>
                                                                        ) : null}
                                                                        {item.additional_data?.locked_days?.includes(
                                                                            day
                                                                        ) ? (
                                                                            <i
                                                                                style={{
                                                                                    marginTop: 20,
                                                                                    fontSize: '12px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                className="fas fa-lock"
                                                                                aria-hidden="true"
                                                                            />
                                                                        ) : (
                                                                            <Flex sx={{ height: 32 }} />
                                                                        )}
                                                                    </Flex>
                                                                </td>
                                                            );
                                                        })}
                                                        <td
                                                            style={{
                                                                textAlign: 'center',
                                                                verticalAlign: 'top',
                                                            }}
                                                        >
                                                            <Paragraph sx={{ mt: 35 }}>
                                                                {convertMinutesToHours(item.time_spent || 0)}
                                                            </Paragraph>
                                                        </td>
                                                        <td style={{ textAlign: 'center', verticalAlign: 'top' }}>
                                                            <i
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    marginRight: '20px',
                                                                    marginTop: 40,
                                                                    fontSize: '12px',
                                                                    cursor: 'pointer',
                                                                    color: item.additional_data?.locked_days?.length
                                                                        ? 'lightGrey'
                                                                        : 'red',
                                                                }}
                                                                className="fal fa-trash"
                                                                aria-hidden="true"
                                                                onClick={async () => {
                                                                    if (item.additional_data?.locked_days?.length)
                                                                        return;

                                                                    if (item.uuid) {
                                                                        await axios({
                                                                            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/${item.uuid}`,
                                                                            method: 'DELETE',
                                                                        });
                                                                    }
                                                                    updateState({
                                                                        ...state,
                                                                        showDeleteItemConfirmation: true,
                                                                        confirmCallback: async () => {
                                                                            {
                                                                                const newTimeEntries = [
                                                                                    ...state.timeEntries,
                                                                                ];
                                                                                newTimeEntries.splice(idx, 1);

                                                                                updateState({
                                                                                    ...state,
                                                                                    timeEntries: newTimeEntries,
                                                                                    errors: {},
                                                                                    totalTimeSpent:
                                                                                        newTimeEntries.reduce(
                                                                                            (accum, value) => {
                                                                                                accum =
                                                                                                    parseInt(
                                                                                                        accum || 0,
                                                                                                        10
                                                                                                    ) +
                                                                                                    parseInt(
                                                                                                        value.time_spent ||
                                                                                                            0,
                                                                                                        10
                                                                                                    );
                                                                                                return accum;
                                                                                            },
                                                                                            0
                                                                                        ),
                                                                                });
                                                                            }
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                                {state.timeEntries?.length ? (
                                                    <tr style={{ borderTop: '1px solid lightGrey' }}>
                                                        <td></td>
                                                        <td></td>
                                                        {[0, 1, 2, 3, 4, 5, 6].map((x) => (
                                                            <td
                                                                key={`total_${x}`}
                                                                style={{ textAlign: 'center', padding: 25 }}
                                                            >
                                                                {state.timeEntries.reduce((accum, value) => {
                                                                    accum =
                                                                        parseInt(accum || 0, 10) +
                                                                        parseInt(
                                                                            value.additional_data.days[x] || 0,
                                                                            10
                                                                        );
                                                                    return accum;
                                                                }, 0)}
                                                            </td>
                                                        ))}
                                                        <td style={{ textAlign: 'center', padding: 25 }}>
                                                            {state.timeEntries.reduce((accum, value) => {
                                                                accum =
                                                                    parseInt(accum || 0, 10) +
                                                                    parseInt(value.time_spent || 0, 10);
                                                                return accum;
                                                            }, 0)}{' '}
                                                            minutes
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </table>
                                        </Flex>
                                    )}
                                    {state.view === 'week' ? (
                                        <Flex sx={{ alignItems: 'center', mt: -30 }}>
                                            <Button
                                                onClick={() => {
                                                    updateState({
                                                        ...state,
                                                        timeEntries: state.timeEntries.map((x) => ({
                                                            ...x,
                                                            collapsed: false,
                                                        })),
                                                    });
                                                }}
                                                variant="light"
                                            >
                                                <i style={{ marginRight: '7px' }} className="fal fa-chevron-down" />
                                                Expand all
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    updateState({
                                                        ...state,
                                                        timeEntries: state.timeEntries.map((x) => ({
                                                            ...x,
                                                            collapsed: true,
                                                        })),
                                                    });
                                                }}
                                                variant="light"
                                                sx={{ ml: 10 }}
                                            >
                                                <i style={{ marginRight: '7px' }} className="fal fa-chevron-right" />
                                                Collapse all
                                            </Button>
                                        </Flex>
                                    ) : null}
                                </Flex>
                            </Flex>
                        </>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timesheet);
export function buildDateRange(state) {
    let fromDate, toDate;
    if (state.timePeriod?.value === 'this_month') {
        fromDate = `${moment().startOf('day').startOf('month').format('YYYY-MM-DDTHH:mm:ssZ')}`;
        toDate = `${moment().endOf('day').endOf('month').format('YYYY-MM-DDTHH:mm:ssZ')}`;
    }
    if (state.timePeriod?.value === 'today') {
        fromDate = `${moment().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')}`;
        toDate = `${moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')}`;
    }
    if (state.timePeriod?.value === 'custom') {
        fromDate = state.fromDate;
        toDate = state.toDate;
    }
    if (state.timePeriod?.value === 'last_month') {
        fromDate = `${moment().subtract(1, 'month').startOf('day').startOf('month').format('YYYY-MM-DDTHH:mm:ssZ')}`;
        toDate = `${moment().subtract(1, 'month').endOf('day').endOf('month').format('YYYY-MM-DDTHH:mm:ssZ')}`;
    }
    if (state.timePeriod?.value === 'this_week') {
        fromDate = `${moment().startOf('day').startOf('week').format('YYYY-MM-DDTHH:mm:ssZ')}`;
        toDate = `${moment().endOf('day').endOf('week').format('YYYY-MM-DDTHH:mm:ssZ')}`;
    }
    if (state.timePeriod?.value === 'last_week') {
        fromDate = `${moment().subtract(1, 'week').startOf('day').startOf('week').format('YYYY-MM-DDTHH:mm:ssZ')}`;
        toDate = `${moment().subtract(1, 'week').endOf('day').endOf('week').format('YYYY-MM-DDTHH:mm:ssZ')}`;
    }
    return { fromDate, toDate };
}
