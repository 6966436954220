/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
import axios from 'axios';
import ReactSelect from 'react-select';
import { useEffect, useState } from 'react';
import { Button, Flex, Paragraph } from 'theme-ui';
import { extractWorkflowTasksToEnable } from './components';

const updateClient = async (state, updateState, workflow_tasks_for_svc) => {
    try {
        updateState({ ...state, loading: true });
        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView.id}`,
            method: 'PUT',
            data: {
                workflow_tasks: extractWorkflowTasksToEnable(workflow_tasks_for_svc || {}),
            },
        });
        updateState({ ...state, loading: false });
    } catch (ex) {
        updateState({ ...state, loading: false });
        console.error(ex);
    }
};

const CustomWorkflowSettings = ({ state, updateState }) => {
    const [workflow_tasks_for_svc, setClientWorkflowTaskMappings] = useState(
        (state.clientInView?.workflowTaskMappings || []).reduce((acc, x) => {
            if (!acc[x.workflow_task_service]) {
                acc[x.workflow_task_service] = [];
            }
            acc[x.workflow_task_service].push({ value: x.id, label: x.workflow_task_definition?.title });
            return acc;
        }, {})
    );

    const [workflowTasks, setWorkflowTasks] = useState([]);

    useEffect(() => {
        (async () => {
            updateState({ ...state, loading: true });
            const {
                data: { tasks },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/workflow-tasks`,
            });
            updateState({ ...state, loading: false });
            setWorkflowTasks(
                tasks.filter((task) =>
                    state.clientInView?.client_data?.new_client_services_offered?.includes(task.service)
                )
            );
        })();
    }, []);
    return (
        <Flex sx={{ ml: 30, flexDirection: 'column' }}>
            <Paragraph sx={{ mt: 30, fontWeight: 600, mb: 20 }}>Custom Workflow Settings</Paragraph>
            {state.clientInView?.client_data?.new_client_services_offered?.map((service) => {
                const tasks = workflowTasks?.filter((x) => x.service === service);
                if (tasks.length < 2) return null;
                return (
                    <Flex key={`custom_workflow_${service}`} sx={{ flexDirection: 'column' }}>
                        <Paragraph sx={{}}>
                            {state.clientInView?.organisation?.workflow_definition?.services?.[service]?.label}
                        </Paragraph>
                        <ReactSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={async (c) => {
                                if (c?.find((x) => x.value === 'all')) {
                                    return setClientWorkflowTaskMappings({
                                        ...workflow_tasks_for_svc,
                                        [service]: workflowTasks
                                            ?.filter((x) => x.service === service)
                                            ?.map((x) => ({
                                                value: x.id,
                                                label: x.definition?.title,
                                            })),
                                    });
                                }
                                setClientWorkflowTaskMappings({
                                    ...workflow_tasks_for_svc,
                                    [service]: c,
                                });
                            }}
                            isMulti
                            placeholder="Select which workflow tasks apply"
                            value={workflow_tasks_for_svc[service]}
                            options={[
                                { value: 'all', label: 'All' },
                                ...workflowTasks
                                    ?.filter((x) => x.service === service)
                                    ?.map((x) => ({
                                        value: x.id,
                                        label: x.definition?.title,
                                    })),
                            ]}
                        />
                    </Flex>
                );
            })}
            <Button
                sx={{ mt: 20, width: 100 }}
                onClick={async () => {
                    await updateClient(state, updateState, workflow_tasks_for_svc);
                }}
            >
                <i className="fal fa-save" style={{ marginRight: '7px' }} />
                Save
            </Button>
        </Flex>
    );
};

export default CustomWorkflowSettings;
