/* eslint-disable no-undef */
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Flex, Paragraph } from 'theme-ui';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';

import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from 'ag-grid-community';

// Register all community features
ModuleRegistry.registerModules([AllCommunityModule]);

// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: 'legacy' });

const customStyles = {
    headRow: {
        style: {
            border: 'none',
        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const LinkCellRenderer = (props) => {
    return (
        <a target="_blank" rel="noreferrer" href={props.data.href}>
            {props.displayText || props.value || 'Link'}
        </a>
    );
};

const loadReportData = async (state, updateState, localState, updateLocalState) => {
    updateState({ ...state, loading: true });

    try {
        let {
            data: { rows, dataTableConfig },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients/reports`, {
            params: {
                report_name: state.report_to_download?.value,
            },
        });

        updateState({ ...state, loading: false });
        updateLocalState({
            ...localState,
            data: rows,
            columns: dataTableConfig,
        });
    } catch (e) {
        updateState({ ...state, loading: false });
        updateLocalState({
            ...localState,
            data: [],
            columns: [],
        });
        toast('We have been unable to generate the report data at this time, please try again later', {
            type: 'error',
            autoClose: 5000,
            position: 'top-right',
        });
    }
};

const ReportTable = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({ data: [], columns: [] });

    useEffect(() => {
        loadReportData(state, updateState, localState, updateLocalState);
    }, [state.report_to_download]);

    const ResendDocReminderRenderer = (props) => {
        if (props.data.status !== 'sent') {
            return null;
        }
        return (
            <Button
                onClick={async () => {
                    updateState({ ...state, loading: true });

                    await axios({
                        url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${props.data.client_id}/docsign/${props.data.id}/resend`,
                        method: 'PUT',
                    });

                    updateState({ ...state, loading: false });
                    toast.success('Email has been resent');
                }}
                sx={{ boxShadow: 'none', height: 35, mt: '1px', fontSize: 12 }}
                variant="light"
            >
                <Paragraph sx={{ mt: '-10px' }}>
                    <i style={{ marginRight: '7px' }} className="fa fa-plane" />
                    Resend email
                </Paragraph>
            </Button>
        );
    };

    const columnDefs = localState.columns.map((col) => ({
        field: col.selector, // This assumes your selector is the field name
        headerName: col.name,
        sortable: true,
        filter: true,
        ...(col.isHref
            ? {
                  cellRenderer: LinkCellRenderer,
                  cellRendererParams: {},
              }
            : {}),
        ...(col.buttonType === 'resendDocReminder'
            ? {
                  cellRenderer: ResendDocReminderRenderer,
                  cellRendererParams: {},
              }
            : {}),
    }));

    return (
        <Flex sx={{ flexDirection: 'column', width: '100%' }}>
            {/* <DataTable
                title={state.report_to_download?.label}
                columns={localState.columns}
                data={localState.data}
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
            /> */}
            <div className="ag-theme-alpine" style={{ height: 800, minWidth: '1300px' }}>
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={localState.data}
                    headerHeight={40}
                    rowHeight={40}
                    rowSelection="multiple"
                    rowHoverHighlight={true}
                />
            </div>
        </Flex>
    );
};

export default ReportTable;
