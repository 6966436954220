/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
import moment from 'moment';
import * as uuid from 'uuid';
import { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactSelect from 'react-select';
import { Button, Flex, Input, Label, Paragraph, Progress } from 'theme-ui';
import { PgPaginationV2 } from '../documents/paging';
import AddAComment from './add-a-comment';
import { checkForDuplicateTask, cloneTask, deleteTask, fireAndForgetSave, loadFullTaskData, updateFields } from './api';
import AttachFile from './attach-file';
import Attachment from './attachment';
import Comment from './comment';
import ConfirmDialog from './confirm-dialog';
import Subtask from './subtask';
import { PriorityItem, loadClientsAsync } from './filters';
import axios from 'axios';
import { components } from 'react-select';
import Overlay from '../overlay/overlay';
import TaskCloneModal from './task-clone-modal';
import ProgressIndicator from './progress-indicator';
import Confetti from './confetti';
import TemplateSuggestion from './template-suggestion';
import SubTaskComments from './subtask-comments';
import { useUnsavedChangesWarning } from '../../hooks/saving';

const stateUpdateSelector = (state) => {
    return {
        subtasks: state.fullTaskData?.subtasks,
        title: state.fullTaskData?.title,
        description: state.fullTaskData?.description,
        reminders: state.fullTaskData?.reminders,
        due_date: state.fullTaskData?.due_date,
        status: state.fullTaskData?.status_for_component?.value,
        priority: state.fullTaskData?.priority_for_component?.value,
        relations: [
            ...(state.fullTaskData?.assigned_to_for_component?.map((x) => ({
                id: x.value,
                type: 'assigned_to',
            })) || []),
            ...(state.fullTaskData?.reviewer_for_component?.map((x) => ({
                id: x.value,
                type: 'reviewer',
            })) || []),
            ...(state.fullTaskData?.partner_for_component?.map((x) => ({
                id: x.value,
                type: 'partner',
            })) || []),
        ],
        client_id: state.fullTaskData?.client_for_component?.value,
        board_id: state.board_id,
        additional_data: state.fullTaskData?.additional_data || {},
    };
};

export const mandatoryFieldsValidator = (mandatoryFields, fullTaskData) => {
    const result = { valid: true, errors: [] };

    if (mandatoryFields?.includes('client') && !fullTaskData?.client_for_component) {
        result.valid = false;
        result.errors.push('client');
    }

    if (mandatoryFields?.includes('service') && !fullTaskData?.additional_data?.service) {
        result.valid = false;
        result.errors.push('service');
    }

    if (mandatoryFields?.includes('relevant_period_end') && !fullTaskData?.additional_data?.relevant_period_end) {
        result.errors.push('relevant_period_end');
        result.valid = false;
    }

    if (mandatoryFields?.includes('title') && !fullTaskData?.title) {
        result.valid = false;
        result.errors.push('title');
    }

    if (mandatoryFields?.includes('description') && !fullTaskData?.description) {
        result.valid = false;
        result.errors.push('description');
    }

    if (mandatoryFields?.includes('due_date') && !fullTaskData?.due_date) {
        result.valid = false;
        result.errors.push('due_date');
    }

    if (mandatoryFields?.includes('statutory_due_date') && !fullTaskData?.additional_data?.statutory_due_date) {
        result.valid = false;
        result.errors.push('statutory_due_date');
    }

    if (mandatoryFields?.includes('relevant_period_end') && !fullTaskData?.additional_data?.relevant_period_end) {
        result.valid = false;
        result.errors.push('relevant_period_end');
    }

    // if (mandatoryFields?.includes('team') && !fullTaskData?.team_for_component) {
    //     result.valid = false;
    //     result.errors.push('team');
    // }

    if (mandatoryFields?.includes('partners') && !fullTaskData?.partner_for_component?.length) {
        result.valid = false;
        result.errors.push('partners');
    }

    if (mandatoryFields?.includes('assigned_to') && !fullTaskData?.assigned_to_for_component?.length) {
        result.valid = false;
        result.errors.push('assigned_to');
    }

    if (mandatoryFields?.includes('reviewers') && !fullTaskData?.reviewer_for_component?.length) {
        result.valid = false;
        result.errors.push('reviewers');
    }

    if (mandatoryFields?.includes('status') && !fullTaskData?.status_for_component?.value) {
        result.valid = false;
        result.errors.push('status');
    }

    if (mandatoryFields?.includes('priority') && !fullTaskData?.priority_for_component) {
        result.valid = false;
        result.errors.push('priority');
    }

    if (mandatoryFields?.includes('expected_fee') && !fullTaskData?.additional_data?.expected_fee) {
        result.valid = false;
        result.errors.push('expected_fee');
    }

    if (mandatoryFields?.includes('expected_date_billed') && !fullTaskData?.additional_data?.expected_date_billed) {
        result.valid = false;
        result.errors.push('expected_date_billed');
    }

    return result;
};

const Task = ({ state, updateState, refreshCallback, organisation }) => {
    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        state.mode !== 'list' &&
            state.mode !== 'new' &&
            (state.comments?.length || state.documents?.length) &&
            loadFullTaskData(state, updateState);
    }, [state.taskCommentsOffset, state.taskDocumentsOffset]);

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useUnsavedChangesWarning(hasUnsavedChanges);

    useEffect(() => {
        stateRef.current = state;
        const payloadStringified = JSON.stringify(stateRef.current.fullTaskData);

        setHasUnsavedChanges(stateRef.current.lastPayload && stateRef.current.lastPayload !== payloadStringified);
    }, [state]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (
                mandatoryFieldsValidator(
                    organisation?.task_configuration?.mandatory_fields,
                    stateRef.current.fullTaskData
                )?.valid
            ) {
                (async () => {
                    if (
                        stateRef.current?.task_loaded_at &&
                        moment().diff(stateRef.current.task_loaded_at, 'seconds') > 10
                    ) {
                        await fireAndForgetSave(stateRef.current, updateState, stateUpdateSelector(stateRef.current));
                    }
                })();
            }
        }, 5000);

        return () => clearInterval(intervalId);
    }, [state.fullTaskData]);

    useEffect(() => {
        if (state.showTemplateSuggestionModal) {
            // Prevent background scrolling
            document.body.style.overflow = 'hidden';
            document.body.classList.add('no-scroll'); // Fallback for additional enforcement
        } else {
            // Restore background scrolling
            document.body.style.overflow = '';
            document.body.classList.remove('no-scroll');
        }

        // Cleanup when modal closes
        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('no-scroll');
        };
    }, [state.showTemplateSuggestionModal]);

    return state.fullTaskData || state.mode === 'new' ? (
        <Flex>
            {state.showDeleteCommentConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteCommentConfirmation"
                    text="Are you sure you wish to delete this comment?"
                />
            )}

            {state.showDeleteSubtaskConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteSubtaskConfirmation"
                    text="Are you sure you wish to delete this subtask?"
                />
            )}

            {state.showDeleteDocumentConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteDocumentConfirmation"
                    text="Are you sure you wish to delete this document?"
                />
            )}

            {state.showDeleteTaskConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteTaskConfirmation"
                    text="Are you sure you wish to delete this task?"
                />
            )}

            {state.showDuplicateTaskWarning && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDuplicateTaskWarning"
                    text="We have found a task for this client with the same service and relevant period end date. Would you like to open it?"
                />
            )}

            {state.showFrequencySetupModal && (
                <Overlay
                    copy={{}}
                    maxWidth={850}
                    maxHeight={870}
                    embeddedComponent={<TaskCloneModal state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showFrequencySetupModal: false })}
                />
            )}

            {state.showSubTaskHelpVideo && (
                <Overlay
                    copy={{}}
                    customSx={{ ml: 120, width: 'auto', height: 'auto' }}
                    embeddedComponent={
                        <Flex
                            sx={{
                                bg: 'white',
                                borderRadius: '8px',
                                padding: 4,
                                position: 'relative',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <video
                                style={{
                                    width: '90%',
                                    maxWidth: 900,
                                    aspectRatio: '16 / 9',
                                }}
                                controls
                            >
                                <source src={state.showSubTaskHelpVideoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Flex>
                    }
                    updateOverlay={() => updateState({ ...state, showSubTaskHelpVideo: false })}
                />
            )}

            {state.showTemplateSuggestionModal && (
                <Overlay
                    copy={{}}
                    maxWidth={550}
                    maxHeight={620}
                    embeddedComponent={<TemplateSuggestion state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showTemplateSuggestionModal: false, template: null })}
                />
            )}

            {state.showSubTaskCommentsDialog && (
                <Overlay
                    copy={{}}
                    maxWidth={550}
                    maxHeight={720}
                    embeddedComponent={
                        <SubTaskComments
                            triggerSave={async (updatedState) => {
                                fireAndForgetSave(updatedState, updateState, stateUpdateSelector(updatedState)).then(
                                    () => ({})
                                );
                            }}
                            state={state}
                            updateState={updateState}
                        />
                    }
                    updateOverlay={() =>
                        updateState({ ...state, showSubTaskCommentsDialog: false, subTaskUIKey: null })
                    }
                />
            )}

            <Flex sx={{ ml: 30, mt: 0, mb: 100, flexDirection: 'column' }}>
                {state.fullTaskData?.additional_data?.created_by && (
                    <Paragraph sx={{ fontWeight: '400', fontSize: 14, mb: 20 }}>
                        Created by: {state.fullTaskData?.additional_data?.created_by}
                    </Paragraph>
                )}

                <Flex
                    sx={{
                        position: 'fixed',
                        height: 80,
                        background: 'white',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        alignItems: 'center',
                        borderTop: '2px solid #EFEFEF',
                        width: '100%',
                        zIndex: 998,
                    }}
                >
                    <Flex sx={{ justifyContent: 'flex-start', ml: 20, mt: 0 }}>
                        <Button
                            sx={{
                                ml: 0,
                                width: '115px',
                                height: '40px',
                                fontSize: 13,
                                backgroundColor: '#FFF',
                                border: '1px solid lightGrey',
                                color: 'text',
                            }}
                            onClick={async () => {
                                window.location.assign('/tasks');
                            }}
                        >
                            <i
                                style={{
                                    marginTop: '0px',
                                    fontSize: '12px',
                                    marginRight: '6px',
                                    color: 'text',
                                }}
                                className={`fal fa-chevron-left`}
                            />{' '}
                            Back
                        </Button>

                        <Button
                            onClick={async () => {
                                await cloneTask(state, updateState, stateUpdateSelector(state));
                            }}
                            sx={{
                                ml: 10,
                                width: '115px',
                                height: '40px',
                                fontSize: 13,
                                backgroundColor: '#FFF',
                                border: '1px solid lightGrey',
                                color: 'text',
                            }}
                        >
                            <i
                                style={{
                                    marginRight: '7px',
                                }}
                                className={`fas fa-copy`}
                            />
                            Clone
                        </Button>

                        <Button
                            onClick={async () => {
                                updateState({ ...state, showFrequencySetupModal: true });
                            }}
                            sx={{
                                ml: 10,
                                width: '125px',
                                height: '40px',
                                fontSize: 13,
                                backgroundColor: '#FFF',
                                border: '1px solid lightGrey',
                                color: 'text',
                            }}
                        >
                            <i
                                style={{
                                    marginRight: '7px',
                                }}
                                className={`fas fa-clock`}
                            />
                            Schedule
                        </Button>

                        <Button
                            onClick={async () => {
                                const { valid } = mandatoryFieldsValidator(
                                    organisation?.task_configuration?.mandatory_fields,
                                    state.fullTaskData
                                );

                                if (!valid) {
                                    return updateState({
                                        ...state,
                                        error: 'Please fill out the mandatory fields in red',
                                    });
                                }

                                await updateFields(state, updateState, stateUpdateSelector(state), refreshCallback);
                            }}
                            sx={{ ml: 10, width: '95px', height: '40px', fontSize: 14 }}
                        >
                            <i
                                style={{
                                    marginRight: '7px',
                                }}
                                className={`fal fa-save`}
                            />
                            Save
                        </Button>

                        {state.lastAutoSaved && (
                            <Paragraph sx={{ ml: 20, mt: 10, fontSize: 14 }}>
                                Last Saved: {state.lastAutoSaved}
                            </Paragraph>
                        )}
                    </Flex>
                </Flex>

                {state.overlayError && (
                    <Paragraph sx={{ fontWeight: '400', color: 'red', mb: 20 }}>{state.overlayError}</Paragraph>
                )}

                <Flex sx={{ mt: 0, flexDirection: 'column' }}>
                    <Label id="client-label" sx={{ mr: 30, fontSize: 14 }}>
                        Client
                    </Label>
                    <Flex>
                        <AsyncSelect
                            aria-labelledby="client-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '370px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: `1px solid ${
                                        organisation?.task_configuration?.mandatory_fields?.includes('client') &&
                                        !state.fullTaskData?.client_for_component
                                            ? 'red'
                                            : '#a3a69b'
                                    }`,
                                    fontSize: '14px',
                                }),
                            }}
                            isClearable
                            onChange={async (c) => {
                                const newState = {
                                    ...state,
                                    fullTaskData: {
                                        ...state.fullTaskData,
                                        client_for_component: c,
                                    },
                                };

                                if (!newState.fullTaskData?.partner_for_component?.length) {
                                    newState.fullTaskData.partner_for_component =
                                        c?.fullData?.relations
                                            ?.filter((x) => x.type === 'partner')
                                            ?.map((relation) => ({
                                                value: relation.id,
                                                label: relation.name || relation.email_address,
                                            })) || [];
                                }
                                if (!newState.fullTaskData?.reviewer_for_component?.length) {
                                    newState.fullTaskData.reviewer_for_component =
                                        c?.fullData?.relations
                                            ?.filter((x) => x.type === 'reviewer')
                                            ?.map((relation) => ({
                                                value: relation.id,
                                                label: relation.name || relation.email_address,
                                            })) || [];
                                }
                                if (!newState.fullTaskData?.assigned_to_for_component?.length) {
                                    newState.fullTaskData.assigned_to_for_component =
                                        c?.fullData?.relations
                                            ?.filter((x) => x.type === 'assigned_to')
                                            ?.map((relation) => ({
                                                value: relation.id,
                                                label: relation.name || relation.email_address,
                                            })) || [];
                                }

                                updateState(newState);

                                if (!c) return;

                                const duplicateResponse = await checkForDuplicateTask(
                                    state.task?.id || '-1',
                                    c?.value,
                                    state.fullTaskData?.additional_data?.service,
                                    state.fullTaskData?.additional_data?.relevant_period_end
                                );

                                if (duplicateResponse) {
                                    updateState({
                                        ...newState,
                                        showDuplicateTaskWarning: true,
                                        confirmCallback: () => window.location.assign(`/tasks?id=${duplicateResponse}`),
                                    });
                                }
                            }}
                            placeholder="Search by client name"
                            value={state.fullTaskData?.client_for_component}
                            loadOptions={loadClientsAsync(state, updateState)}
                            defaultOptions={[{ value: 'unassigned', label: 'Unassigned' }]}
                        />
                        <Flex>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={
                                    state.fullTaskData?.client_for_component?.value
                                        ? `/clients?id=${state.fullTaskData?.client_for_component?.value}&raw_id=1`
                                        : '/clients'
                                }
                            >
                                <Button
                                    sx={{
                                        width: 100,
                                        ml: 10,
                                        height: 40,
                                        mt: 10,
                                        fontSize: 14,
                                        color: 'text',
                                        backgroundColor: 'white',
                                        border: '1px solid lightGrey',
                                    }}
                                >
                                    <i
                                        style={{
                                            marginRight: '7px',
                                        }}
                                        className={`fas fa-external-link-alt`}
                                    />
                                    Open
                                </Button>
                            </a>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                    <Label id="service-label" sx={{ mr: 30, fontSize: 14 }}>
                        Service
                    </Label>
                    <ReactSelect
                        aria-labelledby="service-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '370px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                border: `1px solid ${
                                    organisation?.task_configuration?.mandatory_fields?.includes('service') &&
                                    !state.fullTaskData?.additional_data?.service
                                        ? 'red'
                                        : '#a3a69b'
                                }`,
                                fontSize: '14px',
                            }),
                        }}
                        onChange={async (c) => {
                            updateState({
                                ...state,
                                loading: true,
                            });

                            const {
                                data: { tasks },
                            } = await axios.get(
                                `${process.env.REACT_APP_AQRU_AI_API}/organisations/workflow-tasks`,
                                {}
                            );

                            const newState = {
                                ...state,
                                loading: false,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    additional_data: {
                                        ...state.fullTaskData?.additional_data,
                                        service: c.value,
                                    },
                                    workflows: tasks?.map((x) => ({
                                        value: x.id,
                                        label: x.definition?.title,
                                    })),
                                    workflowTasks: tasks,
                                },
                            };

                            updateState(newState);

                            const duplicateResponse = await checkForDuplicateTask(
                                newState.task?.id || '-1',
                                newState.fullTaskData?.client_for_component?.value,
                                newState.fullTaskData?.additional_data?.service,
                                newState.fullTaskData?.additional_data?.relevant_period_end
                            );

                            if (duplicateResponse) {
                                updateState({
                                    ...newState,
                                    showDuplicateTaskWarning: true,
                                    confirmCallback: () => window.location.assign(`/tasks?id=${duplicateResponse}`),
                                });
                            }
                        }}
                        placeholder="Select..."
                        value={
                            state.fullTaskData?.additional_data?.service
                                ? {
                                      value: state.fullTaskData?.additional_data?.service,
                                      label: organisation?.workflow_definition?.services?.[
                                          state.fullTaskData?.additional_data?.service
                                      ]?.label,
                                  }
                                : null
                        }
                        options={[
                            ...(Object.entries(organisation?.workflow_definition?.services || {})
                                ?.filter((entry) => {
                                    return Array.isArray(state.fullTaskData?.client_for_component?.services)
                                        ? state.fullTaskData?.client_for_component?.services?.includes(entry[0])
                                        : true;
                                })
                                ?.map(([key, value]) => ({
                                    value: key,
                                    label: value.label,
                                })) || []),
                        ]}
                    />
                </Flex>

                <Label id="rel-per-end" sx={{ fontWeight: '400', mt: 20, mb: 10, width: 160 }}>
                    Relevant Period End
                </Label>
                <Input
                    aria-labelledby="rel-per-end"
                    type="date"
                    value={
                        state.fullTaskData?.additional_data?.relevant_period_end
                            ? moment(state.fullTaskData?.additional_data?.relevant_period_end).format('YYYY-MM-DD')
                            : ''
                    }
                    onChange={(e) =>
                        updateState({
                            ...state,
                            fullTaskData: {
                                ...state.fullTaskData,
                                additional_data: {
                                    ...state.fullTaskData?.additional_data,
                                    relevant_period_end: e.target.value,
                                },
                            },
                        })
                    }
                    onBlur={async (e) => {
                        const newState = {
                            ...state,
                            fullTaskData: {
                                ...state.fullTaskData,
                                ...(state.mode === 'new' &&
                                !state.fullTaskData?.title &&
                                state.fullTaskData?.additional_data?.service &&
                                state.fullTaskData?.client_for_component?.label
                                    ? {
                                          title: `${state.fullTaskData?.client_for_component?.label} - ${
                                              organisation?.workflow_definition?.services?.[
                                                  state.fullTaskData?.additional_data?.service
                                              ]?.label
                                          } - ${moment.utc(e.target.value).format('Do MMM YY')}`,
                                      }
                                    : {}),
                            },
                        };

                        updateState(newState);

                        const duplicateResponse = await checkForDuplicateTask(
                            state.task?.id || '-1',
                            state.fullTaskData?.client_for_component?.value,
                            state.fullTaskData?.additional_data?.service,
                            e.target.value
                        );

                        if (duplicateResponse) {
                            updateState({
                                ...newState,
                                showDuplicateTaskWarning: true,
                                confirmCallback: () => window.location.assign(`/tasks?id=${duplicateResponse}`),
                            });
                        }
                    }}
                    sx={{
                        width: 370,
                        fontWeight: '400',
                        border: `1px solid ${
                            organisation?.task_configuration?.mandatory_fields?.includes('relevant_period_end') &&
                            !state.fullTaskData?.additional_data?.relevant_period_end
                                ? 'red'
                                : '#a3a69b'
                        }`,

                        mb: 20,
                    }}
                />

                <Label id="title-label" sx={{ fontWeight: '400', mt: 10, mb: 10, width: 160 }}>
                    Title
                </Label>
                <Flex>
                    <Input
                        aria-labelledby="title-label"
                        value={state.fullTaskData?.title}
                        onChange={(e) =>
                            updateState({
                                ...state,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    title: e.target.value,
                                },
                            })
                        }
                        sx={{
                            width: 470,
                            fontWeight: '400',
                            border: `1px solid ${
                                organisation?.task_configuration?.mandatory_fields?.includes('title') &&
                                !state.fullTaskData?.title
                                    ? 'red'
                                    : '#a3a69b'
                            }`,

                            mb: 20,
                        }}
                    />
                    <Button
                        onClick={() => {
                            updateState({
                                ...state,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    ...(state.fullTaskData?.additional_data?.service &&
                                    state.fullTaskData?.additional_data?.relevant_period_end &&
                                    state.fullTaskData?.client_for_component?.label
                                        ? {
                                              title: `${state.fullTaskData?.client_for_component?.label} - ${
                                                  organisation?.workflow_definition?.services?.[
                                                      state.fullTaskData?.additional_data?.service
                                                  ]?.label
                                              } - ${moment
                                                  .utc(state.fullTaskData?.additional_data?.relevant_period_end)
                                                  .format('Do MMM YY')}`,
                                          }
                                        : {}),
                                },
                            });
                        }}
                        sx={{
                            ml: 10,
                            height: 37,
                            fontSize: 14,
                            color: 'text',
                            backgroundColor: 'white',
                            border: '1px solid lightGrey',
                            width: 100,
                        }}
                    >
                        <i
                            style={{
                                marginRight: '7px',
                            }}
                            className={`fas fa-sync`}
                        />
                        Reset
                    </Button>
                </Flex>

                <Label id="description-label" sx={{ fontWeight: '400', mb: 10, mt: 10, width: 160 }}>
                    Description
                </Label>

                <Flex
                    sx={{
                        border: `${
                            organisation?.task_configuration?.mandatory_fields?.includes('description') &&
                            (!state.fullTaskData?.description || state.fullTaskData?.description === '<p><br></p>')
                                ? '1px solid red'
                                : 'none'
                        }`,
                        height: 344,
                        width: 470,
                    }}
                >
                    <ReactQuill
                        theme="snow"
                        value={state.fullTaskData?.description}
                        style={{ height: 300, width: 470 }}
                        onChange={(val) =>
                            updateState({
                                ...state,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    description: val,
                                },
                            })
                        }
                    />
                </Flex>

                <Label id="it-due-date-label" sx={{ fontWeight: '400', mt: 70, mb: 10, width: 160 }}>
                    Internal Due Date
                </Label>
                <Input
                    aria-labelledby="it-due-date-label"
                    type="date"
                    value={
                        state.fullTaskData?.due_date ? moment(state.fullTaskData?.due_date).format('YYYY-MM-DD') : ''
                    }
                    onChange={(e) =>
                        updateState({
                            ...state,
                            fullTaskData: {
                                ...state.fullTaskData,
                                due_date: e.target.value,
                            },
                        })
                    }
                    sx={{
                        width: 370,
                        fontWeight: '400',
                        border: `1px solid ${
                            organisation?.task_configuration?.mandatory_fields?.includes('due_date') &&
                            !state.fullTaskData?.due_date
                                ? 'red'
                                : '#a3a69b'
                        }`,

                        mb: 20,
                    }}
                />

                <Label id="st-due-date" sx={{ fontWeight: '400', mt: 10, mb: 10, width: 190 }}>
                    Statutory Due Date
                </Label>
                <Input
                    aria-labelledby="st-due-date"
                    type="date"
                    value={
                        state.fullTaskData?.additional_data?.statutory_due_date
                            ? moment(state.fullTaskData?.additional_data?.statutory_due_date).format('YYYY-MM-DD')
                            : ''
                    }
                    onChange={(e) =>
                        updateState({
                            ...state,
                            fullTaskData: {
                                ...state.fullTaskData,
                                additional_data: {
                                    ...state.fullTaskData?.additional_data,
                                    statutory_due_date: e.target.value,
                                },
                            },
                        })
                    }
                    sx={{
                        width: 370,
                        fontWeight: '400',

                        border: `1px solid ${
                            organisation?.task_configuration?.mandatory_fields?.includes('statutory_due_date') &&
                            !state.fullTaskData?.additional_data?.statutory_due_date
                                ? 'red'
                                : '#a3a69b'
                        }`,

                        mb: 20,
                    }}
                />

                <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                    <Label id="partners-label" sx={{ mr: 30, fontSize: 14 }}>
                        Partners assigned
                    </Label>
                    <ReactSelect
                        aria-labelledby="partners-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '370px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                fontSize: '14px',
                                border: `1px solid ${
                                    organisation?.task_configuration?.mandatory_fields?.includes('partners') &&
                                    !state.fullTaskData?.partner_for_component?.length
                                        ? 'red'
                                        : '#a3a69b'
                                }`,
                            }),
                        }}
                        isMulti
                        onChange={async (c) => {
                            updateState({
                                ...state,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    partner_for_component: c,
                                },
                            });
                        }}
                        components={{
                            MultiValueLabel: ({ children, ...rest }) => {
                                return (
                                    <components.MultiValueLabel {...rest}>
                                        <a
                                            target={'_blank'}
                                            href={`/tasks?user_id=${rest.data.value}&raw_id=1`}
                                            style={{ textDecoration: 'none' }}
                                            rel="noreferrer"
                                        >
                                            <Flex
                                                sx={{
                                                    width: '370px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {children}
                                            </Flex>
                                        </a>
                                    </components.MultiValueLabel>
                                );
                            },
                        }}
                        placeholder="Select..."
                        value={state.fullTaskData?.partner_for_component}
                        options={[
                            ...state?.users?.map((x) => ({
                                value: x.raw_id,
                                label: x.first_name ? `${x.first_name} ${x.last_name}` : x.email_address,
                            })),
                        ]}
                    />
                </Flex>

                <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                    <Label id="preparers-label" sx={{ mr: 30, fontSize: 14 }}>
                        Preparers
                    </Label>
                    <ReactSelect
                        aria-labelledby="preparers-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '370px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                fontSize: '14px',
                                border: `1px solid ${
                                    organisation?.task_configuration?.mandatory_fields?.includes('assigned_to') &&
                                    !state.fullTaskData?.assigned_to_for_component?.length
                                        ? 'red'
                                        : '#a3a69b'
                                }`,
                            }),
                        }}
                        components={{
                            MultiValueLabel: ({ children, ...rest }) => {
                                return (
                                    <components.MultiValueLabel {...rest}>
                                        <a
                                            target={'_blank'}
                                            href={`/tasks?user_id=${rest.data.value}&raw_id=1`}
                                            style={{ textDecoration: 'none' }}
                                            rel="noreferrer"
                                        >
                                            <Flex
                                                sx={{
                                                    width: '370px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {children}
                                            </Flex>
                                        </a>
                                    </components.MultiValueLabel>
                                );
                            },
                        }}
                        isMulti
                        onChange={async (c) => {
                            updateState({
                                ...state,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    assigned_to_for_component: c,
                                },
                            });
                        }}
                        placeholder="Select..."
                        value={state.fullTaskData?.assigned_to_for_component}
                        options={[
                            ...state?.users?.map((x) => ({
                                value: x.raw_id,
                                label: x.first_name ? `${x.first_name} ${x.last_name}` : x.email_address,
                            })),
                        ]}
                    />
                </Flex>

                <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                    <Label id="reviewers-label" sx={{ mr: 30, fontSize: 14 }}>
                        Reviewers
                    </Label>
                    <ReactSelect
                        aria-labelledby="reviewers-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '370px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: `1px solid ${
                                    organisation?.task_configuration?.mandatory_fields?.includes('reviewers') &&
                                    !state.fullTaskData?.reviewer_for_component?.length
                                        ? 'red'
                                        : '#a3a69b'
                                }`,
                                fontSize: '14px',
                            }),
                        }}
                        components={{
                            MultiValueLabel: ({ children, ...rest }) => {
                                return (
                                    <components.MultiValueLabel {...rest}>
                                        <a
                                            target={'_blank'}
                                            href={`/tasks?user_id=${rest.data.value}&raw_id=1`}
                                            style={{ textDecoration: 'none' }}
                                            rel="noreferrer"
                                        >
                                            <Flex
                                                sx={{
                                                    width: '370px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {children}
                                            </Flex>
                                        </a>
                                    </components.MultiValueLabel>
                                );
                            },
                        }}
                        onChange={async (c) => {
                            updateState({
                                ...state,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    reviewer_for_component: c,
                                },
                            });
                        }}
                        isMulti
                        placeholder="Select..."
                        value={state.fullTaskData?.reviewer_for_component}
                        options={[
                            ...state?.users?.map((x) => ({
                                value: x.raw_id,
                                label: x.first_name ? `${x.first_name} ${x.last_name}` : x.email_address,
                            })),
                        ]}
                    />
                </Flex>

                <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                    <Label id="status-label" sx={{ mr: 30, fontSize: 14 }}>
                        Status
                    </Label>
                    <ReactSelect
                        aria-labelledby="status-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '370px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: `1px solid ${
                                    organisation?.task_configuration?.mandatory_fields?.includes('status') &&
                                    !state.fullTaskData?.status_for_component
                                        ? 'red'
                                        : '#a3a69b'
                                }`,
                                fontSize: '14px',
                            }),
                        }}
                        onChange={async (c) => {
                            if (
                                ['done', 'closed']?.includes(c?.value) &&
                                !!state.fullTaskData?.subtasks?.find((x) => x.status !== 'done')
                            ) {
                                updateState({
                                    ...state,
                                    subtasksIncompleteAlert: true,
                                });
                            } else {
                                updateState({
                                    ...state,
                                    fullTaskData: {
                                        ...state.fullTaskData,
                                        status_for_component: c,
                                    },
                                });
                            }
                        }}
                        placeholder="Select..."
                        value={state.fullTaskData?.status_for_component}
                        options={[
                            ...(state.organisation?.default_visualisation?.statuses?.map((x) => ({
                                value: x.key,
                                label: x.label,
                            })) || []),
                        ]}
                    />
                </Flex>

                <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                    <Label id="priority-label" sx={{ mr: 30, fontSize: 14 }}>
                        Priority
                    </Label>
                    <ReactSelect
                        aria-labelledby="priority-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '370px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                border: `1px solid ${
                                    organisation?.task_configuration?.mandatory_fields?.includes('priority') &&
                                    !state.fullTaskData?.priority_for_component
                                        ? 'red'
                                        : '#a3a69b'
                                }`,
                                fontSize: '14px',
                            }),
                        }}
                        onChange={(c) => {
                            updateState({
                                ...state,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    priority_for_component: c,
                                },
                            });
                        }}
                        placeholder="Select..."
                        value={state.fullTaskData?.priority_for_component}
                        options={[
                            ...(state.organisation?.default_visualisation?.priorities?.map((x, idx) => ({
                                value: x.key,
                                label: <PriorityItem key={`prio_${idx}`} priority={x} />,
                            })) || []),
                        ]}
                    />
                </Flex>

                <Label id="expected-fee-label" sx={{ fontWeight: '400', mb: 10, mt: 20, width: 200 }}>
                    Expected Fee (£)
                </Label>
                <Input
                    aria-labelledby="expected-fee-label"
                    value={state.fullTaskData?.additional_data?.expected_fee}
                    onChange={(e) =>
                        updateState({
                            ...state,
                            fullTaskData: {
                                ...state.fullTaskData,
                                additional_data: {
                                    ...state.fullTaskData?.additional_data,
                                    expected_fee: e.target.value,
                                },
                            },
                        })
                    }
                    placeholder="Enter the expected fee"
                    type="number"
                    sx={{
                        width: 200,
                        fontWeight: '400',
                        border: `1px solid ${
                            organisation?.task_configuration?.mandatory_fields?.includes('expected_fee') &&
                            !state.fullTaskData?.additional_data?.expected_fee
                                ? 'red'
                                : '#a3a69b'
                        }`,

                        mb: 20,
                    }}
                />

                <Label id="expected-date-billed" sx={{ fontWeight: '400', mt: 10, mb: 10, width: 190 }}>
                    Expected Date Billed
                </Label>
                <Input
                    type="date"
                    aria-labelledby="expected-date-billed"
                    value={
                        state.fullTaskData?.additional_data?.expected_date_billed
                            ? moment(state.fullTaskData?.additional_data?.expected_date_billed).format('YYYY-MM-DD')
                            : ''
                    }
                    onChange={(e) =>
                        updateState({
                            ...state,
                            fullTaskData: {
                                ...state.fullTaskData,
                                additional_data: {
                                    ...state.fullTaskData?.additional_data,
                                    expected_date_billed: e.target.value,
                                },
                            },
                        })
                    }
                    sx={{
                        width: 370,
                        fontWeight: '400',
                        border: `1px solid ${
                            organisation?.task_configuration?.mandatory_fields?.includes('expected_date_billed') &&
                            !state.fullTaskData?.additional_data?.expected_date_billed
                                ? 'red'
                                : '#a3a69b'
                        }`,

                        mb: 20,
                    }}
                />
            </Flex>
            <Flex sx={{ mt: 0, ml: 50, flexDirection: 'column' }}>
                <Paragraph sx={{ fontWeight: '600', fontSize: 18, mb: 15 }}>Sub tasks</Paragraph>

                {state.fullTaskData?.subtasks?.length > 2 && (
                    <Confetti
                        percentage={
                            (state.fullTaskData?.subtasks?.filter((x) => x.status === 'done')?.length /
                                state.fullTaskData?.subtasks?.length) *
                            100
                        }
                    />
                )}

                {state.fullTaskData?.subtasks?.length > 2 && (
                    <ProgressIndicator
                        sx={{ mb: 20, width: 600 }}
                        percentage={
                            (state.fullTaskData?.subtasks?.filter((x) => x.status === 'done')?.length /
                                state.fullTaskData?.subtasks?.length) *
                            100
                        }
                    />
                )}

                <Label id="workflows-label" sx={{ mr: 30, mb: 10 }}>
                    Copy sub tasks from workflow
                </Label>
                <Flex>
                    <ReactSelect
                        aria-labelledby="workflows-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '300px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={async (c) => {
                            updateState({
                                ...state,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    workflow: c,
                                },
                            });
                        }}
                        placeholder="Select..."
                        value={state.fullTaskData?.workflow}
                        options={state.fullTaskData?.workflows}
                    />
                    <Button
                        onClick={() => {
                            if (state.fullTaskData?.workflow) {
                                const task = state.fullTaskData?.workflowTasks?.find(
                                    (x) => x.id === state.fullTaskData?.workflow?.value
                                );
                                if (task) {
                                    updateState({
                                        ...state,
                                        fullTaskData: {
                                            ...state.fullTaskData,
                                            subtasks: task?.definition?.subtasks?.map((x) => ({
                                                ...x,
                                                uiKey: uuid.v4(),
                                                status: 'todo',
                                            })),
                                        },
                                    });
                                }
                            }
                        }}
                        sx={{ width: 100, ml: 10, height: 40, mt: 10, fontSize: 14 }}
                    >
                        <i
                            style={{
                                marginRight: '7px',
                            }}
                            className={`fas fa-copy`}
                        />
                        Copy
                    </Button>
                    <Button
                        onClick={() => {
                            updateState({
                                ...state,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    subtasks: [],
                                },
                            });
                        }}
                        sx={{
                            width: 100,
                            ml: 10,
                            height: 40,
                            mt: 10,
                            fontSize: 14,
                            color: 'text',
                            backgroundColor: 'white',
                            border: '1px solid lightGrey',
                        }}
                    >
                        <i
                            style={{
                                marginRight: '7px',
                            }}
                            className={`fas fa-times-circle`}
                        />
                        Clear
                    </Button>
                </Flex>
                <Flex sx={{ height: 20 }} />

                {state.fullTaskData?.subtasks?.map((subtask, idx) => (
                    <Subtask
                        state={state}
                        updateState={updateState}
                        organisation={organisation}
                        key={`subtask_${subtask.uiKey}`}
                        subtask={subtask}
                        idx={idx}
                    />
                ))}
                <Button
                    sx={{
                        mt: 10,
                        mb: 30,
                        width: 160,
                        fontSize: 14,
                    }}
                    onClick={() =>
                        updateState({
                            ...state,
                            fullTaskData: {
                                ...state.fullTaskData,
                                subtasks: [
                                    ...(state.fullTaskData?.subtasks || []),
                                    {
                                        uiKey: uuid.v4(),
                                        status: 'todo',
                                        edit: true,
                                        title: '',
                                        creator: `${state.organisation?.user?.first_name} ${state.organisation?.user?.last_name}`,
                                    },
                                ],
                            },
                        })
                    }
                >
                    <i
                        style={{
                            marginRight: '7px',
                        }}
                        className={`fas fa-plus-circle`}
                    />
                    Add a subtask
                </Button>

                <Paragraph sx={{ fontWeight: '600', mt: 20, fontSize: 18 }}>Actions</Paragraph>

                {state.showAttachFileForm && <AttachFile state={state} updateState={updateState} />}

                {!state.showAttachFileForm && state.task?.id && organisation?.user?.role === 'admin' && (
                    <Button
                        onClick={() =>
                            updateState({
                                ...state,
                                showDeleteTaskConfirmation: true,
                                confirmCallback: deleteTask(state, updateState, refreshCallback),
                            })
                        }
                        sx={{
                            mt: 20,
                            width: 140,
                            height: 40,
                            textAlign: 'center',
                        }}
                        variant="light"
                    >
                        <i
                            style={{
                                marginRight: '7px',
                            }}
                            className={`fas fa-times-circle`}
                        />
                        Delete task
                    </Button>
                )}

                {!state.showAttachFileForm && (
                    <Paragraph sx={{ fontWeight: '600', fontSize: 20, mt: 60 }}>Activity</Paragraph>
                )}

                {!state.showAttachFileForm && (
                    <Flex sx={{}}>
                        <Paragraph
                            onClick={() => updateState({ ...state, taskTabInFocus: 'documents' })}
                            sx={{
                                borderLeft: '1px solid lightGrey',
                                borderRight: '1px solid lightGrey',
                                borderTop: '1px solid lightGrey',
                                borderBottom: `1px solid ${
                                    state.taskTabInFocus === 'documents' ? '#FFF' : 'lightGrey'
                                }`,
                                mt: 20,
                                padding: 20,
                                fontWeight: state.taskTabInFocus === 'documents' ? '600' : '400',
                                width: 150,
                                cursor: 'pointer',
                                backgroundColor: state.taskTabInFocus === 'documents' ? '#FFF' : 'lightGrey',
                            }}
                        >
                            Documents
                        </Paragraph>
                        <Paragraph
                            onClick={() => updateState({ ...state, taskTabInFocus: 'comments' })}
                            sx={{
                                borderRight: '1px solid lightGrey',
                                borderTop: '1px solid lightGrey',
                                borderBottom: `1px solid ${state.taskTabInFocus === 'comments' ? '#FFF' : 'lightGrey'}`,
                                mt: 20,
                                cursor: 'pointer',
                                padding: 20,
                                fontWeight: state.taskTabInFocus === 'comments' ? '600' : '400',
                                width: 170,
                                backgroundColor: state.taskTabInFocus === 'comments' ? '#FFF' : 'lightGrey',
                            }}
                        >
                            Comments ({state.comments?.length || 0})
                        </Paragraph>
                        <Flex sx={{ height: '1px', width: 80, mt: 90, borderBottom: '1px solid lightGrey' }} />
                    </Flex>
                )}

                {!state.showAttachFileForm &&
                    state.taskTabInFocus === 'documents' &&
                    (state.documents || []).map((x, idx) => (
                        <Attachment
                            key={`attachment_${idx}`}
                            updateState={updateState}
                            state={state}
                            refreshCallback={loadFullTaskData}
                            data={x}
                        />
                    ))}

                {!state.showAttachFileForm && state.taskTabInFocus === 'documents' && !state.showAttachFileForm && (
                    <>
                        {state.totalDocuments > state.documentsLimit && (
                            <PgPaginationV2
                                currentState={state}
                                updateState={updateState}
                                totalPages={
                                    state.totalDocuments ? Math.ceil(state.totalDocuments / state.documentsLimit) : 1
                                }
                                totalRecords={state.totalDocuments}
                                offsetKey="taskDocumentsOffset"
                                totalKey="totalDocuments"
                                limitKey="documentsLimit"
                            />
                        )}
                        {state.taskTabInFocus === 'documents' && (
                            <Button
                                sx={{
                                    mt: state.totalDocuments > state.totalDocuments ? -20 : 30,
                                    mb: 30,
                                    width: 150,
                                    fontSize: 14,
                                }}
                                onClick={() => updateState({ ...state, showAttachFileForm: true })}
                            >
                                <i
                                    style={{
                                        marginRight: '7px',
                                    }}
                                    className={`fas fa-plus-circle`}
                                />
                                Attach a file
                            </Button>
                        )}
                    </>
                )}

                {!state.showAttachFileForm &&
                    state.taskTabInFocus === 'comments' &&
                    (state.comments || []).map((x, idx) => (
                        <Comment
                            key={`comment_${idx}`}
                            updateState={updateState}
                            state={state}
                            refreshCallback={refreshCallback}
                            data={x}
                        />
                    ))}

                {state.showSubmitCommentForm && !state.showAttachFileForm && state.taskTabInFocus === 'comments' && (
                    <AddAComment state={state} updateState={updateState} />
                )}

                {!state.showSubmitCommentForm && !state.showAttachFileForm && (
                    <>
                        {state.totalComments > state.commentsLimit && state.taskTabInFocus === 'comments' && (
                            <PgPaginationV2
                                currentState={state}
                                updateState={updateState}
                                totalPages={
                                    state.totalComments ? Math.ceil(state.totalComments / state.commentsLimit) : 1
                                }
                                totalRecords={state.totalComments}
                                offsetKey="taskCommentsOffset"
                                totalKey="totalComments"
                                limitKey="commentsLimit"
                            />
                        )}
                        {state.taskTabInFocus === 'comments' && (
                            <Button
                                sx={{
                                    mt: state.totalComments > state.commentsLimit ? -20 : 30,
                                    mb: 30,
                                    width: 200,
                                    fontSize: 14,
                                }}
                                onClick={() => updateState({ ...state, showSubmitCommentForm: true })}
                            >
                                <i
                                    style={{
                                        marginRight: '7px',
                                    }}
                                    className={`fas fa-plus-circle`}
                                />
                                Add a comment
                            </Button>
                        )}
                    </>
                )}
            </Flex>
        </Flex>
    ) : null;
};

export default Task;
