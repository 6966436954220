/* eslint-disable no-undef */
import axios from 'axios';
import { useDrag, useDrop } from 'react-dnd';
import ReactQuill from 'react-quill';
import * as uuid from 'uuid';
import 'react-quill/dist/quill.snow.css';
import ReactSelect from 'react-select';
import { Button, Checkbox, Flex, Input, Label, Paragraph, Switch, Textarea } from 'theme-ui';
import Divider from '../divider';
import moment from 'moment';
import { toast } from 'react-toastify';
import Overlay from '../overlay/overlay';
import TaskSchedulingForm, { defaultTask } from './task-scheduling';
import { useState } from 'react';

export const clientTypeOptions = [
    { value: 'all', label: 'Companies and Individuals' },
    { value: 'company', label: 'Companies only' },
    { value: 'individual', label: 'Individuals only' },
];

export const frequencyOptions = [
    { value: 'on_ch_filing_date', label: 'On Companies House filing date' },
    { value: 'on_hmrc_filing_date', label: 'On HMRC filing date' },
    { value: 'on_creation', label: 'On client creation' },
    { value: 'on_specific_date', label: 'On a specific date' },
    { value: '01', label: '1st of the month' },
    { value: '02', label: '2nd of the month' },
    { value: '03', label: '3rd of the month' },
    { value: '04', label: '4th of the month' },
    { value: '05', label: '5th of the month' },
    { value: '06', label: '6th of the month' },
    { value: '07', label: '7th of the month' },
    { value: '08', label: '8th of the month' },
    { value: '09', label: '9th of the month' },
    { value: '10', label: '10th of the month' },
    { value: '11', label: '11th of the month' },
    { value: '12', label: '12th of the month' },
    { value: '13', label: '13th of the month' },
    { value: '14', label: '14th of the month' },
    { value: '15', label: '15th of the month' },
    { value: '16', label: '16th of the month' },
    { value: '17', label: '17th of the month' },
    { value: '18', label: '18th of the month' },
    { value: '19', label: '19th of the month' },
    { value: '20', label: '20th of the month' },
    { value: '21', label: '21st of the month' },
    { value: '22', label: '22nd of the month' },
    { value: '23', label: '23rd of the month' },
    { value: '24', label: '24th of the month' },
    { value: '25', label: '25th of the month' },
    { value: '26', label: '26th of the month' },
    { value: '27', label: '27th of the month' },
    { value: '28', label: '28th of the month' },
    { value: '29', label: '29th of the month' },
    { value: '30', label: '30th of the month' },
    { value: '31', label: '31st of the month' },
];

const internalDateOptions = [
    {
        value: -1,
        label: '1 day before statutory deadline',
    },
    {
        value: -2,
        label: '2 days before statutory deadline',
    },
    {
        value: -3,
        label: '3 days before statutory deadline',
    },
    {
        value: -4,
        label: '4 days before statutory deadline',
    },
    {
        value: -5,
        label: '5 days before statutory deadline',
    },
    {
        value: -6,
        label: '6 days before statutory deadline',
    },
    {
        value: -7,
        label: '7 days before statutory deadline',
    },
    {
        value: -30,
        label: '1 month before statutory deadline',
    },
    {
        value: 7,
        label: '1 week after Relevant Period end',
    },
    {
        value: 30,
        label: '1 month after Relevant Period end',
    },
];

const saveWorkflowTask = async (state, updateState, taskScheduling, setTasks) => {
    try {
        updateState({ ...state, loading: true, editError: null, confirmMessage: null });

        const frequencyValues = state.workflowTask?.newFrequencySelection?.map((x) => x.value);

        const clientTypesValue = state.workflowTask?.clientTypeSelection?.value || 'all';

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/workflow-tasks${
                state?.workflowTask?.id ? `/${state?.workflowTask?.id}` : ''
            }`,
            method: state?.workflowTask?.id ? 'PUT' : 'POST',
            data: {
                service: state.workflowTask?.newWorkflowTaskService?.value,
                definition: {
                    relevantPeriodEnd: state.workflowTask?.definition?.relevantPeriodEnd,
                    title: state.workflowTask?.title,
                    status: state.workflowTask?.status,
                    description: state.workflowTask?.description,
                    datesToSchedule: state.workflowTask?.datesToSchedule?.map((date) => {
                        if (date.day?.length === 1) {
                            date.day = `0${date.day}`;
                        }
                        if (date.month?.length === 1) {
                            date.month = `0${date.month}`;
                        }
                        return date;
                    }),
                    frequency: frequencyValues,
                    client_type: clientTypesValue,
                    subtasks: state.workflowTask?.subtasks || [],
                    priority: state.workflowTask?.priority,
                    sendNotification: state.workflowTask?.sendNotification,
                    ...(state.workflowTask?.sendNotification
                        ? { notificationTemplate: state.workflowTask.notificationTemplate }
                        : {}),
                    whenToSetInternalDueDateComparator: state.workflowTask?.whenToSetInternalDueDateComparator,
                    whenToSetInternalDueDateUnitOfTime: state.workflowTask?.whenToSetInternalDueDateUnitOfTime,
                    whenToSetInternalDueDateCount: state.workflowTask?.whenToSetInternalDueDateCount,
                    whenToSetStatutoryDueDateComparator: state.workflowTask?.whenToSetStatutoryDueDateComparator,
                    whenToSetStatutoryDueDateUnitOfTime: state.workflowTask?.whenToSetStatutoryDueDateUnitOfTime,
                    whenToSetStatutoryDueDateCount: state.workflowTask?.whenToSetStatutoryDueDateCount,
                    dueDateSpecificMonth: state.workflowTask?.dueDateSpecificMonth,
                    dueDateSpecificDay: state.workflowTask?.dueDateSpecificDay,
                    dueDateSpecificMonthComparator: state.workflowTask?.dueDateSpecificMonthComparator,
                    statutoryDueDateSpecificMonth: state.workflowTask?.statutoryDueDateSpecificMonth,
                    statutoryDueDateSpecificDay: state.workflowTask?.statutoryDueDateSpecificDay,
                    autoGenerateTitle: state.workflowTask?.autoGenerateTitle,
                    expected_fee: state.workflowTask?.expected_fee,
                    statutoryDueDateSpecificMonthComparator:
                        state.workflowTask?.statutoryDueDateSpecificMonthComparator,
                    taskScheduling,
                },
            },
        });

        const {
            data: { tasks, total: workflowTasksTotal },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/workflow-tasks`,
        });

        updateState({
            ...state,
            loading: false,
            editError: null,
            workflowTask: null,
            confirmMessage: null,
            workflowTasksTotal,
            tasks,
            workflowView: 'list',
        });

        setTasks([defaultTask()]);

        window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            confirmMessage: null,
        });
        toast('Something has gone wrong saving your task, please ensure all fields are set', { type: 'error' });
    }
};

export const deepClone = (obj) => (obj ? JSON.parse(JSON.stringify(obj)) : null);

const Subtask = ({ state, updateState, subtask, idx, organisation }) => {
    const [collected, drag, dragPreview] = useDrag(
        () => ({
            type: 'subtask',
            item: { subtask, idx },
            end: (item, monitor) => {
                if (state.idxMovingTo === state.idxOfItemMoving) return;

                const newSubtasks = deepClone([...(state.workflowTask?.subtasks || [])]);

                const itemToMove = deepClone(newSubtasks[state.idxOfItemMoving]);
                const existingAtLocation = deepClone(newSubtasks[state.idxMovingTo]);

                newSubtasks[state.idxMovingTo] = itemToMove;
                newSubtasks[state.idxOfItemMoving] = existingAtLocation;

                updateState({
                    ...state,
                    workflowTask: {
                        ...state.workflowTask,
                        subtasks: newSubtasks,
                    },
                    idxMovingTo: -1,
                    idxOfItemMoving: -1,
                });
            },
        }),
        [state.idxMovingTo, state.idxOfItemMoving, subtask]
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'subtask',
            hover(item) {
                updateState({
                    ...state,
                    idxOfItemMoving: idx,
                    idxMovingTo: item.idx,
                });
            },
        }),
        [state.idxMovingTo, state.idxOfItemMoving]
    );

    return collected.isDragging ? (
        <div ref={dragPreview} />
    ) : (
        <div ref={(node) => drag(drop(node))} {...collected}>
            {state.showSubTaskHelpVideo && (
                <Overlay
                    copy={{}}
                    customSx={{ ml: 120, width: 'auto', height: 'auto' }}
                    embeddedComponent={
                        <Flex
                            sx={{
                                bg: 'white',
                                borderRadius: '8px',
                                padding: 4,
                                position: 'relative',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <video
                                style={{
                                    width: '90%',
                                    maxWidth: 900,
                                    aspectRatio: '16 / 9',
                                }}
                                controls
                            >
                                <source src={state.showSubTaskHelpVideoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Flex>
                    }
                    updateOverlay={() => updateState({ ...state, showSubTaskHelpVideo: false })}
                />
            )}

            <Flex sx={{ cursor: 'pointer', mb: 10, padding: 20, border: '1px solid #EFEFEF', flexDirection: 'column' }}>
                <Flex sx={{ mb: 10, alignItems: 'center' }} key={`subtask_${idx}`}>
                    <Textarea
                        value={subtask.title}
                        onChange={(e) => {
                            const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                            newSubtasks[idx].title = e.target.value;
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: newSubtasks,
                                },
                            });
                        }}
                        sx={{ width: 450, fontWeight: '400', border: '1px solid #a3a69b', mb: 10 }}
                    />
                    <Flex>
                        <Button
                            sx={{ ml: 20 }}
                            variant="light"
                            onClick={() => {
                                const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                                newSubtasks.splice(idx, 1);
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        subtasks: newSubtasks,
                                    },
                                });
                            }}
                        >
                            <i
                                style={{
                                    marginTop: '0px',
                                    fontSize: '14px',
                                    marginRight: '7px',
                                    color: 'red',
                                    cursor: 'pointer',
                                }}
                                className="fal fa-trash"
                            />
                            Delete
                        </Button>
                    </Flex>
                </Flex>

                <Flex sx={{ alignItems: 'center', mb: 20 }}>
                    {subtask.video_url ? (
                        <Button
                            sx={{ ml: 10, mr: 10, height: 40 }}
                            variant="light"
                            onClick={async () => {
                                const {
                                    data: { url },
                                } = await axios.get(
                                    `${process.env.REACT_APP_AQRU_AI_API}/documents/${subtask.video_url}`,
                                    {}
                                );
                                updateState({
                                    ...state,
                                    showSubTaskHelpVideo: true,
                                    showSubTaskHelpVideoUrl: url,
                                });
                            }}
                        >
                            <i
                                style={{
                                    marginRight: '7px',
                                }}
                                className="fas fa-video"
                            />{' '}
                            Video
                        </Button>
                    ) : null}
                    <Label
                        sx={{
                            display: 'inline-block',
                            padding: '10px 20px',
                            color: '#000',
                            backgroundColor: 'white',
                            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.3)',
                            height: 40,
                            borderRadius: '20px',
                            cursor: 'pointer',
                            fontSize: '14px',
                            border: '1px solid #ccc',
                            transition: 'background-color 0.3s',
                            width: '130px',
                            '&:hover': {
                                backgroundColor: '#EFEFEF',
                                color: '#000',
                            },
                        }}
                    >
                        <i
                            style={{
                                cursor: 'pointer',
                                marginRight: '10px',
                            }}
                            className="fas fa-video"
                            aria-hidden="true"
                        />
                        Upload
                        <Input
                            id="ref_file_input"
                            sx={{
                                display: 'none',
                            }}
                            accept=".mp4"
                            type="file"
                            onChange={async (e) => {
                                try {
                                    updateState({ ...state, loading: true });

                                    const newFile = [...e.target.files]?.[0];

                                    const {
                                        data: { document_uuid, presigned_url },
                                    } = await axios({
                                        url: `${process.env.REACT_APP_AQRU_AI_API}/documents`,
                                        method: 'POST',
                                        data: {
                                            content_type: newFile.type,
                                            file_name: newFile.name,
                                        },
                                    });

                                    await axios.put(presigned_url, newFile, {
                                        headers: { 'Content-Type': newFile.type },
                                    });

                                    const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                                    newSubtasks[idx].video_url = document_uuid;
                                    updateState({
                                        ...state,
                                        workflowTask: {
                                            ...state.workflowTask,
                                            subtasks: newSubtasks,
                                        },
                                    });

                                    document.getElementById('ref_file_input').value = '';
                                } catch (e) {
                                    console.log(e);
                                    updateState({
                                        ...state,
                                        loading: false,
                                    });
                                    toast.error('We are currently unable to upload your file');
                                }
                            }}
                        />
                    </Label>
                    {subtask.video_url ? (
                        <Button
                            sx={{ ml: 10, height: 40 }}
                            variant="light"
                            onClick={async () => {
                                const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                                newSubtasks[idx].video_url = null;
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        subtasks: newSubtasks,
                                    },
                                });
                            }}
                        >
                            <i
                                style={{
                                    marginRight: '7px',
                                    color: 'red',
                                }}
                                className="fal fa-trash"
                            />{' '}
                            Remove video
                        </Button>
                    ) : null}
                </Flex>

                <Flex sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Switch
                        label="Mark as resolved when third party api updates"
                        sx={{ cursor: 'pointer' }}
                        defaultChecked={subtask.autoResolveUsingWorkflow}
                        onClick={() => {
                            const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                            newSubtasks[idx].autoResolveUsingWorkflow = subtask.autoResolveUsingWorkflow ? false : true;
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: newSubtasks,
                                },
                            });
                        }}
                    />
                </Flex>
                <Flex sx={{ mt: 15, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Switch
                        label="Require user to be an administrator to edit"
                        sx={{ cursor: 'pointer' }}
                        defaultChecked={subtask.requireAdminPermission}
                        onClick={() => {
                            const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                            newSubtasks[idx].requireAdminPermission = subtask.requireAdminPermission ? false : true;
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: newSubtasks,
                                },
                            });
                        }}
                    />
                </Flex>
                <Flex sx={{ mt: 15, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Switch
                        label="Change status when marked as complete"
                        sx={{ cursor: 'pointer' }}
                        defaultChecked={subtask.changeStatus}
                        onClick={() => {
                            const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                            newSubtasks[idx].changeStatus = subtask.changeStatus ? false : true;
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: newSubtasks,
                                },
                            });
                        }}
                    />
                    {state.workflowTask?.subtasks?.[idx]?.changeStatus && (
                        <Flex sx={{ ml: 48, mt: 0, alignItems: 'center' }}>
                            <Label id="status-label" sx={{ mr: 10 }}>
                                Set task status to
                            </Label>
                            <ReactSelect
                                aria-labelledby="status-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '250px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        marginBottom: '10px',
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                    }),
                                }}
                                onChange={async (c) => {
                                    const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                                    newSubtasks[idx].changeStatusTo = c.value;
                                    updateState({
                                        ...state,
                                        workflowTask: {
                                            ...state.workflowTask,
                                            subtasks: newSubtasks,
                                        },
                                    });
                                }}
                                placeholder="Select..."
                                value={
                                    subtask.changeStatusTo
                                        ? {
                                              value: subtask.changeStatusTo,
                                              label: organisation?.default_visualisation?.statuses?.find(
                                                  (x) => x.key === subtask?.changeStatusTo
                                              )?.label,
                                          }
                                        : null
                                }
                                options={[
                                    ...(organisation?.default_visualisation?.statuses?.map((x) => ({
                                        value: x.key,
                                        label: x.label,
                                    })) || []),
                                ]}
                            />
                        </Flex>
                    )}
                </Flex>
                <Flex sx={{ mt: 15, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Switch
                        label="Suggest email template when complete"
                        sx={{ cursor: 'pointer' }}
                        defaultChecked={subtask.suggestEmailTemplate}
                        onClick={() => {
                            const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                            newSubtasks[idx].suggestEmailTemplate = subtask.suggestEmailTemplate ? false : true;

                            if (subtask.suggestEmailTemplate) {
                                newSubtasks[idx].emailTemplateToSuggest = null;
                            }

                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: newSubtasks,
                                },
                            });
                        }}
                    />
                    {state.workflowTask?.subtasks?.[idx]?.suggestEmailTemplate && (
                        <Flex sx={{ ml: 48, mt: 0, alignItems: 'center' }}>
                            <Label id="email-tem-label" sx={{ mr: 10, width: 115 }}>
                                Email template
                            </Label>
                            <ReactSelect
                                aria-labelledby="email-tem-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '250px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        marginBottom: '10px',
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                    }),
                                }}
                                onChange={async (c) => {
                                    const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                                    newSubtasks[idx].emailTemplateToSuggest = c.value;
                                    updateState({
                                        ...state,
                                        workflowTask: {
                                            ...state.workflowTask,
                                            subtasks: newSubtasks,
                                        },
                                    });
                                }}
                                placeholder="Select..."
                                value={
                                    subtask.emailTemplateToSuggest
                                        ? {
                                              value: subtask.emailTemplateToSuggest,
                                              label: state.emailTemplates?.find(
                                                  (x) => x.uuid === subtask?.emailTemplateToSuggest
                                              )?.name,
                                          }
                                        : null
                                }
                                options={[
                                    ...(state.emailTemplates.map((x) => ({
                                        value: x.uuid,
                                        label: x.name,
                                    })) || []),
                                ]}
                            />
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </div>
    );
};

const ManageWorkflow = ({ state, updateState, workflowTask, organisation }) => {
    const [, drop] = useDrop(() => ({
        accept: 'subtask',
    }));

    const services = Object.entries(organisation?.workflow_definition?.services || {}).map((x) => ({
        value: x[0],
        label: x[1]?.label,
    }));

    const [tasks, setTasks] = useState(state.workflowTask?.definition?.taskScheduling || [defaultTask()]);

    return (
        <Flex sx={{ flexDirection: 'column', mb: 150 }}>
            <Flex
                sx={{
                    position: 'fixed',
                    height: 80,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    borderTop: '2px solid #EFEFEF',
                    width: '100%',
                    zIndex: 998,
                }}
            >
                <Button
                    sx={{
                        height: '40px',
                        width: 100,
                        ml: 20,
                    }}
                    variant="light"
                    onClick={async () => {
                        updateState({
                            ...state,
                            userView: 'list',
                            workflowView: 'list',
                            user: null,
                            newRole: null,
                            confirmMessage: null,
                            editError: null,
                            error: null,
                        });
                    }}
                >
                    <i
                        style={{
                            marginTop: '0px',
                            fontSize: '14px',
                            marginRight: '7px',
                        }}
                        className={`fal fa-chevron-left`}
                    />
                    Back
                </Button>
                <Button
                    sx={{
                        height: 40,
                        width: 100,
                        ml: 10,
                    }}
                    onClick={async () => {
                        await saveWorkflowTask(state, updateState, tasks, setTasks);
                    }}
                >
                    <i
                        style={{
                            marginTop: '0px',
                            fontSize: '14px',
                            marginRight: '7px',
                            cursor: 'pointer',
                        }}
                        className="fal fa-save"
                    />
                    Save
                </Button>
            </Flex>

            <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                <h2 style={{ fontWeight: 300, fontSize: 20 }}>Set the basic task details</h2>
                <Divider mt={0} />
            </Flex>

            <Flex sx={{ flexDirection: 'column' }}>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Flex sx={{ mt: 0, flexDirection: 'column', width: 300 }}>
                        <Label id="client-type-label" sx={{ mr: 30 }}>
                            Client types
                        </Label>
                        <ReactSelect
                            aria-labelledby="client-type-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                }),
                            }}
                            onChange={async (c) => {
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        clientTypeSelection: c,
                                    },
                                });
                            }}
                            placeholder="Select which client types apply"
                            value={state.workflowTask?.clientTypeSelection || clientTypeOptions[0]}
                            options={clientTypeOptions}
                        />
                    </Flex>
                    <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                        <Flex sx={{ alignItems: 'center', width: 410, justifyContent: 'space-between' }}>
                            <Label id="task-title" sx={{ width: 200 }}>
                                Task title
                            </Label>
                            <Flex
                                sx={{ alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => {
                                    updateState({
                                        ...state,
                                        workflowTask: {
                                            ...state.workflowTask,
                                            autoGenerateTitle: state.workflowTask.autoGenerateTitle ? false : true,
                                        },
                                    });
                                }}
                            >
                                <Label sx={{ mr: '5px' }}>Auto generate task title</Label>
                                <Checkbox checked={state.workflowTask.autoGenerateTitle} />
                            </Flex>
                        </Flex>
                        <Input
                            aria-labelledby="task-title"
                            value={state.workflowTask?.title || ''}
                            onChange={(e) =>
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        title: e.target.value,
                                    },
                                })
                            }
                            sx={{
                                width: 400,
                                mt: 10,
                                fontWeight: '400',
                                border: '1px solid #ccc',
                                backgroundColor: state.workflowTask.autoGenerateTitle ? '#EFEFEF' : 'white',
                                mb: '5px',
                            }}
                        />
                        <Paragraph sx={{ fontSize: 12, mb: 15 }}>
                            Auto generating a title will result in it being set to{' '}
                            {'client name - service - relevant period end date'}
                        </Paragraph>
                    </Flex>

                    <Flex sx={{ mt: 10, flexDirection: 'column', width: 600, mb: 60 }}>
                        <Label id="task-desc" sx={{ mb: 10 }}>
                            Task description
                        </Label>
                        <ReactQuill
                            theme="snow"
                            value={state.workflowTask?.description}
                            style={{ minHeight: 200 }}
                            onChange={(val) =>
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        description: val,
                                    },
                                })
                            }
                        />
                    </Flex>

                    <Flex sx={{ mt: 10, flexDirection: 'column', mb: 10 }}>
                        <Label id="priority-label" sx={{ mr: 30 }}>
                            Priority
                        </Label>
                        <ReactSelect
                            aria-labelledby="priority-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={(c) => {
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        priority: c.value,
                                    },
                                });
                            }}
                            placeholder="Select..."
                            value={organisation?.default_visualisation?.priorities?.find(
                                (x) => x.key === state.workflowTask?.priority
                            )}
                            options={[
                                ...(organisation?.default_visualisation?.priorities?.map((x, idx) => ({
                                    value: x.key,
                                    label: x.label,
                                })) || []),
                            ]}
                        />
                    </Flex>

                    <Flex sx={{ mt: 10, flexDirection: 'column', mb: 10 }}>
                        <Label id="status-label" sx={{ mr: 30 }}>
                            Status
                        </Label>
                        <ReactSelect
                            aria-labelledby="status-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={(c) => {
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        status: c.value,
                                    },
                                });
                            }}
                            placeholder="Select..."
                            value={organisation?.default_visualisation?.statuses?.find(
                                (x) => x.key === state.workflowTask?.status
                            )}
                            options={[
                                ...(organisation?.default_visualisation?.statuses?.map((x, idx) => ({
                                    value: x.key,
                                    label: x.label,
                                })) || []),
                            ]}
                        />
                    </Flex>

                    <Flex sx={{ mt: 10, flexDirection: 'column', width: 300 }}>
                        <Label id="service-label" sx={{ mr: 30 }}>
                            Service
                        </Label>
                        <ReactSelect
                            aria-labelledby="service-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                }),
                            }}
                            onChange={async (c) => {
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        newWorkflowTaskService: c,
                                    },
                                });
                            }}
                            placeholder="Select a service this task belongs to"
                            value={state.workflowTask?.newWorkflowTaskService}
                            options={services}
                        />
                    </Flex>

                    <Divider mt={20} />

                    <TaskSchedulingForm tasks={tasks} setTasks={setTasks} />
                </Flex>
                <Divider />
                <Flex sx={{ flexDirection: 'column', mt: 0 }}>
                    <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <h3 style={{ fontWeight: 300 }}>Setup the subtasks</h3>
                    </Flex>

                    <Divider mt={0} />

                    <Label id="workflows-label" sx={{ mt: 0, mr: 30, mb: 10 }}>
                        Copy sub tasks from another template
                    </Label>
                    <Flex>
                        <ReactSelect
                            aria-labelledby="workflows-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '300px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={async (c) => {
                                updateState({
                                    ...state,
                                    selectedWorkflow: c,
                                });
                            }}
                            placeholder="Select..."
                            value={state.selectedWorkflow}
                            options={state.tasks
                                ?.map(
                                    (x) =>
                                        ({
                                            value: x.id,
                                            label:
                                                x.definition?.title ||
                                                services.find((svc) => svc.value === x.service)?.label,
                                            subtasks: x.definition?.subtasks,
                                        } || [])
                                )
                                .filter((x) => {
                                    return x.value !== state.workflowTask?.id && x.subtasks?.length;
                                })}
                        />
                        <Button
                            onClick={() => {
                                if (state.selectedWorkflow) {
                                    updateState({
                                        ...state,
                                        workflowTask: {
                                            ...state.workflowTask,
                                            subtasks: state.selectedWorkflow?.subtasks?.map((x) => ({
                                                ...x,
                                                uiKey: uuid.v4(),
                                            })),
                                        },
                                    });
                                }
                            }}
                            sx={{ width: 160, ml: 10, height: 40, mt: 10, fontSize: 14 }}
                        >
                            <i
                                style={{
                                    marginRight: '7px',
                                }}
                                className={`fas fa-copy`}
                            />
                            Copy sub tasks
                        </Button>
                        <Button
                            onClick={() => {
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        subtasks: [],
                                    },
                                });
                            }}
                            sx={{
                                width: 160,
                                ml: 10,
                                height: 40,
                                mt: 10,
                                fontSize: 14,
                                color: 'text',
                                backgroundColor: 'white',
                                border: '1px solid lightGrey',
                            }}
                        >
                            <i
                                style={{
                                    marginRight: '7px',
                                }}
                                className={`fas fa-times-circle`}
                            />
                            Clear subtasks
                        </Button>
                    </Flex>
                    <Flex sx={{ height: 20 }} />

                    <Divider width="600px" />

                    <Button
                        sx={{ width: 100, mb: 20 }}
                        onClick={() =>
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: [
                                        ...(state.workflowTask?.subtasks || []),
                                        {
                                            title: '',
                                            autoResolveUsingWorkflow: false,
                                            uiKey: uuid.v4(),
                                        },
                                    ],
                                },
                            })
                        }
                    >
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '14px',
                                marginRight: '7px',
                                cursor: 'pointer',
                            }}
                            className="fas fa-plus-circle"
                        />
                        Add
                    </Button>

                    <Flex sx={{ flexDirection: 'column' }} ref={drop}>
                        {state.workflowTask?.subtasks?.map((x, idx) => (
                            <Subtask
                                state={state}
                                updateState={updateState}
                                key={`subss_${idx}_${x.uiKey}}`}
                                idx={idx}
                                subtask={x}
                                organisation={organisation}
                            />
                        ))}
                    </Flex>
                </Flex>

                <h3 style={{ fontWeight: 300 }}>Send Notifications when task created</h3>

                <Flex sx={{ mb: 20, flexDirection: 'column', mt: 0 }}>
                    <Switch
                        aria-labelledby="send-not-label"
                        sx={{ cursor: 'pointer' }}
                        defaultChecked={state?.workflowTask?.sendNotification}
                        onClick={() => {
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    sendNotification: state?.workflowTask?.sendNotification ? false : true,
                                },
                            });
                        }}
                    />
                </Flex>

                {state.workflowTask?.sendNotification && (
                    <Flex sx={{ mt: 10, flexDirection: 'column', width: 600, mb: 60 }}>
                        <Label sx={{ mb: 10 }}>Notification template</Label>
                        <ReactQuill
                            theme="snow"
                            value={state.workflowTask?.notificationTemplate}
                            style={{ minHeight: 200 }}
                            onChange={(val) =>
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        notificationTemplate: val,
                                    },
                                })
                            }
                        />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default ManageWorkflow;
