import { Flex, Input, Label, Switch, Textarea } from 'theme-ui';
import { handleFileUpload } from './doc-sign';

const DocView = ({ localState, updateLocalState, setHasUnsavedChanges }) => {
    const record = localState?.archive_information?.find((x) => x.pdfIndex === localState.pdfIndex);
    const archived = !!record;

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Label
                sx={{
                    mt: 30,
                    display: 'inline-block',
                    padding: '10px 20px',
                    color: '#fff',
                    backgroundColor: 'primary',
                    borderRadius: '15px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    transition: 'background-color 0.3s',
                    width: 400,
                    '&:hover': {
                        backgroundColor: '#EFEFEF',
                        color: '#000',
                    },
                }}
            >
                <i
                    style={{
                        fontSize: '18px',
                        cursor: 'pointer',
                        marginRight: '10px',
                    }}
                    className="fas fa-file-pdf"
                    aria-hidden="true"
                />
                Select your PDF to add signatures
                <Input
                    id="file_input"
                    sx={{
                        display: 'none',
                    }}
                    accept=".pdf"
                    type="file"
                    onChange={async (e) => {
                        await handleFileUpload(e, localState, updateLocalState, setHasUnsavedChanges);
                    }}
                />
            </Label>

            <Flex sx={{ mt: 20 }}>
                <Label sx={{ mr: 10, width: 60, fontSize: 14 }}>Archive</Label>
                <Flex sx={{ width: 50 }}>
                    <Switch
                        checked={archived}
                        onClick={() =>
                            updateLocalState({
                                ...localState,
                                archive_information: archived
                                    ? localState.archive_information.filter((item) => {
                                          if (item.pdfIndex === localState.pdfIndex) {
                                              return false;
                                          }
                                          return true;
                                      })
                                    : [
                                          ...localState.archive_information,
                                          {
                                              pdfIndex: localState.pdfIndex,
                                              reason: '',
                                          },
                                      ],
                            })
                        }
                        sx={{ mb: 10 }}
                    />
                </Flex>
            </Flex>

            {archived && (
                <Flex sx={{ alignItems: 'center', mt: 10, mb: 10 }}>
                    <Label sx={{ mr: 10, width: 60 }}>Reason</Label>
                    <Textarea
                        value={record?.reason || ''}
                        onChange={(e) => {
                            updateLocalState({
                                ...localState,
                                archive_information: localState.archive_information.map((item) => {
                                    if (item.pdfIndex === localState.pdfIndex) {
                                        item.reason = e.target.value;
                                    }
                                    return item;
                                }),
                            });
                        }}
                        sx={{ width: 500 }}
                    />
                </Flex>
            )}
        </Flex>
    );
};

export default DocView;
