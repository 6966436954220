/* eslint-disable no-undef */
/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
import { Paragraph, Flex, Box, Image, Link, Card, Heading, Text } from 'theme-ui';
import { useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Input from '../components/form-input';
import Button from '../components/button';
import Spinner from '../components/spinner';
import PasswordValidation from '../components/password-validation';
import mixpanel from 'mixpanel-browser';
import axios from 'axios';
import Waves from './waves';
import Modal from '../components/modal';
import { toast } from 'react-toastify';

const confirmSignIn = async (state, updateState, navigate) => {
    try {
        updateState({ ...state, loading: true, error: null });

        if (state.challenge === 'email_mfa') {
            const result = await Auth.sendCustomChallengeAnswer(state.cognitoUser, state.code.trim());
            if (!result.signInUserSession) throw { code: 'CodeMismatchException' };
        } else {
            await Auth.confirmSignIn(state.cognitoUser, state.code, state.cognitoUser?.challengeName);
        }

        try {
            mixpanel.identify(state.cognitoUser?.username);
        } catch (exx) {
            // suppress
        }

        return window.location.assign('/home');
    } catch (e) {
        let error = 'We are currently unable to log you in, please try again later';
        if (e.code === 'NotAuthorizedException') {
            error = 'Username and/or password are incorrect.';
        } else if (e.code === 'CodeMismatchException') {
            error = 'The code entered does not match';
        } else if (e.code === 'ExpiredCodeException') {
            error = 'The code has already been used once';
        }

        updateState({ ...state, loading: false, error });
    }
};

const logUserIn = async (currentState, updateState, navigate) => {
    try {
        Auth.configure({
            authenticationFlowType: 'USER_SRP_AUTH',
        });

        updateState({ ...currentState, loading: true, error: null });
        const { username, password } = currentState;

        const user = await Auth.signIn(username.toLowerCase().trim(), password.trim());

        if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return updateState({
                ...currentState,
                cognitoUser: user,
                challenge: 'new_password',
                error: null,
            });
        }

        if (user?.attributes && user?.attributes['custom:source_v2'] === 'portal_user') {
            Auth.configure({
                authenticationFlowType: 'CUSTOM_AUTH',
            });

            try {
                await Auth.setPreferredMFA(user, 'NOMFA');
            } catch (e) {}

            await Auth.signOut();

            const user = await Auth.signIn(username.toLowerCase().trim());

            return updateState({
                ...currentState,
                cognitoUser: user,
                challenge: 'email_mfa',
                error: null,
            });
        }

        if (user?.challengeName === 'SMS_MFA' || user?.challengeName === 'SOFTWARE_TOKEN_MFA') {
            return updateState({
                ...currentState,
                cognitoUser: user,
                challenge: 'mfa',
                error: null,
            });
        }

        return window.location.assign('/home');
    } catch (e) {
        console.log(e);
        let error = 'We are currently unable to log you in, please try again later';
        if (e.code === 'NotAuthorizedException') {
            error = 'Username and/or password are incorrect.';
        } else if (e.code === 'CodeMismatchException') {
            error = 'The code entered does not match';
        } else if (e.code === 'InvalidPasswordException') {
            error = 'Please make sure you use an upper case and lower case letter plus a digit in your password';
        } else if (e.code === 'ExpiredCodeException') {
            error = 'The code has already been used once';
        } else if (e.code === 'UserNotFoundException') {
            error = 'Username and/or password are incorrect.';
        }

        if (e?.message?.includes('Password attempts exceeded')) {
            error = 'Password attempts exceeded, please contact support.';
        }

        const newFailedAttempts = currentState.failedAttempts + 1;

        if (newFailedAttempts >= 3) {
            try {
                await Auth.forgotPassword(currentState.username.toLowerCase().trim());

                updateState({
                    ...currentState,
                    loading: false,
                    failedAttempts: 0,
                    showModal: true,
                });
            } catch (e) {
                toast.error(error);
                return updateState({
                    ...currentState,
                    loading: false,
                    failedAttempts: newFailedAttempts,
                });
            }
        } else {
            toast.error(error);
            return updateState({
                ...currentState,
                loading: false,
                failedAttempts: newFailedAttempts,
            });
        }
    }
};

const EnterMfaCode = ({ currentState, updateState, navigate }) => {
    useEffect(() => {
        setTimeout(() => {
            document.getElementById('code')?.focus();
        }, 300);
    }, []);

    return (
        <Flex sx={{ justifyContent: 'center', flexDirection: 'column' }}>
            <Paragraph
                sx={{
                    fontSize: 18,
                    fontWeight: '500',
                    mt: 30,
                    textAlign: ['center', 'center'],
                    alignSelf: ['center', 'center'],
                }}
            >
                {currentState.cognitoUser?.challengeName === 'SOFTWARE_TOKEN_MFA'
                    ? 'Enter the code from your authenticator app'
                    : 'Enter the code we have sent to your email address'}
            </Paragraph>
            <Flex
                sx={{
                    mb: 40,
                    mt: 4,
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Input
                    sx={{
                        mb: 25,
                        alignSelf: 'center',
                        width: [280, 400],
                        background: '#FFFFFF',
                        textAlign: 'center',
                    }}
                    type="code"
                    id="code"
                    data-testid="code"
                    name="mfa-code"
                    label=""
                    placeholder="Enter the one time passcode"
                    onChange={(e) => updateState({ ...currentState, code: e.target.value })}
                    error={null}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            confirmSignIn(currentState, updateState, navigate);
                        }
                    }}
                />
                <Button
                    variant="primary-wide"
                    sx={{ alignSelf: 'center', zIndex: 1000 }}
                    data-testid="submit"
                    onClick={() => confirmSignIn(currentState, updateState, navigate)}
                >
                    Submit
                </Button>
                <Link
                    onClick={() =>
                        updateState({
                            ...currentState,
                            cognitoUser: null,
                            error: null,
                        })
                    }
                    sx={{ textAlign: 'center', mt: 20, zIndex: 1000 }}
                >
                    Cancel
                </Link>
            </Flex>
        </Flex>
    );
};

const setNewPasswordApiCall = async (state, updateState, navigate) => {
    try {
        updateState({ ...state, loading: true, error: null });

        if (state.useOurApiToSetPassword) {
            await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/clients/magic-links/set-pwd`,
                method: 'POST',
                data: {
                    password: state.newPassword,
                },
            });

            return window.location.assign('/');
        } else {
            await Auth.completeNewPassword(state.cognitoUser, state.newPassword);
        }

        await Auth.signIn(state.username, state.newPassword);

        updateState({ ...state, loading: false, error: null });

        window.location.assign('/home');
    } catch (e) {
        let error =
            'We are currently unable to set your password, please ensure the password is at least 10 characters, has a lower and upper case character, a digit and a symbol';
        if (e.code === 'NotAuthorizedException') {
            error = 'Username and/or password are incorrect.';
        } else if (e.code === 'CodeMismatchException') {
            error = 'The code entered does not match the one we sent';
        }
        updateState({ ...state, loading: false, error });
    }
};

export const SetNewPassword = ({ currentState, updateState, navigate }) => (
    <Flex sx={{ justifyContent: 'center', flexDirection: 'column' }}>
        <Paragraph
            sx={{
                fontSize: 20,
                fontWeight: '500',
                mt: 30,
                alignSelf: ['center', 'center'],
            }}
        >
            Set a new password
        </Paragraph>
        <Flex
            sx={{
                mb: 0,
                mt: 4,
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Input
                sx={{
                    mb: 25,
                    alignSelf: 'center',
                    width: 400,
                    background: '#FFFFFF',
                }}
                type={currentState.passwordFieldType}
                id="password"
                data-testid="password"
                name="password"
                label=""
                placeholder="Enter a new password"
                onChange={(e) => updateState({ ...currentState, newPassword: e.target.value })}
                error={null}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setNewPasswordApiCall(currentState, updateState, navigate);
                    }
                }}
            />

            <Flex
                sx={{
                    justifyContent: 'flex-end',
                    alignSelf: 'flex-end',
                    marginTop: -60,
                    mr: '15px',
                    mb: 30,
                    width: 25,
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
            >
                <Image
                    onClick={() =>
                        updateState({
                            ...currentState,
                            passwordFieldType: currentState.passwordFieldType === 'password' ? 'text' : 'password',
                        })
                    }
                    sx={{ width: '25px' }}
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/registration/elipsis.png`}
                    alt="Click to show password"
                />
            </Flex>

            <Flex sx={{ alignSelf: 'center', mb: 20 }}>
                <PasswordValidation password={currentState.newPassword} />
            </Flex>

            <Button
                variant="primary-wide"
                sx={{ alignSelf: 'center' }}
                data-testid="submit"
                onClick={() => setNewPasswordApiCall(currentState, updateState, navigate)}
            >
                Submit
            </Button>
            <Link
                onClick={() =>
                    updateState({
                        ...currentState,
                        cognitoUser: null,
                        error: null,
                    })
                }
                sx={{ textAlign: 'center', mt: 20 }}
            >
                Cancel
            </Link>
        </Flex>
    </Flex>
);

const LogIn = ({ logIn, logOut }) => {
    const [currentState, updateState] = useState({
        username: process.env.REACT_APP_MODE === 'local' ? 'louis+1690211597938@accru.finance' : '',
        password: process.env.REACT_APP_MODE === 'local' ? 'Welcome12345!' : '',
        cognitoUser: null,
        code: '',
        passwordFieldType: 'password',
        failedAttempts: 0, // Track failed attempts
        showModal: false,
    });
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();
                navigate('/home');
            } catch (e) {}
        })();
    }, []);

    return (
        <Flex
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                minHeight: '100vh',
                backgroundSize: 'cover',
            }}
        >
            {currentState.loading && <Spinner />}

            <Waves />

            {currentState.showModal && (
                <Modal
                    message="A reset password link has been sent to your email."
                    onClose={() => updateState({ ...currentState, showModal: false })}
                />
            )}

            <Flex
                sx={{
                    width: ['100%', '600px'],
                    flexDirection: 'column',
                    p: '50px',
                    borderRadius: '10px',
                    position: 'relative',
                    zIndex: 10,
                }}
            >
                <Box sx={{ margin: '0px auto 30px' }}>
                    <Image
                        src={`https://cdn.accountingflow.io/af/logo-dark.png`}
                        width="320px"
                        height="auto"
                        alt="aqru ai logo"
                        data-testid="login-logo"
                    />
                </Box>

                {currentState.cognitoUser && currentState.challenge === 'new_password' && (
                    <SetNewPassword currentState={currentState} updateState={updateState} navigate={navigate} />
                )}

                {currentState.cognitoUser && ['mfa', 'email_mfa'].includes(currentState.challenge) && (
                    <EnterMfaCode currentState={currentState} updateState={updateState} navigate={navigate} />
                )}

                {!currentState?.cognitoUser && (
                    <>
                        <Card
                            sx={{
                                margin: '10px auto',
                                width: [300, '420px'],
                                padding: '25px',
                                borderRadius: '10px',
                            }}
                        >
                            <Heading as="h2" sx={{ mb: 3, fontSize: 24, textAlign: 'center' }}>
                                Welcome Back
                            </Heading>

                            <Input
                                type="email"
                                id="email"
                                data-testid="email"
                                name="email"
                                placeholder="Enter your email address"
                                onChange={(e) => updateState({ ...currentState, username: e.target.value })}
                                value={currentState.username}
                                sx={{ border: '1px solid lightgrey', mb: 3 }}
                            />
                            <Input
                                type={currentState.passwordFieldType}
                                id="password"
                                data-testid="password"
                                name="password"
                                placeholder="Password"
                                onChange={(e) => updateState({ ...currentState, password: e.target.value })}
                                value={currentState.password}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        logUserIn(currentState, updateState, navigate);
                                    }
                                }}
                                sx={{ border: '1px solid lightgrey' }}
                            />

                            <Flex
                                sx={{
                                    justifyContent: 'flex-end',
                                    marginTop: -42,
                                    mr: -35,
                                    mb: 30,
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                <Image
                                    onClick={() =>
                                        updateState({
                                            ...currentState,
                                            passwordFieldType:
                                                currentState.passwordFieldType === 'password' ? 'text' : 'password',
                                        })
                                    }
                                    sx={{ width: '25px' }}
                                    src={`${process.env.REACT_APP_CDN_BASE_URL}/registration/elipsis.png`}
                                    alt="Click to show password"
                                />
                            </Flex>

                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    mt: 20,
                                }}
                            >
                                <Button
                                    sx={{
                                        width: '100% !important',
                                        py: 3,
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        },
                                    }}
                                    variant="primary-wide"
                                    data-testid="login"
                                    onClick={() => logUserIn(currentState, updateState, navigate)}
                                >
                                    Log in
                                </Button>

                                <Button
                                    sx={{
                                        width: '100% !important',
                                        py: 3,
                                        mt: '10px',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        },
                                    }}
                                    variant="light-wide"
                                    data-testid="login"
                                    onClick={() => window.location.assign('/register')}
                                >
                                    Create free trial account
                                </Button>

                                <Flex sx={{ width: '100%', justifyContent: 'space-between', mt: 3 }}>
                                    <Link
                                        data-testid="forgot-password"
                                        onClick={() => navigate('/reset-password/request')}
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: 'primary',
                                            zIndex: 10000000,
                                            textDecoration: 'none',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                        }}
                                    >
                                        Forgot Password?
                                    </Link>
                                    <Link
                                        data-testid="help"
                                        onClick={() => window.location.assign('/help')}
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: 'primary',
                                            zIndex: 10000000,
                                            textDecoration: 'none',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                        }}
                                    >
                                        Need Help?
                                    </Link>
                                </Flex>
                            </Flex>
                        </Card>
                    </>
                )}

                {currentState.error && (
                    <Paragraph
                        data-testid="error-msg"
                        sx={{
                            color: 'red',
                            alignSelf: 'center',
                            textAlign: 'center',
                            mt: 3,
                            bg: 'rgba(255, 0, 0, 0.1)',
                            p: 2,
                            borderRadius: '4px',
                            width: [300, '380px'],
                        }}
                    >
                        {currentState.error}
                    </Paragraph>
                )}
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logIn: () => dispatch({ type: 'LOG_USER_IN' }),
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
