/* eslint-disable no-undef */
import axios from 'axios';
import { useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Close,
    Flex,
    Heading,
    Input,
    Label,
    Link,
    Paragraph,
    Select,
    Text,
} from 'theme-ui';

const RegistrationPage = () => {
    const [formData, setFormData] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        employees: '',
        email: '',
        password: '',
        termsAccepted: false,
    });

    const [errors, setErrors] = useState({});
    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState('');

    const employeeOptions = [
        { value: '1-5', label: '1-5 employees' },
        { value: '5-20', label: '5-20 employees' },
        { value: '20+', label: '20+ employees' },
    ];

    // Password validation rules
    const passwordRules = [
        { test: /.{10,}/, message: 'At least 10 characters' },
        { test: /[0-9]/, message: 'At least 1 number' },
        { test: /[a-z]/, message: 'At least 1 lowercase letter' },
        { test: /[A-Z]/, message: 'At least 1 uppercase letter' },
        { test: /[^A-Za-z0-9]/, message: 'At least 1 special character' },
    ];

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        // Company name validation
        if (!formData.companyName.trim()) {
            newErrors.companyName = 'Company name is required';
        }

        // First name validation
        if (!formData.firstName.trim()) {
            newErrors.firstName = 'First name is required';
        }

        // Last name validation
        if (!formData.lastName.trim()) {
            newErrors.lastName = 'Last name is required';
        }

        // Employee count validation
        if (!formData.employees) {
            newErrors.employees = 'Please select number of employees';
        }

        // Email validation
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }

        // Password validation
        if (!formData.password) {
            newErrors.password = 'Password is required';
        } else {
            const failedRules = passwordRules.filter((rule) => !rule.test.test(formData.password));
            if (failedRules.length > 0) {
                newErrors.password = failedRules.map((rule) => rule.message).join(', ');
            }
        }

        // Terms and conditions validation
        if (!formData.termsAccepted) {
            newErrors.termsAccepted = 'You must accept the terms and conditions to create an account';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitError('');
        setSubmitSuccess('');

        if (validateForm()) {
            setIsSubmitting(true);
            try {
                // Remove termsAccepted from data sent to API as it's not needed on the backend
                const { termsAccepted, ...dataToSubmit } = formData;

                const response = await axios.post(`${process.env.REACT_APP_AQRU_AI_API}/register`, dataToSubmit);

                setSubmitSuccess('Registration successful! Please check your email to confirm your account.');
                setFormData({
                    companyName: '',
                    firstName: '',
                    lastName: '',
                    employees: '',
                    email: '',
                    password: '',
                    termsAccepted: false,
                });
            } catch (error) {
                console.log(error);

                setSubmitError(error.response?.data?.message || 'Registration failed. Please try again later.');
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    return (
        <Box
            sx={{
                maxWidth: '600px',
                mx: 'auto',
                px: 3,
                py: 4,
                bg: 'white',
                borderRadius: 4,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Heading as="h1" sx={{ mb: 2 }}>
                Create Your Account
            </Heading>
            <Text sx={{ mb: 4, color: 'gray' }}>Start your free trial today. No credit card required.</Text>

            {submitError && (
                <Alert variant="error" sx={{ mt: '20px', mb: 3 }}>
                    {submitError}
                    <Close sx={{ cursor: 'pointer' }} onClick={() => setSubmitError('')} />
                </Alert>
            )}

            {submitSuccess && (
                <Alert variant="success" sx={{ mt: '20px', mb: 3 }}>
                    {submitSuccess}
                    <Close sx={{ cursor: 'pointer' }} onClick={() => setSubmitSuccess('')} />
                </Alert>
            )}

            <Label htmlFor="companyName" sx={{ mt: 3 }}>
                Company Name
            </Label>
            <Input id="companyName" name="companyName" value={formData.companyName} onChange={handleChange} mb={2} />
            {errors.companyName && <Text sx={{ color: 'red', fontSize: 1, mb: 2 }}>{errors.companyName}</Text>}

            <Flex sx={{ flexDirection: ['column', 'row'], gap: [0, 3] }}>
                <Box sx={{ flex: 1 }}>
                    <Label htmlFor="firstName" sx={{ mt: 2 }}>
                        First Name
                    </Label>
                    <Input id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} mb={2} />
                    {errors.firstName && <Text sx={{ color: 'red', fontSize: 1, mb: 2 }}>{errors.firstName}</Text>}
                </Box>

                <Box sx={{ flex: 1 }}>
                    <Label htmlFor="lastName" sx={{ mt: 2 }}>
                        Last Name
                    </Label>
                    <Input id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} mb={2} />
                    {errors.lastName && <Text sx={{ color: 'red', fontSize: 1, mb: 2 }}>{errors.lastName}</Text>}
                </Box>
            </Flex>

            <Label htmlFor="employees" sx={{ mt: 2 }}>
                Number of Employees
            </Label>
            <Select id="employees" name="employees" value={formData.employees} onChange={handleChange} mb={2}>
                <option value="" disabled>
                    Select...
                </option>
                {employeeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
            {errors.employees && <Text sx={{ color: 'red', fontSize: 1, mb: 2 }}>{errors.employees}</Text>}

            <Label htmlFor="email" sx={{ mt: 2 }}>
                Email Address
            </Label>
            <Input id="email" name="email" type="email" value={formData.email} onChange={handleChange} mb={2} />
            {errors.email && <Text sx={{ color: 'red', fontSize: 1, mb: 2 }}>{errors.email}</Text>}

            <Label htmlFor="password" sx={{ mt: 2 }}>
                Password
            </Label>
            <Input
                id="password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                mb={2}
            />
            {errors.password ? (
                <Text sx={{ color: 'red', fontSize: 1, mb: 2 }}>{errors.password}</Text>
            ) : (
                <Text sx={{ fontSize: 1, color: 'gray', mb: 2 }}>
                    Password must contain at least 10 characters, including 1 number, 1 lowercase letter, 1 uppercase
                    letter, and 1 special character.
                </Text>
            )}

            <Flex
                sx={{ alignItems: 'flex-start', mt: 4 }}
                onClick={() => {
                    setFormData((prevData) => ({
                        ...prevData,
                        termsAccepted: prevData.termsAccepted ? false : true,
                    }));
                }}
            >
                <Checkbox
                    id="termsAccepted"
                    name="termsAccepted"
                    checked={formData.termsAccepted}
                    sx={{ mt: '5px', cursor: 'pointer' }}
                />
                <Paragraph sx={{ ml: 2, fontSize: 1, color: 'gray', fontWeight: 'normal' }}>
                    I certify that I am 18 years of age or older, and agree to the{' '}
                    <Link
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        href={'https://cdn.accountingflow.io/af/user_agreement.pdf'}
                        target="_blank"
                    >
                        User Agreement{' '}
                    </Link>{' '}
                    and{' '}
                    <Link
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        href={'https://cdn.accountingflow.io/af/privacy_policy.pdf'}
                        target="_blank"
                    >
                        {' '}
                        Privacy Policy.
                    </Link>
                </Paragraph>
            </Flex>
            {errors.termsAccepted && (
                <Text sx={{ color: 'red', fontSize: 1, mb: 2, ml: 4 }}>{errors.termsAccepted}</Text>
            )}

            <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                sx={{
                    mt: 4,
                    width: '100%',
                    cursor: isSubmitting ? 'not-allowed' : 'pointer',
                    opacity: isSubmitting ? 0.7 : 1,
                }}
            >
                {isSubmitting ? 'Creating Account...' : 'Create Free Account'}
            </Button>
            <Button
                variant="light"
                onClick={() => {
                    window.location.assign('/');
                }}
                type="submit"
                disabled={isSubmitting}
                sx={{
                    mt: 3,
                    width: '100%',
                    cursor: isSubmitting ? 'not-allowed' : 'pointer',
                    opacity: isSubmitting ? 0.7 : 1,
                }}
            >
                Cancel
            </Button>
        </Box>
    );
};

export default RegistrationPage;
