import React, { useState } from 'react';
import { Box, Heading, Text, Button, Flex, Card, Container, Grid, Paragraph } from 'theme-ui';
import { Users, CheckSquare, Calendar, Link, FileText } from 'react-feather';

const WelcomeEducationScreen = ({ state }) => {
    const completedTasks = state?.data?.education || {
        createClients: false,
        createTasks: false,
        scheduleTasks: false,
        connectQuickbooks: false,
        connectHMRC: false,
    };

    const features = [
        {
            id: 'createClients',
            title: 'Create Clients',
            description: 'Add and manage your client profiles with contact details and important information.',
            icon: <Users size={24} />,
            buttonText: 'Add Clients',
            url: '/clients',
        },
        {
            id: 'createTasks',
            title: 'Create Tasks',
            description:
                'Create custom tasks for your clients with due dates, priority levels, and detailed descriptions.',
            icon: <CheckSquare size={24} />,
            buttonText: 'Create Tasks',
            url: '/tasks',
        },
        {
            id: 'scheduleTasks',
            title: 'Schedule Tasks',
            description: 'Arrange your tasks in a calendar view to optimize your workflow and never miss a deadline.',
            icon: <Calendar size={24} />,
            buttonText: 'Schedule',
            url: '/settings?tab=workflow',
        },
        {
            id: 'connectQuickbooks',
            title: 'Connect to QuickBooks/Xero',
            description: 'Integrate with accounting software to streamline financial data management.',
            icon: <Link size={24} />,
            buttonText: 'Connect',
            url: '/settings?tab=integrations',
        },
        {
            id: 'connectHMRC',
            title: 'Connect to HMRC',
            description: 'Link directly to HMRC for simplified tax compliance and submissions.',
            icon: <FileText size={24} />,
            buttonText: 'Connect',
            url: '/clients',
        },
    ];

    return (
        <Container sx={{ maxWidth: '1200px', px: 3, py: 4 }}>
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Heading as="h1" sx={{ fontSize: 5, mb: 3 }}>
                    Welcome to Your Dashboard
                </Heading>
                <Text sx={{ fontSize: 2, color: 'gray.700', maxWidth: '700px', mx: 'auto' }}>
                    Get started by setting up these key features to make the most of your experience.
                </Text>
            </Box>

            <Grid gap={4} columns={[1, null, 2, 3]} sx={{ mb: 5 }}>
                {features.map((feature) => (
                    <Card
                        key={feature.id}
                        sx={{
                            p: 3,
                            borderRadius: '8px',
                            boxShadow: 'sm',
                            position: 'relative',
                            transition: 'all 0.2s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: 'md',
                            },
                            border: completedTasks[feature.id] ? '1px solid #4CAF50' : '1px solid #e0e0e0',
                            bg: completedTasks[feature.id] ? 'rgba(76, 175, 80, 0.05)' : 'white',
                        }}
                    >
                        {completedTasks[feature.id] && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '12px',
                                    right: '12px',
                                    color: 'green',
                                    bg: 'rgba(76, 175, 80, 0.1)',
                                    borderRadius: '50%',
                                    p: '2px',
                                }}
                            >
                                <CheckSquare size={20} />
                            </Box>
                        )}

                        <Flex sx={{ mb: 3, color: 'primary' }}>{feature.icon}</Flex>

                        <Heading as="h3" sx={{ fontSize: '17px', mb: 2 }}>
                            {feature.title}
                        </Heading>

                        <Paragraph sx={{ color: 'gray.700', mb: 3, fontSize: 1 }}>{feature.description}</Paragraph>

                        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', mt: '10px' }}>
                            <a href={feature.url} target="_blank" rel="noopener noreferrer">
                                <Button
                                    variant="primary"
                                    sx={{
                                        py: 2,
                                        px: 3,
                                        borderRadius: '4px',
                                        fontSize: 1,
                                    }}
                                >
                                    {feature.buttonText}
                                </Button>
                            </a>

                            {completedTasks[feature.id] ? (
                                <Button
                                    variant="text"
                                    sx={{
                                        bg: 'transparent',
                                        color: completedTasks[feature.id] ? 'green' : 'gray.600',
                                        p: 2,
                                        cursor: 'pointer',
                                        '&:hover': {
                                            bg: 'rgba(0,0,0,0.05)',
                                        },
                                    }}
                                >
                                    {'Completed'}
                                </Button>
                            ) : null}
                        </Flex>
                    </Card>
                ))}
            </Grid>

            {/* <Box sx={{ textAlign: 'center' }}>
                <Button
                    variant="secondary"
                    sx={{
                        px: 4,
                        py: 2,
                        borderRadius: '4px',
                        bg: 'secondary',
                        fontSize: 2,
                    }}
                >
                    Skip Setup
                </Button>
            </Box> */}
        </Container>
    );
};

export default WelcomeEducationScreen;
