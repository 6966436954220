import { useEffect } from 'react';

export const useUnsavedChangesWarning = (hasUnsavedChanges) => {
    useEffect(() => {
        // Handler function for the beforeunload event
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                // Standard way to show a confirmation dialog
                event.preventDefault();

                // This message might not be displayed in some modern browsers
                // as they show their own generic message instead
                const message = 'You have unsaved changes. Are you sure you want to leave?';
                event.returnValue = message; // For older browsers
                return message; // For modern browsers
            }
        };

        // Handler for history navigation (like back button)
        const handlePopState = (event) => {
            if (hasUnsavedChanges) {
                // Save the current URL to restore it after
                const currentUrl = window.location.href;

                // Push the current state again to prevent the navigation
                window.history.pushState(null, '', currentUrl);

                // Show a custom confirmation dialog
                const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');

                if (confirmLeave) {
                    // If user confirms, allow navigation by going back twice
                    // (once to neutralize our pushState, and once to actually go back)
                    hasUnsavedChanges = false; // Prevent another warning
                    window.history.back();
                    window.history.back();
                }
            }
        };

        // Add event listeners
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        // Push an entry into the history stack when the component mounts
        // This ensures popstate will trigger even on the first back navigation
        window.history.pushState(null, '', window.location.href);

        // Clean up
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [hasUnsavedChanges]);
};
