import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Flex, Heading, Link, Text, Button, Box } from 'theme-ui';
import theme from '../theme';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown, ChevronRight } from 'react-feather'; // Assuming you have react-feather or similar

// Reorganized help content with categories and submenu items
const helpContent = {
    'getting-started': {
        title: 'Getting Started',
        isCategory: true,
        subItems: {
            'login-accountant': {
                title: 'Login',
                content: [
                    'To login:',
                    '1. Enter your email address and password',
                    '3. Click the "Login" button',
                    '4. If you have two-factor authentication enabled, enter the code from your authenticator app',
                    '5. You will be redirected to the dashboard upon successful login',
                    'NB: If you have not setup two-factor authentication, please download an App like Google Authenticator to your phone and follow the instructions to set it up',
                ],
            },
            'reset-password': {
                title: 'Forgot Password',
                content: [
                    'If you need to reset your password:',
                    '1. Click "Forgot Password" on the login page',
                    '2. Enter the email address associated with your account',
                    '3. Click "Send Reset Link"',
                    '4. Check your email for a password reset link',
                    '5. Click the link and follow the instructions to create a new password',
                    '6. Use your new password to login',
                    '',
                    'Important Password Reset Link Notes:',
                    '• Password reset links expire after 24 hours for security reasons',
                    '• Links can only be used once',
                    "• If the link expires, you'll need to restart the reset process",
                    '• Password must meet complexity requirements (minimum 10 characters, including uppercase, lowercase, numbers, and special characters)',
                    "• If you don't receive the email, check your spam folder or contact support",
                ],
            },
        },
    },
    'client-management': {
        title: 'Client Management',
        isCategory: true,
        subItems: {
            'creating-client': {
                title: 'Creating a Client',
                content: [
                    'To create a new client account:',
                    '1. Login to the dashboard',
                    '2. Navigate to "Clients" in the sidebar menu',
                    '3. Click the "+ New Client" button',
                    '4. Fill in the required client information (company name etc.)',
                    '5. Select which services are offered to the client',
                    '6. Click "Create Client"',
                    '7. The client will appear in your client list once created',
                ],
            },
            'filling-client-data': {
                title: 'Filling Client Data',
                content: [
                    'Once a client has been created:',
                    '1. Click the client name in the client list to open their profile',
                    '2. Complete required fields such as company number and client reference number',
                    '3. To add custom fields (e.g. internal tags or reference codes):',
                    '   a. Go to "Settings" in the sidebar menu',
                    '   b. Select the "Clients" tab',
                    '   c. Click "Add Custom Field" and define your new field',
                    '   d. These fields will now appear within client records for editing',
                    '4. Click "Save" to update the client profile with any changes',
                ],
            },
            'managing-contacts': {
                title: 'Managing Contacts',
                content: [
                    'To manage contacts within a client:',
                    '1. Go to the "Contacts" tab inside the client\'s profile',
                    '2. Click "Add Contact" and fill in the relevant contact information',
                    '3. Click "Save Contact"',
                    '4. To invite a contact to the client portal:',
                    '   a. Click "Invite to Portal" next to their name',
                    '   b. Confirm the invitation to send an email to the contact',
                    '5. Once invited, the contact will be able to log in to the client portal',
                    '6. You can assign tasks (e.g. forms, signatures) to portal users at any time',
                ],
            },
            questionnaires: {
                title: 'Questionnaires',
                content: [
                    'To create and manage client questionnaires:',
                    '1. Navigate to "Questionnaires" in the sidebar menu',
                    '2. Click "+ New Questionnaire" to start from scratch or use a pre-built template',
                    '3. Add questions using various formats (e.g. text input, dropdown, date, file upload)',
                    '4. You can assign fields to auto-fill client attributes when responses are submitted',
                    '5. Once complete, click "Save Questionnaire"',
                    '6. To assign a questionnaire to a client:',
                    '   a. Go to the client’s profile',
                    '   b. Open the "Tasks" tab',
                    '   c. Click "Assign Task", select "Questionnaire", and choose the form you want to send',
                    '   d. The assigned contact will receive an email with a link to complete the form',
                    '7. Submitted responses are saved and can be viewed under the client’s profile',
                    '8. Any fields linked to client attributes will automatically update when the questionnaire is submitted',
                ],
            },
            'hmrc-integration': {
                title: 'HMRC Integration',
                content: [
                    'To integrate AccountingFlow with HMRC for VAT data:',
                    '1. Open the client record and set the VAT number',
                    '2. Navigate to the "HMRC" tab within the client record',
                    '3. If you haven’t connected to HMRC before, click the "Connect" button',
                    '4. You will be redirected to log in with your HMRC agent credentials',
                    '5. Authorise AccountingFlow to access VAT data on your behalf',
                    '6. Once connected, VAT obligations and filing information will be pulled into the client profile',
                    '7. You can schedule tasks based on VAT period deadlines and set reminders',
                    '8. Any changes in HMRC VAT status will automatically update in the client record',
                ],
            },
        },
    },
};

// Flattened object for accessing content directly
const flattenedContent = Object.keys(helpContent).reduce((acc, categoryKey) => {
    const category = helpContent[categoryKey];
    if (category.isCategory && category.subItems) {
        Object.keys(category.subItems).forEach((subItemKey) => {
            acc[subItemKey] = category.subItems[subItemKey];
        });
    }
    return acc;
}, {});

const Help = ({ userData, accountSummary }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authChecked, setAuthChecked] = useState(false);
    const [expandedCategories, setExpandedCategories] = useState({});

    // Check authentication status with Amplify
    useEffect(() => {
        checkAuthStatus();
    }, []);

    const checkAuthStatus = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsAuthenticated(true);
        } catch (error) {
            setIsAuthenticated(false);
        } finally {
            setAuthChecked(true);
        }
    };

    // Parse the hash from the URL or default to login-accountant
    const getActiveSection = () => {
        const hash = location.hash.slice(1);
        return flattenedContent[hash] ? hash : 'login-accountant';
    };

    const [activeSection, setActiveSection] = useState(getActiveSection());

    // Initialize expanded categories based on active section
    useEffect(() => {
        // Find which category contains the active section
        const initialExpandedCategories = {};
        Object.keys(helpContent).forEach((categoryKey) => {
            const category = helpContent[categoryKey];
            if (category.isCategory && category.subItems && Object.keys(category.subItems).includes(activeSection)) {
                initialExpandedCategories[categoryKey] = true;
            }
        });
        setExpandedCategories(initialExpandedCategories);
    }, []);

    // Update state when URL hash changes
    useEffect(() => {
        const handleHashChange = () => {
            setActiveSection(getActiveSection());
        };

        // Set initial section based on hash
        handleHashChange();

        // Listen for hash changes
        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [location.hash]);

    // Handle click on a knowledge base item
    const handleSectionClick = (sectionKey) => {
        setActiveSection(sectionKey);
        navigate(`#${sectionKey}`);
    };

    // Handle click on a category (expand/collapse)
    const toggleCategory = (categoryKey) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [categoryKey]: !prev[categoryKey],
        }));
    };

    // Effect to update the URL hash when activeSection changes
    useEffect(() => {
        if (location.hash !== `#${activeSection}`) {
            navigate(`#${activeSection}`);
        }
    }, [activeSection, location.hash, navigate]);

    // Handle navigation based on login status
    const handleNavigation = () => {
        window.location.assign(isAuthenticated ? '/home' : '/');
    };

    return (
        <>
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    width: '100vw',
                    flexDirection: 'column',
                    position: 'relative',
                    backgroundSize: 'cover',
                }}
                data-testid="help-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: '100vw',
                        flexDirection: 'column',
                    }}
                >
                    {/* Knowledge Base Navigation Sidebar */}
                    <Flex
                        sx={{
                            position: 'fixed',
                            top: '80px',
                            left: 20,
                            width: '260px',
                            flexDirection: 'column',
                            backgroundColor: 'white',
                            padding: '20px',
                            border: '1px solid #E0E0E0',
                            borderRadius: 20,
                            zIndex: 10,
                            mt: 23,
                            maxHeight: 'calc(100vh - 120px)',
                            overflowY: 'auto',
                        }}
                    >
                        <Heading as="h3" sx={{ mb: '20px', fontSize: 3 }}>
                            Knowledge Base
                        </Heading>

                        {Object.keys(helpContent).map((categoryKey) => {
                            const category = helpContent[categoryKey];
                            if (category.isCategory) {
                                const isExpanded = expandedCategories[categoryKey];
                                return (
                                    <Box key={categoryKey} sx={{ mb: 3 }}>
                                        <Flex
                                            onClick={() => toggleCategory(categoryKey)}
                                            sx={{
                                                alignItems: 'center',
                                                p: '10px',
                                                borderRadius: '8px',
                                                cursor: 'pointer',
                                                backgroundColor: 'muted',
                                                mb: 2,
                                                '&:hover': {
                                                    backgroundColor: 'lightgray',
                                                },
                                            }}
                                        >
                                            {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                                            <Text sx={{ ml: 2, fontWeight: 'bold' }}>{category.title}</Text>
                                        </Flex>
                                        {isExpanded && (
                                            <Box sx={{ ml: 2 }}>
                                                {Object.keys(category.subItems).map((subItemKey) => (
                                                    <Link
                                                        key={subItemKey}
                                                        onClick={() => handleSectionClick(subItemKey)}
                                                        sx={{
                                                            display: 'block',
                                                            p: '10px',
                                                            pl: '25px',
                                                            mb: '5px',
                                                            borderRadius: '8px',
                                                            cursor: 'pointer',
                                                            textDecoration: 'none',
                                                            color: activeSection === subItemKey ? 'white' : 'text',
                                                            backgroundColor:
                                                                activeSection === subItemKey
                                                                    ? 'primary'
                                                                    : 'transparent',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    activeSection === subItemKey ? 'primary' : 'muted',
                                                            },
                                                            transition: 'all 0.2s ease',
                                                        }}
                                                        href={`#${subItemKey}`}
                                                    >
                                                        {category.subItems[subItemKey].title}
                                                    </Link>
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            }
                            return null;
                        })}
                    </Flex>

                    {/* Content Area */}
                    <Flex
                        sx={{
                            minHeight: 'calc(100vh)',
                            borderTopLeftRadius: '25px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            mt: '5px',
                            border: `1px solid ${theme.colors.whiteBlockBorder}`,
                            borderRadius: 20,
                            padding: '30px 30px',
                            minWidth: 370,
                            backgroundColor: 'white',
                            ml: 290, // Increased to account for the wider sidebar
                            mr: 20,
                        }}
                    >
                        <Flex sx={{ alignItems: 'center', mb: 4 }}>
                            <Heading as="h2" sx={{}}>
                                {flattenedContent[activeSection]?.title || ''}
                            </Heading>
                            <Button
                                onClick={handleNavigation}
                                sx={{
                                    width: 120,
                                    height: 40,
                                    px: 3,
                                    py: 2,
                                    '&:hover': {
                                        backgroundColor: 'primaryDark',
                                    },
                                    ml: 20,
                                }}
                                variant="light"
                            >
                                {isAuthenticated ? 'Dashboard' : 'Login'}
                            </Button>
                        </Flex>

                        <Flex sx={{ maxWidth: '800px', flexDirection: 'column' }}>
                            {flattenedContent[activeSection]?.content.map((paragraph, index) => (
                                <Text key={index} sx={{ mb: 3, lineHeight: 1.6 }}>
                                    {paragraph}
                                </Text>
                            ))}
                        </Flex>

                        {/* Bookmark instructions */}
                        <Flex
                            sx={{
                                mt: 5,
                                pt: 4,
                                borderTop: '1px solid #E0E0E0',
                                width: '100%',
                                flexDirection: 'column',
                            }}
                        >
                            <Text sx={{ fontSize: 1, color: 'gray' }}>
                                Tip: You can bookmark or share this page by copying the URL. Each section has its own
                                unique link.
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch({ type: 'LOGOUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
