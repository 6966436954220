/* eslint-disable no-undef */
/** @jsxImportSource theme-ui */
import axios from 'axios';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Checkbox, Flex, Input, Paragraph, Textarea } from 'theme-ui';
import Divider from '../divider';
import Overlay from '../overlay/overlay';
import { generatePdf } from './generate-invoice';
import { loadInvoices } from './invoices';
import uniqueByKey from '../../utils/unique-by-key';
import InvoiceScheduler, { validateOptions } from './invoice-scheduler';
import { useUnsavedChangesWarning } from '../../hooks/saving';
import { useNavigate } from 'react-router-dom';

export const vatOptions = [
    { value: '20', label: '20% Standard Rated', multiplier: 1.2 },
    { value: '0', label: '0% Zero Rated', multiplier: 1 },
    { value: '0_exempt', label: '0% Exempt', multiplier: 1 },
    { value: 'no-vat', label: 'No VAT', multiplier: 1 },
];

export const replaceTokens = (str, state) => {
    if (state.clientInView.name) {
        str = str?.replace(/{clientName}/g, state.clientInView.name);
    }
    if (state.invoice?.model?.bank_details?.payable_by) {
        str = str?.replace(/{dueDate}/g, state.invoice?.model?.bank_details?.payable_by);
    }
    if (state.invoice?.invoice_number) {
        str = str?.replace(/{invoiceNumber}/g, state.invoice?.invoice_number);
    }
    if (state.invoice?.model?.totals?.total) {
        str = str?.replace(/{totalDue}/g, state.invoice?.model.totals.total);
    }
    return str;
};

const EmailDialog = ({ state, updateState }) => {
    const fullOptions = uniqueByKey(
        [
            ...(state.clientInView.client_data?.key_contacts?.map((x) => ({
                value: x.email_address,
                label: `${x.first_name} ${x.last_name} (${x.email_address})`,
            })) || []),
            ...(state.clientInView.relations?.map((x) => ({
                value: x.email_address,
                label: `${x.first_name} (${x.email_address})`,
            })) || []),
        ],
        'value'
    );

    useEffect(() => {
        if (state.showEmailDialog) {
            // Prevent background scrolling
            document.body.style.overflow = 'hidden';
            document.body.classList.add('no-scroll'); // Fallback for additional enforcement
        } else {
            // Restore background scrolling
            document.body.style.overflow = '';
            document.body.classList.remove('no-scroll');
        }

        // Cleanup when modal closes
        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('no-scroll');
        };
    }, [state.showEmailDialog]);

    const [localState, updateLocalState] = useState({
        toOptions: [...fullOptions],
        ccOptions: [...fullOptions],
        bcc: [],
        to: [],
        subject: replaceTokens(
            state.clientInView.organisation?.default_visualisation?.invoiceOptions?.defaultSubjectLine,
            state
        ),
        body: replaceTokens(
            state.clientInView.organisation?.default_visualisation?.invoiceOptions?.defaultInvoiceBody,
            state
        ),
    });
    return (
        <Flex sx={{ flexDirection: 'column', m: 15 }}>
            <Paragraph sx={{ fontSize: 18 }}>Email Invoice</Paragraph>
            <Divider width="550px" />
            <Flex>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Flex sx={{ alignItems: 'center' }}>
                        <Paragraph sx={{ mr: 10, mt: -25, width: 30 }}>To</Paragraph>
                        <CreatableSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '480px',
                                    minHeight: '40px',
                                    borderRadius: 10,
                                    marginBottom: '30px',
                                    border: localState.emailError ? '1px solid red' : '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={async (c) => {
                                updateLocalState({
                                    ...localState,
                                    to: c,
                                    emailError: null,
                                });
                            }}
                            isMulti
                            placeholder={'Select the contacts to be emailed'}
                            value={localState.to}
                            options={localState.toOptions}
                        />
                    </Flex>
                    <Flex sx={{ alignItems: 'center' }}>
                        <Paragraph sx={{ mr: 10, mt: -25, width: 30 }}>Cc</Paragraph>
                        <CreatableSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '480px',
                                    minHeight: '40px',
                                    borderRadius: 10,
                                    marginBottom: '30px',
                                    border: localState.emailError ? '1px solid red' : '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={async (c) => {
                                updateLocalState({
                                    ...localState,
                                    cc: c,
                                    emailError: null,
                                });
                            }}
                            isMulti
                            placeholder={"Select the contacts to be cc'd"}
                            value={localState.cc}
                            options={[...localState.toOptions, ...localState.ccOptions]}
                        />{' '}
                    </Flex>
                    <Flex sx={{ alignItems: 'center' }}>
                        <Paragraph sx={{ mr: 10, mt: -25, width: 30 }}>Bcc</Paragraph>
                        <CreatableSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '480px',
                                    minHeight: '40px',
                                    borderRadius: 10,
                                    marginBottom: '30px',
                                    border: localState.emailError ? '1px solid red' : '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={async (c) => {
                                updateLocalState({
                                    ...localState,
                                    bcc: c,
                                    emailError: null,
                                });
                            }}
                            isMulti
                            placeholder={"Select the contacts to be bcc'd"}
                            value={localState.bcc}
                            options={localState.ccOptions}
                        />{' '}
                    </Flex>
                    <Paragraph sx={{ mb: 10 }}>Subject</Paragraph>
                    <Input
                        onChange={(e) => updateLocalState({ ...localState, subject: e.target.value })}
                        value={localState.subject}
                        sx={{ mb: 10 }}
                    />
                    <Paragraph sx={{ mb: 10 }}>Body</Paragraph>
                    <ReactQuill
                        theme="snow"
                        value={localState.body}
                        style={{ height: 300, width: 560 }}
                        onChange={(val) =>
                            updateLocalState({
                                ...localState,
                                body: val,
                            })
                        }
                    />
                    <Flex sx={{ position: 'absolute', bottom: 20 }}>
                        <Button
                            onClick={() => updateState({ ...state, showEmailDialog: false })}
                            variant="light"
                            sx={{ mr: 10, width: 120, height: 40, fontSize: 14 }}
                        >
                            <i style={{ marginRight: '7px' }} className="fa fa-times-circle" />
                            Cancel
                        </Button>
                        <Button
                            sx={{ width: 120, height: 40, fontSize: 14 }}
                            onClick={async () => {
                                try {
                                    if (!localState.to?.length) {
                                        return updateLocalState({
                                            ...localState,
                                            emailError: true,
                                        });
                                    }
                                    updateLocalState({
                                        ...localState,
                                        error: null,
                                    });
                                    updateState({ ...state, loading: true });

                                    await axios({
                                        url: `${process.env.REACT_APP_AQRU_AI_API}/invoices/${state.invoice.uuid}/email`,
                                        method: 'POST',
                                        data: {
                                            to: localState.to?.map((x) => x.value?.trim().toLowerCase()),
                                            cc: localState.cc?.map((x) => x.value?.trim().toLowerCase()),
                                            bcc: localState.bcc?.map((x) => x.value?.trim().toLowerCase()),
                                            subject: localState.subject,
                                            body: localState.body,
                                        },
                                    });
                                    updateState({
                                        ...state,
                                        loading: false,
                                        showEmailDialog: false,
                                        invoice: { ...state.invoice, last_sent_at: moment() },
                                    });
                                } catch (e) {
                                    updateState({ ...state, loading: false });
                                    toast('We are unable to send your email at this time, please try again later', {
                                        type: 'error',
                                    });
                                }
                            }}
                        >
                            <i style={{ marginRight: '7px' }} className="fa fa-paper-plane" />
                            Send
                        </Button>
                    </Flex>
                </Flex>
                <iframe
                    src={state.invoice.pdfUrl}
                    style={{
                        border: 'none',
                        height: 835,
                        width: 550,
                        marginLeft: 30,
                        marginTop: '-65px',
                    }}
                />
            </Flex>
        </Flex>
    );
};

const pdfColumnsDefaults = [
    { value: 'date', label: 'Date' },
    { value: 'service', label: 'Service' },
    { value: 'description', label: 'Description' },
    { value: 'time_spent', label: 'Time Spent' },
    { value: 'rate', label: 'Rate' },
    { value: 'vat', label: 'VAT' },
    { value: 'total', label: 'Total' },
];

const InvoiceOptions = ({ state, updateState, lineItems }) => {
    const [localState, updateLocalState] = useState({
        pdfColumns: state.invoice?.model?.pdfColumns || pdfColumnsDefaults,
        invoiceNumber: '',
        bankDetails: {
            payableBy: state.invoice?.model?.payableBy
                ? state.invoice?.model?.payableBy
                : state?.organisation?.default_visualisation?.invoiceOptions?.dueDateDays
                ? moment()
                      .add(state?.organisation?.default_visualisation?.invoiceOptions?.dueDateDays, 'days')
                      .format('YYYY-MM-DD')
                : null,
        },
        customFields: [...(state?.organisation?.default_visualisation?.invoiceOptions?.customFields || [])],
        autoGenerateInvoiceNumber: true,
        invoiceDate: state.invoice?.model?.invoiceDate || moment().format('YYYY-MM-DD'),
        comment: state.invoice?.model?.comment || '',

        is_invoice_scheduled: state.invoice?.model?.recurring_props?.is_invoice_scheduled,
        invoice_days_in_advance: state.invoice?.model?.recurring_props?.invoice_days_in_advance,
        invoice_interval: state.invoice?.model?.recurring_props?.invoice_interval,
        invoice_day: state.invoice?.model?.recurring_props?.invoice_day,
        invoice_day_of_month: state.invoice?.model?.recurring_props?.invoice_day_of_month,
        invoice_interval_every: state.invoice?.model?.recurring_props?.invoice_interval_every,
        invoice_weekly_on: state.invoice?.model?.recurring_props?.invoice_weekly_on,
        invoice_monthly_on: state.invoice?.model?.recurring_props?.invoice_monthly_on,
        invoice_month_of_year: state.invoice?.model?.recurring_props?.invoice_month_of_year,
        invoice_recurring_start_date: state.invoice?.model?.recurring_props?.invoice_recurring_start_date,
        invoice_recurring_end: state.invoice?.model?.recurring_props?.invoice_recurring_end,
        invoice_recurring_end_date: state.invoice?.model?.recurring_props?.invoice_recurring_end_date,
        auto_send_invoice_emails: state.invoice?.model?.recurring_props?.auto_send_invoice_emails,
    });

    const isFirstRender = useRef(lineItems?.length > 0);

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useUnsavedChangesWarning(hasUnsavedChanges);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        setHasUnsavedChanges(true);
    }, [localState, lineItems]);

    const handleBankDetailsChange = (e) => {
        updateLocalState({
            ...localState,
            bankDetails: { ...localState.bankDetails, [e.target.name]: e.target.value },
        });
    };

    const navigate = useNavigate();

    return (
        <Box sx={{ mt: 20 }}>
            {state.showEmailDialog && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={1200}
                    maxHeight={900}
                    embeddedComponent={<EmailDialog lineItems={lineItems} state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showEmailDialog: false })}
                />
            )}

            {localState.customFields?.map((field, fieldIdx) => (
                <Flex key={`cf_${fieldIdx}`} sx={{ flexDirection: 'column', mb: 3, width: 500 }}>
                    <Paragraph sx={{ mb: 10 }}>{field.label}</Paragraph>
                    {field.type.value === 'text_area' ? (
                        <Textarea
                            sx={{
                                height: 80,
                                backgroundColor: localState.customFields?.[fieldIdx]?.editable ? 'white' : '#EFEFEF',
                            }}
                            disabled={localState.customFields?.[fieldIdx]?.editable === false}
                            value={field?.editable ? field?.value || '' : replaceTokens(field?.defaultValue, state)}
                            onChange={(e) => {
                                if (!field.editable) return;

                                updateLocalState({
                                    ...localState,
                                    customFields: localState.customFields?.map((customField, ffIdx) => {
                                        if (ffIdx === fieldIdx) {
                                            customField.value = e.target.value;
                                        }
                                        return customField;
                                    }),
                                });
                            }}
                        />
                    ) : null}
                    {field.type.value !== 'text_area' ? (
                        <Input
                            sx={{
                                height: 50,
                                backgroundColor: localState.customFields?.[fieldIdx]?.editable ? 'white' : '#EFEFEF',
                            }}
                            type={field?.editable ? field.type?.value : 'text'}
                            disabled={localState.customFields?.[fieldIdx]?.editable === false}
                            value={field?.editable ? field?.value || '' : replaceTokens(field?.defaultValue, state)}
                            onChange={(e) =>
                                updateLocalState({
                                    ...localState,
                                    customFields: localState.customFields?.map((customField, ffIdx) => {
                                        if (ffIdx === fieldIdx) {
                                            customField.value = e.target.value;
                                        }
                                        return customField;
                                    }),
                                })
                            }
                        />
                    ) : null}
                </Flex>
            ))}

            <Box mb={3}>
                <Paragraph sx={{ mb: 10 }} htmlFor="invoice-date">
                    Invoice Date
                </Paragraph>
                <Input
                    sx={{ height: 50 }}
                    id="invoice-date"
                    type="date"
                    value={localState.invoiceDate}
                    onChange={(e) => updateLocalState({ ...localState, invoiceDate: e.target.value })}
                />
            </Box>

            {!localState.autoGenerateInvoiceNumber && (
                <Box mb={4}>
                    <Paragraph sx={{ mb: 10 }} htmlFor="invoice-number">
                        Invoice number:
                    </Paragraph>
                    <Input
                        sx={{ height: 50 }}
                        id="invoice-number"
                        name="invoiceNumber"
                        value={localState.invoiceNumber}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                invoiceNumber: e.target.value,
                            })
                        }
                        placeholder="Invoice number"
                    />
                </Box>
            )}

            <Box mb={3}>
                <Paragraph sx={{ mb: 10 }} htmlFor="payable-by">
                    Payable by:
                </Paragraph>
                <Input
                    sx={{ height: 50 }}
                    id="payable-by"
                    type="date"
                    name="payableBy"
                    value={localState.bankDetails.payableBy}
                    onChange={handleBankDetailsChange}
                />
            </Box>

            <Box mb={3}>
                <Paragraph sx={{ mb: 10 }} htmlFor="cols_pdf">
                    Columns on invoice pdf
                </Paragraph>
                <ReactSelect
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '480px',
                            minHeight: '40px',
                            borderRadius: 10,
                            marginBottom: '30px',
                            border: '1px solid #a3a69b',
                            fontSize: '14px',
                        }),
                    }}
                    onChange={async (value) => {
                        updateLocalState({
                            ...localState,
                            pdfColumns: value,
                        });
                    }}
                    isMulti
                    placeholder={'Select the columns'}
                    value={localState.pdfColumns}
                    options={pdfColumnsDefaults}
                />
            </Box>

            <Divider />

            <Box>
                <Flex
                    onClick={(e) =>
                        updateLocalState({
                            ...localState,
                            is_invoice_scheduled: !localState.is_invoice_scheduled,
                        })
                    }
                    sx={{ mt: 20, alignItems: 'center', cursor: 'pointer' }}
                >
                    <Paragraph sx={{ width: 300 }}>Recurring Invoice</Paragraph>
                    <Checkbox checked={localState.is_invoice_scheduled} sx={{ ml: -100 }} />
                </Flex>
            </Box>
            {localState.is_invoice_scheduled ? (
                <Box mb={3}>
                    <InvoiceScheduler updateLocalState={updateLocalState} localState={localState} />
                </Box>
            ) : null}

            <Flex
                sx={{
                    position: 'fixed',
                    height: 80,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    borderTop: '2px solid #EFEFEF',
                    width: '100%',
                    zIndex: 998,
                }}
            >
                <Flex sx={{ ml: 20, alignItems: 'center' }}>
                    <Button
                        sx={{
                            color: '#000',
                            backgroundColor: '#FFF',
                            border: '1px solid lightGrey',
                            height: 40,
                            width: 140,
                            fontSize: 14,
                            mr: 10,
                        }}
                        variant="light"
                        onClick={async () => {
                            if (hasUnsavedChanges) {
                                const confirmLeave = window.confirm(
                                    'You have unsaved changes. Are you sure you want to leave?'
                                );
                                if (!confirmLeave) return;
                            }

                            window.location.assign(`/clients?id=${state.clientInView?.id}&tab=invoices`);
                        }}
                    >
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '12px',
                                marginRight: '6px',
                            }}
                            className={`fal fa-chevron-left`}
                        />
                        Back
                    </Button>
                    <Button
                        sx={{ width: 100, mr: 10, height: 40 }}
                        onClick={async () => {
                            if (localState.is_invoice_scheduled) {
                                const { result, keys } = validateOptions(localState);

                                if (!result) return updateLocalState({ ...localState, errorKeys: keys });
                            }

                            updateLocalState({ ...localState, errorKeys: [] });

                            await generatePdf(
                                state,
                                updateState,
                                lineItems,
                                { ...localState, errorKeys: [] },
                                state.setInvoices,
                                false,
                                true
                            );
                        }}
                        variant="light"
                    >
                        <i style={{ marginRight: '7px' }} className="fa fa-save" />
                        Save
                    </Button>
                    <Button
                        sx={{ width: 200 }}
                        onClick={async () => {
                            await generatePdf(state, updateState, lineItems, localState, state.setInvoices);
                        }}
                    >
                        <i style={{ marginRight: '7px' }} className="fa fa-file-pdf" />
                        Generate Invoice
                    </Button>
                    {state.invoice && (
                        <Button
                            sx={{ ml: 10, width: 200, height: 40 }}
                            variant="light"
                            onClick={async () => {
                                const newState = await generatePdf(
                                    state,
                                    updateState,
                                    lineItems,
                                    localState,
                                    state.setInvoices,
                                    true
                                );

                                if (!newState) return;

                                updateState({ ...newState, showEmailDialog: true });
                            }}
                        >
                            <i style={{ marginRight: '7px' }} className="fa fa-envelope"></i>Email Invoice
                        </Button>
                    )}
                    {state.invoice && (
                        <Button
                            sx={{ ml: 10, width: 200, height: 40 }}
                            variant="light"
                            onClick={async () => {
                                updateState({
                                    ...state,
                                    error: null,
                                    showDeleteInvoiceConfirmation: true,
                                    confirmCallback: async () => {
                                        updateState({ ...state, loading: true });

                                        try {
                                            await axios.delete(
                                                `${process.env.REACT_APP_AQRU_AI_API}/invoices/${state.invoice.uuid}`,
                                                {}
                                            );

                                            window.location.assign(
                                                `/clients?id=${state.clientInView?.id}&tab=invoices`
                                            );
                                        } catch (e) {
                                            updateState({
                                                ...state,
                                                loading: false,
                                            });
                                            toast('We are currently unable to delete your invoice', {
                                                type: 'error',
                                                autoClose: 5000,
                                                position: 'top-right',
                                            });
                                        }
                                    },
                                });
                            }}
                        >
                            <i style={{ marginRight: '7px' }} className="fa fa-trash"></i>Delete Invoice
                        </Button>
                    )}
                    {state.invoice?.last_sent_at ? (
                        <Paragraph sx={{ ml: 20 }}>
                            Emailed at {moment(state.invoice?.last_sent_at).format('HH:mm DD/MM/YYY')}
                        </Paragraph>
                    ) : (
                        <Paragraph sx={{ ml: 20, color: 'red' }}>Not emailed to customer yet</Paragraph>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
};

export default InvoiceOptions;
