/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import * as uuid from 'uuid';
import { debounce } from 'debounce';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Input, Label, Link, Paragraph, Switch, Textarea } from 'theme-ui';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { actions as menuActions } from '../store/reducers/menu';
import { actions as userActions } from '../store/reducers/users';
import { defaultOverlayState, defaultSettings } from '../utils/default-states';
import ConfirmDialog from '../components/tasks/confirm-dialog';
import Divider from '../components/divider';
import Question from '../components/questionnaires/question';
import moment from 'moment';
import ReactSelect from 'react-select';
import theme from '../theme';
import { toast } from 'react-toastify';
import { useUnsavedChangesWarning } from '../hooks/saving';

const defaultState = {
    loading: true,
    partialLoading: false,
    error: null,
};

export const attributeTypes = [
    {
        value: 'date',
        label: 'Date',
    },
    {
        value: 'string',
        label: 'Text',
    },
    {
        value: 'textarea',
        label: 'Text area (multi-line answer)',
    },
    {
        value: 'signature',
        label: 'Signature',
    },
    {
        value: 'address',
        label: 'Address',
    },
    {
        value: 'number',
        label: 'Number',
    },
    {
        value: 'dropdown',
        label: 'Dropdown',
    },
    {
        value: 'file',
        label: 'File Upload',
    },
    {
        value: 'multiple_files',
        label: 'Multiple File Upload',
    },
];

const deleteQuestionnaire = (id, state, updateState) => async () => {
    try {
        updateState({ ...state, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/questionnaires/${id}`,
            method: 'DELETE',
        });

        const {
            data: { questionnaires },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/questionnaires`,
        });

        updateState({
            ...state,
            loading: false,
            error: null,
            questionnaires,
            questionnairesFiltered: questionnaires,
            view: 'list',
        });
    } catch (e) {
        updateState({ ...state, loading: false, error: 'We are currently unable to delete your questionnaire' });
    }
};

const saveQuestionnaire = async (state, updateState, fireAndForgetSave = false) => {
    try {
        if (!fireAndForgetSave) {
            updateState({ ...state, loading: true, error: null });
        }

        const keyValuePairs = {
            name: state.questionnaire?.name,
            definition: state.questionnaire.definition || {},
        };

        const payloadStringified = JSON.stringify(keyValuePairs);

        if (fireAndForgetSave && state.lastPayload && state.lastPayload === payloadStringified) {
            return;
        }

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/questionnaires${
                state.questionnaire?.id ? `/${state.questionnaire?.id}` : ''
            }`,
            method: state.questionnaire?.id ? 'PUT' : 'POST',
            data: keyValuePairs,
        });

        const {
            data: { questionnaires },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/questionnaires`,
        });

        updateState({
            ...state,
            loading: false,
            error: null,
            questionnaires,
            lastPayload: state.questionnaire?.id ? payloadStringified : null,
            questionnairesFiltered: questionnaires,
            questionnaire: {
                ...state.questionnaire,
                updated_at: moment(),
                id: state.questionnaire?.id || data.id,
            },
        });

        toast('Questionnaire saved', { type: 'success' });
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'We are currently unable to save your questionnaire' });
    }
};

const clone = async (questionnaire, state, updateState) => {
    try {
        updateState({ ...state, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/questionnaires`,
            method: 'POST',
            data: {
                name: questionnaire?.name + ' - CLONE',
                definition: questionnaire.definition,
            },
        });

        const {
            data: { questionnaires },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/questionnaires`,
        });

        updateState({
            ...state,
            loading: false,
            error: null,
            questionnaires,
            questionnairesFiltered: questionnaires,
            view: 'list',
        });
    } catch (e) {
        updateState({ ...state, loading: false, error: 'We are currently unable to clone your questionnaire' });
    }
};

export const ConfigureQuestionnaire = ({
    cancelHandler,
    saveHandler,
    state,
    updateState,
    organisation,
    attributeOptions,
    attributeTypes,
    showContactSelector,
    contacts,
}) => {
    if (!state.questionnaire?.definition?.sections) {
        if (!state.questionnaire.definition) {
            state.questionnaire.definition = {};
        }
        state.questionnaire.definition.sections = [{}];
    }

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useUnsavedChangesWarning(hasUnsavedChanges);

    useEffect(() => {
        const keyValuePairs = {
            name: state.questionnaire?.name,
            definition: state.questionnaire.definition || {},
        };

        const payloadStringified = JSON.stringify(keyValuePairs);

        setHasUnsavedChanges(state.lastPayload && state.lastPayload !== payloadStringified);
    }, [state.questionnaire]);

    return (
        <Flex
            sx={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 30,
                flexDirection: 'column',
                width: 1240,
            }}
        >
            <Paragraph sx={{ fontWeight: 600, mt: 10, fontSize: 25 }}>
                {state.questionnaire?.id ? 'Edit' : 'Add new'}
            </Paragraph>

            <Divider />

            <Flex
                sx={{
                    position: 'fixed',
                    height: 80,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    borderTop: '2px solid #EFEFEF',
                    width: '100%',
                    zIndex: 998,
                }}
            >
                <Button
                    variant="light"
                    sx={{ width: 120, height: 40, ml: 20 }}
                    onClick={async () => {
                        const keyValuePairs = {
                            name: state.questionnaire?.name,
                            definition: state.questionnaire.definition || {},
                        };

                        const payloadStringified = JSON.stringify(keyValuePairs);

                        if (state.lastPayload && state.lastPayload !== payloadStringified) {
                            const confirmLeave = window.confirm(
                                'You have unsaved changes. Are you sure you want to leave?'
                            );
                            if (!confirmLeave) return;
                        }

                        cancelHandler
                            ? cancelHandler()
                            : updateState({
                                  ...state,
                                  questionnairesFiltered: state.questionnaires,
                                  questionnaire: {},
                                  view: 'list',
                              });
                    }}
                >
                    <i style={{ marginRight: '7px' }} className="fa fa-chevron-left" />
                    Back
                </Button>
                <Button
                    sx={{ ml: 10, width: 120, height: 40, fontSize: 14 }}
                    onClick={async () => {
                        if (saveHandler) {
                            saveHandler(state, updateState);
                        } else {
                            await saveQuestionnaire(state, updateState);
                        }
                    }}
                >
                    <i style={{ marginRight: '7px' }} className="fa fa-save" />
                    Save
                </Button>
                {state.questionnaire?.updated_at && (
                    <Paragraph sx={{ ml: 20, fontSize: 13 }}>
                        Last Saved: {moment(state.questionnaire?.updated_at).format('HH:mm Do MMMM YYYY')}
                    </Paragraph>
                )}
            </Flex>

            {showContactSelector && (
                <>
                    <Paragraph sx={{ mb: 0, mt: 0 }}>
                        Select the contact you wish to assign the questionnaire to
                    </Paragraph>
                    <ReactSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '400px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={(c) => {
                            updateState({
                                ...state,
                                clientUserToSendTo: c,
                            });
                        }}
                        placeholder="Select contact"
                        value={state?.clientUserToSendTo}
                        options={contacts}
                    />
                </>
            )}

            <Label sx={{ mt: 0 }}>Name</Label>
            <Input
                onChange={(e) =>
                    updateState({
                        ...state,
                        questionnaire: {
                            ...state.questionnaire,
                            name: e.target.value,
                        },
                    })
                }
                value={state.questionnaire?.name}
                sx={{ width: 300, height: 40, mt: 10 }}
            />

            <Label sx={{ mt: 30 }}>Description</Label>
            <Textarea
                rows={5}
                onChange={(e) =>
                    updateState({
                        ...state,
                        questionnaire: {
                            ...state.questionnaire,
                            definition: {
                                ...(state.questionnaire.definition || {}),
                                description: e.target.value,
                            },
                        },
                    })
                }
                value={state.questionnaire?.definition?.description}
                sx={{ width: 500, mt: 10, mb: 20 }}
            />

            <Label id="rel-per-end" sx={{ fontWeight: '400', mt: 20, mb: 10, width: 160 }}>
                Relevant Period End
            </Label>
            <Input
                aria-labelledby="rel-per-end"
                type="date"
                value={state.questionnaire?.definition?.relevant_period_end}
                onChange={(e) =>
                    updateState({
                        ...state,
                        questionnaire: {
                            ...state.questionnaire,
                            definition: {
                                ...(state.questionnaire.definition || {}),
                                relevant_period_end: e.target.value,
                            },
                        },
                    })
                }
                sx={{
                    width: 370,
                    fontWeight: '400',
                    border: `1px solid #a3a69b`,

                    mb: 20,
                }}
            />

            <Flex sx={{ mt: 10, flexDirection: 'column', width: 400, mb: 40 }}>
                <Label id="service-label" sx={{ mr: 30, fontSize: 14 }}>
                    Service
                </Label>
                <ReactSelect
                    aria-labelledby="service-label"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '370px',
                            minHeight: '40px',
                            marginTop: '10px',
                            borderRadius: 10,
                            border: `1px solid #a3a69b`,
                            fontSize: '14px',
                        }),
                    }}
                    onChange={async (c) => {
                        updateState({
                            ...state,
                            questionnaire: {
                                ...state.questionnaire,
                                definition: {
                                    ...(state.questionnaire.definition || {}),
                                    service: c.value,
                                },
                            },
                        });
                    }}
                    placeholder="Select..."
                    value={
                        state.questionnaire.definition?.service
                            ? {
                                  value: state.questionnaire.definition?.service,
                                  label: organisation?.workflow_definition?.services?.[
                                      state.questionnaire.definition?.service
                                  ]?.label,
                              }
                            : null
                    }
                    options={[
                        ...(Object.entries(organisation?.workflow_definition?.services || {})?.map(([key, value]) => ({
                            value: key,
                            label: value.label,
                        })) || []),
                    ]}
                />
            </Flex>

            <Flex sx={{ flexDirection: 'column', width: 400, mb: 40 }}>
                <Label id="reminders-label" sx={{ mr: 30, fontSize: 14 }}>
                    Reminders
                </Label>
                <ReactSelect
                    aria-labelledby="reminders-label"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '370px',
                            minHeight: '40px',
                            marginTop: '10px',
                            borderRadius: 10,
                            border: `1px solid #a3a69b`,
                            fontSize: '14px',
                        }),
                    }}
                    isMulti
                    onChange={async (c) => {
                        updateState({
                            ...state,
                            questionnaire: {
                                ...state.questionnaire,
                                definition: {
                                    ...(state.questionnaire.definition || {}),
                                    reminders: c,
                                },
                            },
                        });
                    }}
                    placeholder="Select..."
                    value={state.questionnaire.definition?.reminders || []}
                    options={[
                        {
                            value: 1,
                            label: '1 Day after',
                        },
                        {
                            value: 2,
                            label: '2 Days after',
                        },
                        {
                            value: 3,
                            label: '3 Days after',
                        },
                        {
                            value: 4,
                            label: '4 Days after',
                        },
                        {
                            value: 5,
                            label: '5 Days after',
                        },
                        {
                            value: 6,
                            label: '6 Days after',
                        },
                        {
                            value: 7,
                            label: '1 week after',
                        },
                        {
                            value: 14,
                            label: '2 weeks after',
                        },
                    ]}
                />
            </Flex>

            <Button
                onClick={() => {
                    updateState({
                        ...state,
                        questionnaire: {
                            ...state.questionnaire,
                            definition: {
                                ...(state.questionnaire.definition || {}),
                                sections: [
                                    ...(state.questionnaire.definition.sections || []),
                                    {
                                        title: '',
                                    },
                                ],
                            },
                        },
                    });
                }}
                variant="light"
                sx={{ mb: 20, width: 150 }}
            >
                Add Section
            </Button>

            {state.questionnaire?.definition?.sections?.map((section, sectionIndex) => (
                <Flex
                    sx={{ flexDirection: 'column', padding: 20, mb: 20, border: '1px solid #CCC', borderRadius: 15 }}
                    key={`section_${sectionIndex}`}
                >
                    <Flex sx={{ alignItems: 'center' }}>
                        <Paragraph sx={{ fontSize: 20, fontWeight: 600 }}>Section {sectionIndex + 1}</Paragraph>
                        <Button
                            onClick={() => {
                                state.questionnaire.definition.sections.splice(sectionIndex, 1);
                                updateState({
                                    ...state,
                                    questionnaire: {
                                        ...state.questionnaire,
                                        definition: {
                                            ...(state.questionnaire.definition || {}),
                                            sections: [...(state.questionnaire.definition.sections || [])],
                                            questions: state.questionnaire.definition.questions?.filter(
                                                (question) => question.sectionIndex !== sectionIndex
                                            ),
                                        },
                                    },
                                });
                            }}
                            variant="light"
                            sx={{ ml: 20, width: 150 }}
                        >
                            Remove Section
                        </Button>
                    </Flex>

                    <Label sx={{ mt: 20 }}>Section Title</Label>
                    <Input
                        onChange={(e) => {
                            const sections = state.questionnaire.definition?.sections || [];
                            sections[sectionIndex].title = e.target.value;
                            updateState({
                                ...state,
                                questionnaire: {
                                    ...state.questionnaire,
                                    definition: {
                                        ...(state.questionnaire.definition || {}),
                                        sections,
                                    },
                                },
                            });
                        }}
                        value={state.questionnaire.definition?.sections?.[sectionIndex]?.title}
                        sx={{ width: 400, height: 40, mt: 10 }}
                    />

                    <Label sx={{ mt: 30, fontSize: 18, fontWeight: 600 }}>Questions</Label>

                    <Divider width="500px" />

                    {state.questionnaire?.definition?.questions
                        ?.map((x, idx) => {
                            x.originalIndex = idx;
                            return x;
                        })
                        ?.filter(
                            (question) => question.sectionIndex === undefined || question.sectionIndex === sectionIndex
                        )
                        .map((question, idx) => (
                            <Question
                                key={`questionnaire_q_${idx}_${sectionIndex}`}
                                idx={idx}
                                state={state}
                                updateState={updateState}
                                organisation={organisation}
                                attributeOptions={attributeOptions}
                                attributeTypes={attributeTypes}
                                question={question}
                            />
                        ))}

                    <Link
                        onClick={() =>
                            updateState({
                                ...state,
                                questionnaire: {
                                    ...state.questionnaire,
                                    definition: {
                                        ...(state.questionnaire.definition || {}),
                                        questions: [
                                            ...(state.questionnaire?.definition?.questions || []),
                                            {
                                                text: '',
                                                type: 'string',
                                                uuid: uuid.v4(),
                                                sectionIndex,
                                            },
                                        ],
                                    },
                                },
                            })
                        }
                        sx={{ color: 'blue', mt: 30, width: 200 }}
                    >
                        Add new question
                    </Link>
                </Flex>
            ))}
        </Flex>
    );
};

export const lookupAttributeOptions = (organisation) => [
    ...Object.entries(organisation?.schema || {})?.map((x) => ({
        value: x[0],
        label: x[1]?.label,
        type: x[1]?.type,
    })),
    { value: 'contact.title', label: 'Contact Title', type: 'string' },
    { value: 'contact.first_name', label: 'Contact First Name', type: 'string' },
    { value: 'contact.middle_name', label: 'Contact Middle Name', type: 'string' },
    { value: 'contact.last_name', label: 'Contact Last Name', type: 'string' },
    { value: 'contact.job_title', label: 'Contact Job Title', type: 'string' },
    { value: 'contact.telephone_number', label: 'Contact Telephone Number', type: 'string' },
    { value: 'contact.address', label: 'Contact Address', type: 'address' },
    { value: 'contact.preferred_name', label: 'Preferred Name', type: 'string' },
];

const Questionnaires = ({ refreshCoreData, logout, userData, accountSummary, dismissTopMenu, organisation }) => {
    const [state, updateState] = useState({
        ...defaultState,
        organisation,
        view: 'list',
        questionnaire: null,
    });
    const [user, updateUser] = useState(userData);
    const [overlay, updateOverlay] = useState(defaultOverlayState);

    const [settingsJourney, updateSettingsJourney] = useState(defaultSettings);
    const navigate = useNavigate();

    const handleSearchByNameChange = debounce(async (e) => {
        updateState({
            ...state,
            questionnairesFiltered: state.questionnaires?.filter((x) =>
                x?.name?.toLowerCase()?.startsWith(e.target.value)
            ),
        });
    }, 500);

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    useEffect(() => {
        updateState({
            ...state,
            organisation,
        });
    }, [organisation]);

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();
                await Auth.currentSession();
                refreshCoreData();

                let additionalStateData = {};
                try {
                    const {
                        data: { questionnaires },
                    } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/questionnaires`);

                    additionalStateData = {
                        questionnaires,
                        questionnairesFiltered: questionnaires,
                    };
                } catch (e) {
                    // swallow
                }

                updateState({
                    ...state,
                    loading: false,
                    ...additionalStateData,
                });
            } catch (e) {
                logout();
                navigate('/');
            }
        })();
    }, []);

    const attributeOptions = lookupAttributeOptions(organisation);

    useEffect(() => {
        const intervalId = setInterval(() => {
            (async () => {
                if (stateRef.current.view !== 'configure') return;

                await saveQuestionnaire(stateRef.current, updateState, true);
            })();
        }, 12000);

        return () => clearInterval(intervalId);
    }, [state.questionnaire, state.questionnaire?.id]);

    return (
        <>
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    width: '100vw',
                    flexDirection: 'column',
                    position: 'relative',
                    backgroundSize: 'cover',
                }}
                data-testid="home-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: '100vw',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar
                        currentPage="Questionnaires"
                        overlay={overlay}
                        accountSummary={accountSummary}
                        user={user}
                        copy={{}}
                        settingsJourney={settingsJourney}
                        updateSettingsJourney={updateSettingsJourney}
                    />

                    {state.loading && <Spinner />}

                    <Flex
                        sx={{
                            minHeight: 'calc(100vh)',
                            borderTopLeftRadius: '25px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: `1px solid ${theme.colors.whiteBlockBorder}`,
                            borderRadius: 20,
                            padding: '30px 30px',
                            minWidth: 370,
                            backgroundColor: 'white',
                            ml: 260,
                            mr: 20,
                        }}
                    >
                        <Flex
                            sx={{
                                flexDirection: 'column',
                            }}
                        >
                            <Flex
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    mb: '30px',
                                }}
                            >
                                <Flex
                                    sx={{
                                        flexDirection: 'column',
                                        width: 1300,
                                    }}
                                >
                                    {state.error && (
                                        <Paragraph
                                            sx={{
                                                color: 'red',
                                                minHeight: '20px',
                                                mt: '20px',
                                                ml: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {state.error}
                                        </Paragraph>
                                    )}

                                    {state.view === 'configure' && (
                                        <ConfigureQuestionnaire
                                            state={state}
                                            updateState={updateState}
                                            organisation={organisation}
                                            attributeOptions={attributeOptions}
                                            attributeTypes={attributeTypes}
                                        />
                                    )}

                                    {state.view === 'list' && (
                                        <Flex
                                            sx={{
                                                paddingLeft: 10,
                                                paddingBottom: 30,
                                                mt: 0,
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {state.showDeleteConfirmation && (
                                                <ConfirmDialog
                                                    updateState={updateState}
                                                    state={state}
                                                    stateKey="showDeleteConfirmation"
                                                    text="Are you sure you wish to delete this questionnaire?"
                                                />
                                            )}
                                            <Paragraph sx={{ fontWeight: 400, fontSize: 25 }}>
                                                Template Questionnaires
                                            </Paragraph>
                                            <Divider mt={10} width={1250} />
                                            <Paragraph sx={{ fontWeight: 400, mt: 0, mb: 20 }}>
                                                Setup templates of questions that can be sent to client portal users to
                                                complete.
                                            </Paragraph>

                                            <Button
                                                onClick={() =>
                                                    updateState({
                                                        ...state,
                                                        view: 'configure',
                                                        questionnaire: {},
                                                        lastPayload: JSON.stringify({}),
                                                    })
                                                }
                                                sx={{ mb: 30, width: 100, height: 40 }}
                                            >
                                                <i
                                                    style={{
                                                        cursor: 'pointer',
                                                        marginRight: '7px',
                                                    }}
                                                    className="fas fa-plus-circle"
                                                    aria-hidden="true"
                                                />
                                                New
                                            </Button>

                                            <Input
                                                sx={{
                                                    mb: '25px',
                                                    width: '350px',
                                                    background: '#FFFFFF',
                                                    border: '1px solid #a3a69b',
                                                    borderRadius: '10px',
                                                    height: '42px',
                                                    fontSize: '14px',
                                                }}
                                                aria-labelledby="fn-label"
                                                type="text"
                                                id="searchName"
                                                data-testid="searchName"
                                                name="searchName"
                                                label=""
                                                placeholder={'Search by questionnaire name'}
                                                onChange={handleSearchByNameChange}
                                                error={null}
                                            />

                                            <table
                                                style={{
                                                    width: 1100,
                                                    marginBottom: 30,
                                                    borderCollapse: 'collpase',
                                                    fontSize: 14,
                                                }}
                                            >
                                                <thead
                                                    style={{
                                                        backgroundColor: theme.colors.primary,
                                                        color: 'white',
                                                        cursor: 'pointer',
                                                        position: 'sticky',
                                                        top: 0,
                                                    }}
                                                >
                                                    <tr>
                                                        <th
                                                            onClick={() => {
                                                                const direction =
                                                                    state.sortByDirections?.[0] === 'ASC'
                                                                        ? 'DESC'
                                                                        : 'ASC';
                                                                updateState({
                                                                    ...state,
                                                                    sortByDirections: [direction],
                                                                    sortByKeys: ['name'],
                                                                });
                                                            }}
                                                            style={{ width: 250, textAlign: 'left', padding: 20 }}
                                                        >
                                                            Name
                                                        </th>
                                                        <th style={{ width: 250, textAlign: 'left', padding: 20 }}>
                                                            Description
                                                        </th>
                                                        <th style={{ textAlign: 'left', padding: 20 }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(state.sortByDirections?.[0]
                                                        ? state.questionnairesFiltered?.sort((x, y) => {
                                                              if (state.sortByDirections?.[0] === 'DESC') {
                                                                  return y.name
                                                                      ?.toLowerCase()
                                                                      ?.localeCompare(x.name?.toLowerCase());
                                                              }

                                                              return x.name
                                                                  ?.toLowerCase()
                                                                  ?.localeCompare(y.name?.toLowerCase());
                                                          })
                                                        : state.questionnairesFiltered
                                                    )?.map((questionnaire) => (
                                                        <tr style={{}} key={questionnaire.id}>
                                                            <td
                                                                style={{
                                                                    width: 200,
                                                                    padding: 20,
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {questionnaire.name}
                                                            </td>
                                                            <td style={{ width: 200, padding: 20 }}>
                                                                {questionnaire.definition?.description || ''}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: 10,
                                                                    padding: 20,
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <Flex sx={{ justifyContent: 'space-between' }}>
                                                                    <Button
                                                                        variant="light"
                                                                        onClick={() => {
                                                                            updateState({
                                                                                ...state,
                                                                                view: 'configure',
                                                                                questionnaire,
                                                                                lastPayload:
                                                                                    JSON.stringify(questionnaire),
                                                                            });
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize: '12px',
                                                                                cursor: 'pointer',
                                                                                marginRight: '7px',
                                                                            }}
                                                                            className="fas fa-pencil"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Edit
                                                                    </Button>
                                                                    <Button
                                                                        variant="light"
                                                                        onClick={async () => {
                                                                            await clone(
                                                                                questionnaire,
                                                                                state,
                                                                                updateState
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize: '12px',
                                                                                cursor: 'pointer',
                                                                                marginRight: '7px',
                                                                            }}
                                                                            className="fas fa-clone"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Clone
                                                                    </Button>
                                                                    <Button
                                                                        title="Delete"
                                                                        onClick={() =>
                                                                            updateState({
                                                                                ...state,
                                                                                showDeleteConfirmation: true,
                                                                                confirmCallback: deleteQuestionnaire(
                                                                                    questionnaire.id,
                                                                                    state,
                                                                                    updateState
                                                                                ),
                                                                            })
                                                                        }
                                                                        variant="light"
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize: '12px',
                                                                                cursor: 'pointer',
                                                                                color: 'red',
                                                                                marginRight: '7px',
                                                                            }}
                                                                            className="fal fa-trash"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Delete
                                                                    </Button>
                                                                </Flex>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaires);
