import { useEffect, useState } from 'react';
import { Box, Flex, Image } from 'theme-ui';
import Snow from './snow';
import moment from 'moment';

const MainLogo = ({ organisation, sxContainer }) => {
    const [logo, updateLogo] = useState(organisation?.default_visualisation?.logo);
    useEffect(() => {
        updateLogo(organisation?.default_visualisation?.logo);
    }, [organisation]);

    return (
        <Flex sx={{ alignItems: 'center', ...(sxContainer || {}) }}>
            {/* {isDecember ? <Snow /> : null} */}
            <Image
                sx={{ display: ['none', 'none', 'block'], width: 220, mb: 0, ml: 0 }}
                src={'https://cdn.accountingflow.io/af/logo-dark.png'}
                alt=""
                onClick={() => window.location.assign('/')}
            />
            {/* {organisation && isDecember ? <div className="santa-hat" /> : null} */}
            <Image
                sx={{ display: ['block', 'block', 'none'], width: 60, mb: 0, ml: 0 }}
                src={'https://cdn.accountingflow.io/af/icon-dark.png'}
                alt=""
                onClick={() => window.location.assign('/')}
            />

            {logo && (
                <Box sx={{ display: ['none', 'block'] }}>
                    <Flex sx={{ alignItems: 'center' }}>
                        <i
                            style={{
                                marginLeft: 10,
                                marginRight: 15,
                                fontSize: '23px',
                                cursor: 'pointer',
                            }}
                            className="fal fa-handshake"
                            aria-hidden="true"
                        />
                        <Image sx={{ maxWidth: 200, maxHeight: 80, ml: 0 }} src={logo} alt="" />
                    </Flex>
                </Box>
            )}
        </Flex>
    );
};

export default MainLogo;
