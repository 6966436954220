/* eslint-disable no-undef */
import axios from 'axios';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { Box, Button, Checkbox, Flex, Label, Paragraph, IconButton, Heading, Switch } from 'theme-ui';
import * as uuid from 'uuid';
import { activityTypes, buildDateRange } from '../../pages/timesheets';
import Divider from '../divider';

// Define all possible columns for the expanded view
const ALL_COLUMNS = [
    { id: 'date', required: true, label: 'Date', lookup: (entry) => moment(entry.date).toDate().getTime() },
    {
        id: 'client_employee',
        label: 'Client/Employee',
        required: true,
        lookup: (entry) => entry.timeWeekEntry?.client?.name,
    },
    { id: 'task', label: 'Task', lookup: (entry) => entry.timeWeekEntry.task?.title },
    { id: 'service', required: true, label: 'Service', lookup: (entry) => entry.timeWeekEntry.service },
    { id: 'description', required: true, label: 'Description', lookup: (entry) => entry.timeWeekEntry.description },
    {
        id: 'time_spent',
        required: true,
        label: 'Time Spent',
        lookup: (entry) => parseFloat(entry.timeSpent),
    },
    {
        id: 'hourly_rate',
        required: true,
        label: 'Hourly Rate',
        lookup: (entry) => parseFloat(entry.timeWeekEntry.billableAmount),
    },
    {
        id: 'amount',
        label: 'Amount',
        required: true,
        lookup: (entry) =>
            new Decimal(entry.timeWeekEntry.billableAmount)
                .times(new Decimal(entry.timeSpent).div(new Decimal(60)))
                .toNumber(),
    },
    { id: 'status', label: 'Status', required: true, lookup: (entry) => entry.timeWeekEntry.status },
];

export const handleCreateInvoice = async (timeEntries, groupByService, clientUuid, onClose) => {
    try {
        const { data } = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_AQRU_AI_API}/invoices/generate-from-time-entries`,
            data: {
                time_entry_ids: timeEntries.map((x) => x.id),
                group_by_service: groupByService,
                client_uuid: clientUuid,
            },
        });
        window.open(data.invoice_url, '_blank');
        if (onClose) {
            onClose();
        }
    } catch (ex) {
        toast.error(ex?.response?.data?.message || 'Something has gone wrong');
    }
};

const InvoiceOptionsModal = ({ isOpen, onClose, timeEntries, clientUuid }) => {
    const [groupByService, setGroupByService] = useState(false);

    if (!isOpen) return null;

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 100000000000000000,
            }}
            onClick={onClose}
        >
            <Box
                onClick={(e) => e.stopPropagation()}
                sx={{
                    bg: 'background',
                    borderRadius: 4,
                    p: 4,
                    width: ['90%', '500px'],
                    maxWidth: '500px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Paragraph sx={{ mb: 3, fontSize: 18 }}>Create Invoice</Paragraph>

                <Divider />

                <Flex sx={{ mb: 4 }}>
                    <Paragraph sx={{ width: 200 }}>Group by service</Paragraph>
                    <Switch
                        id="group-by-service"
                        checked={groupByService}
                        onChange={(e) => setGroupByService(e.target.checked)}
                    />
                </Flex>

                <Flex sx={{ justifyContent: 'flex-end', gap: 2 }}>
                    <Button variant="light" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => handleCreateInvoice(timeEntries, groupByService, clientUuid, onClose)}>
                        Create Invoice
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
};

// Column settings local storage key
const COLUMN_SETTINGS_KEY = 'expandable_view_column_settings_v2';

const loadDetailedList = async (idx, state, updateState, returnState = false) => {
    try {
        updateState({ ...state, loading: true, error: null });

        let { fromDate, toDate } = buildDateRange(state);

        const summaryGrouping = state.summaryGrouping || 'employee';

        const {
            data: { entries: summaryEntries },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/summary`,
            params: {
                summary_grouping: summaryGrouping,
                from: fromDate,
                to: toDate,
                status: state.statusSelected?.value || 'locked,open,written_off',
                group_by_service: '1',
                ...(summaryGrouping === 'client'
                    ? { client_uuid: state.client }
                    : { org_user_id: state.user.org_user_id }),
                billing_status: state.billingStatus?.value || 'unbilled',
            },
        });

        const newState = {
            ...state,
            loading: false,
            error: null,
            allEntriesSelected: false,
        };

        if (!newState.detailedTimeEntries) {
            newState.detailedTimeEntries = {};
        }

        newState.detailedTimeEntries[idx] = summaryEntries
            ?.filter((x) => parseFloat(x.timeSpent) > 0)
            ?.map((x) => ({ ...x, uiKey: uuid.v4() }));

        if (returnState) {
            return newState;
        }

        updateState(newState);
    } catch (e) {
        console.log(e);

        updateState({ ...state, loading: false });
        toast.error(e?.response?.data?.message);
    }
};

const ExpandableFlexLayout = ({
    state,
    updateState,
    convertMinutesToHours,
    updateStatus,
    buildDateRange,
    timeStatuses,
}) => {
    // Track which rows are expanded
    const [expandedRows, setExpandedRows] = useState({});

    // Column configuration state
    const [columns, setColumns] = useState([]);
    const [showColumnConfig, setShowColumnConfig] = useState(false);

    // Track if any dropdown is open (to prevent z-index conflicts)
    const [isAnyDropdownOpen, setIsAnyDropdownOpen] = useState(false);

    // Load column settings from localStorage on initial render
    useEffect(() => {
        try {
            const savedColumns = localStorage.getItem(COLUMN_SETTINGS_KEY);
            if (savedColumns) {
                setColumns(JSON.parse(savedColumns));
            } else {
                // Default configuration - all columns enabled
                setColumns(ALL_COLUMNS.map((col) => ({ ...col, visible: col.required })));
            }
        } catch (err) {
            console.error('Error loading column settings from localStorage:', err);
            setColumns(ALL_COLUMNS.map((col) => ({ ...col, visible: col.required })));
        }
    }, []);

    // Save column settings to localStorage when they change
    useEffect(() => {
        if (columns.length > 0) {
            localStorage.setItem(COLUMN_SETTINGS_KEY, JSON.stringify(columns));
        }
    }, [columns]);

    useEffect(() => {
        setExpandedRows({});
        updateState({ ...state, detailedTimeEntries: {} });
    }, [state.summaryGrouping]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Toggle row expansion
    const toggleRowExpansion = (idx) => {
        setExpandedRows((prev) => ({
            ...prev,
            [idx]: !prev[idx],
        }));
    };

    // Select/deselect all entries
    const toggleAllEntries = () => {
        const newAllSelected = !state.allEntriesSelected;
        updateState({
            ...state,
            allEntriesSelected: newAllSelected,
            summaryEntries: state.summaryEntries?.map((item) => ({
                ...item,
                checked: newAllSelected,
            })),
        });
    };

    // Select/deselect a single entry
    const toggleEntry = (idx) => {
        state.summaryEntries[idx].checked = !state.summaryEntries[idx].checked;
        updateState({
            ...state,
            summaryEntries: [...state.summaryEntries],
        });
    };

    // Toggle column visibility
    const toggleColumnVisibility = (columnId) => {
        const columnToToggle = columns.find((col) => col.id === columnId);

        // Don't allow toggling off required columns
        if (columnToToggle?.required) {
            return;
        }

        setColumns(columns.map((col) => (col.id === columnId ? { ...col, visible: !col.visible } : col)));
    };

    // Reset columns to default (all visible)
    const resetColumnSettings = () => {
        setColumns(ALL_COLUMNS.map((col) => ({ ...col, visible: col.required })));
    };

    // Handle column config toggle (close if any dropdowns are open)
    const handleToggleColumnConfig = () => {
        if (isAnyDropdownOpen) {
            return; // Don't toggle column config if dropdowns are open
        }
        setShowColumnConfig(!showColumnConfig);
    };

    // Close column config when a dropdown opens
    useEffect(() => {
        if (isAnyDropdownOpen && showColumnConfig) {
            setShowColumnConfig(false);
        }
    }, [isAnyDropdownOpen]);

    // Handle drill-in for client view
    const handleClientDrillIn = async (item, idx) => {
        if (expandedRows[idx]) {
            toggleRowExpansion(idx);
            updateState({
                ...state,
                selectedTimeEntriesByClient: {},
            });
            return;
        }

        updateState({
            ...state,
            client: item.uuid,
        });
        await loadDetailedList(
            idx,
            {
                ...state,
                client: item.uuid,
            },
            updateState
        );

        toggleRowExpansion(idx);
    };

    // Handle drill-in for user view
    const handleUserDrillIn = async (item, idx) => {
        if (state.summaryGrouping === 'client') return;

        if (expandedRows[idx]) {
            toggleRowExpansion(idx);
            return;
        }

        updateState({
            ...state,
            user: item,
        });

        await loadDetailedList(idx, { ...state, user: item }, updateState);

        toggleRowExpansion(idx);
    };

    // Handle status change
    const handleStatusChange = async (c, item) => {
        const { fromDate, toDate } = buildDateRange(state);

        await updateStatus(
            [
                {
                    status: c.value,
                    user_id: item.org_user_id,
                    from: fromDate,
                    to: toDate,
                },
            ],
            state,
            updateState
        );
    };

    // Get visible columns
    const getVisibleColumns = () => {
        return columns.filter((col) => col.visible);
    };

    // Column configuration panel
    const ColumnConfigPanel = () => (
        <Box
            sx={{
                position: 'absolute',
                top: '60px',
                right: '20px',
                width: '350px',
                bg: 'white',
                p: 3,
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                borderRadius: '8px',
                zIndex: 1000000000,
                backgroundColor: 'white', // Explicit background color
                opacity: 1,
            }}
        >
            <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Paragraph sx={{ fontWeight: 'bold' }}>Configure Columns</Paragraph>
                <Button variant="text" onClick={() => setShowColumnConfig(false)} sx={{ p: 1 }}>
                    <i className="fas fa-times" />
                </Button>
            </Flex>

            {columns.map((col) => (
                <Flex
                    onClick={() => toggleColumnVisibility(col.id)}
                    key={col.id}
                    sx={{ mb: 2, alignItems: 'center', zIndex: 1000000000 }}
                >
                    <Checkbox id={`col-${col.id}`} checked={col.visible} disabled={col.required} />
                    <Label
                        htmlFor={`col-${col.id}`}
                        sx={{ ml: 2, fontSize: 1, cursor: 'pointer', color: col.required ? 'gray' : 'text' }}
                    >
                        {col.label} {col.required ? ' - required' : ''}
                    </Label>
                </Flex>
            ))}

            <Button variant="outline" onClick={resetColumnSettings} sx={{ mt: 2, fontSize: 1 }}>
                Reset to Default
            </Button>
        </Box>
    );

    const selectStyles = {
        control: (provided) => ({
            ...provided,
            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
            width: '180px',
            minHeight: '40px',
            borderRadius: 10,
            fontSize: '14px',
            border: '1px solid #a3a69b',
            zIndex: 1499,
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 1500, // Higher z-index for dropdown menu
        }),
    };

    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });

    const sortedEntries = (entries) => {
        if (!sortConfig.key) return entries;

        return [...entries].sort((a, b) => {
            const column = ALL_COLUMNS.find((col) => col.id === sortConfig.key);
            let aValue = column.lookup(a);
            let bValue = column.lookup(b);

            // Handle null or undefined values
            if (aValue == null && bValue == null) return 0;
            if (aValue == null) return sortConfig.direction === 'asc' ? -1 : 1;
            if (bValue == null) return sortConfig.direction === 'asc' ? 1 : -1;

            // Convert to lowercase strings if string type
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                // Use localeCompare for proper string comparison
                return sortConfig.direction === 'asc'
                    ? aValue.toLowerCase().localeCompare(bValue.toLowerCase())
                    : bValue.toLowerCase().localeCompare(aValue.toLowerCase());
            }

            // For non-string values, use standard comparison
            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    return (
        <Box
            sx={{
                borderRadius: '8px',
                mt: 4,
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                position: 'relative',
            }}
        >
            <InvoiceOptionsModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                timeEntries={state.timeEntries}
                clientUuid={state.clientUuid}
            />
            {/* Header */}
            <Flex
                sx={{
                    bg: 'primary',
                    color: 'white',
                    p: 3,
                    fontWeight: 'bold',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ width: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Label sx={{ mb: 0 }}>
                        <Checkbox
                            checked={state.allEntriesSelected}
                            onChange={(e) => {
                                e.stopPropagation();
                                toggleAllEntries(e);
                            }}
                            sx={{
                                bg: 'white',
                                '&:focus': {
                                    outline: 'none',
                                    boxShadow: 'none',
                                },
                            }}
                        />
                        <span style={{ display: 'none' }}>Select All</span>
                    </Label>
                </Box>
                {state.summaryGrouping === 'client' && <Box sx={{ flex: 1 }}>Client Name</Box>}
                {state.summaryGrouping === 'employee' && <Box sx={{ flex: 1 }}>Employee Name</Box>}
                <Box sx={{ flex: 1 }}>Amount</Box>
                <Box sx={{ flex: 1 }}>Total Time</Box>
                <Box sx={{ width: '40px' }}></Box> {/* Space for expand/collapse icon */}
                {/* Column configuration button */}
                <Box sx={{ ml: 2 }}>
                    <Button
                        variant="outline"
                        onClick={handleToggleColumnConfig}
                        sx={{
                            bg: 'white',
                            color: 'primary',
                            p: 1,
                            fontSize: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <i className="fas fa-columns" style={{ fontSize: '14px' }} />
                        <span>Columns</span>
                    </Button>
                </Box>
                {/* Column Configuration Panel */}
                {showColumnConfig && !isAnyDropdownOpen && <ColumnConfigPanel />}
            </Flex>

            {/* Rows */}
            {state.summaryEntries?.map((item, idx) => (
                <Box
                    key={`summ_${idx}`}
                    onClick={() =>
                        state.summaryGrouping === 'client'
                            ? handleClientDrillIn(item, idx)
                            : handleUserDrillIn(item, idx)
                    }
                >
                    {/* Main row */}
                    <Flex
                        sx={{
                            alignItems: 'center',
                            p: 3,
                            borderColor: 'muted',
                            cursor: 'pointer',
                            bg: idx % 2 === 0 ? 'backgroundMuted' : 'background',
                            '&:hover': {
                                bg: 'highlight',
                            },
                        }}
                    >
                        <Box sx={{ width: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Label sx={{ mb: 0 }}>
                                <div onClick={(e) => e.stopPropagation()}>
                                    <Checkbox
                                        checked={item.checked}
                                        onChange={(e) => {
                                            toggleEntry(idx);
                                        }}
                                        sx={{
                                            '&:focus': {
                                                outline: 'none',
                                                boxShadow: 'none',
                                            },
                                        }}
                                    />
                                </div>
                            </Label>
                        </Box>

                        {state.summaryGrouping === 'client' && (
                            <Box sx={{ flex: 1 }}>
                                <Paragraph sx={{ color: 'primary', cursor: 'pointer' }}>{item.name}</Paragraph>
                            </Box>
                        )}

                        {state.summaryGrouping === 'employee' && (
                            <Box sx={{ flex: 1 }}>
                                <Paragraph
                                    sx={{
                                        color: state.summaryGrouping === 'employee' ? 'text' : 'primary',
                                        cursor: state.summaryGrouping === 'employee' ? 'default' : 'pointer',
                                    }}
                                >
                                    {item.first_name ? item.first_name + ' ' + item.last_name : ''}
                                </Paragraph>
                            </Box>
                        )}

                        <Box sx={{ flex: 1 }}>
                            <Paragraph>
                                {new Intl.NumberFormat('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(parseFloat(item.billable_amount))}
                            </Paragraph>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Paragraph>{convertMinutesToHours(item.total_time_spent || 0)}</Paragraph>
                        </Box>

                        <Box
                            sx={{
                                width: '120px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <i className={expandedRows[idx] ? 'fas fa-chevron-down' : 'fas fa-chevron-right'} />
                        </Box>
                    </Flex>

                    {/* Expanded content */}
                    {expandedRows[idx] && (
                        <Box
                            sx={{
                                p: 2,
                                bg: 'backgroundAlt',
                                borderColor: 'muted',
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {state.selectedTimeEntriesByClient?.[item.uuid]?.length ? (
                                <Button
                                    variant="light"
                                    sx={{ width: 180, mb: 10 }}
                                    onClick={async () => {
                                        const groupedByService = state.selectedTimeEntriesByClient?.[item.uuid]?.reduce(
                                            (acc, curr) => {
                                                if (!acc[curr.timeWeekEntry.service]) {
                                                    acc[curr.timeWeekEntry.service] = [];
                                                }
                                                acc[curr.timeWeekEntry.service].push(curr);
                                                return acc;
                                            },
                                            {}
                                        );

                                        if (!Object.values(groupedByService).find((x) => x.length > 1)) {
                                            await handleCreateInvoice(
                                                state.selectedTimeEntriesByClient?.[item.uuid],
                                                false,
                                                item.uuid
                                            );
                                            return;
                                        }

                                        updateState({
                                            ...state,
                                            clientUuid: item.uuid,
                                            timeEntries: state.selectedTimeEntriesByClient?.[item.uuid],
                                        });
                                        setIsModalOpen(true);
                                    }}
                                >
                                    <i className="fal fa-external-link" style={{ marginRight: '7px' }} />
                                    Create invoice
                                </Button>
                            ) : null}

                            <table
                                style={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    fontSize: '14px',
                                    marginBottom: 10,
                                }}
                            >
                                <thead>
                                    <tr style={{ backgroundColor: '#FAFAFA', cursor: 'pointer' }}>
                                        <th></th>
                                        {getVisibleColumns().map((column) => {
                                            // Special handling for the client/employee column which changes based on summaryGrouping
                                            if (column.id === 'client_employee') {
                                                return (
                                                    <th
                                                        onClick={() => {
                                                            const direction =
                                                                sortConfig.key === column.id &&
                                                                sortConfig.direction === 'asc'
                                                                    ? 'desc'
                                                                    : 'asc';
                                                            setSortConfig({ key: column.id, direction });
                                                        }}
                                                        key={column.id}
                                                        style={{
                                                            padding: '10px',
                                                            textAlign: 'left',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {state.summaryGrouping === 'employee'
                                                            ? 'Client/Activity'
                                                            : 'Employee'}{' '}
                                                        {sortConfig.key === column.id ? (
                                                            sortConfig.direction === 'asc' ? (
                                                                <i
                                                                    className="fal fa-chevron-up"
                                                                    style={{ marginLeft: '2px' }}
                                                                />
                                                            ) : (
                                                                <i
                                                                    className="fal fa-chevron-down"
                                                                    style={{ marginLeft: '2px' }}
                                                                />
                                                            )
                                                        ) : (
                                                            ''
                                                        )}
                                                    </th>
                                                );
                                            }
                                            return (
                                                <th
                                                    onClick={() => {
                                                        const direction =
                                                            sortConfig.key === column.id &&
                                                            sortConfig.direction === 'asc'
                                                                ? 'desc'
                                                                : 'asc';
                                                        setSortConfig({ key: column.id, direction });
                                                    }}
                                                    key={column.id}
                                                    style={{ padding: '10px', textAlign: 'left' }}
                                                >
                                                    {column.label}{' '}
                                                    {sortConfig.key === column.id ? (
                                                        sortConfig.direction === 'asc' ? (
                                                            <i
                                                                className="fal fa-chevron-up"
                                                                style={{ marginLeft: '2px' }}
                                                            />
                                                        ) : (
                                                            <i
                                                                className="fal fa-chevron-down"
                                                                style={{ marginLeft: '2px' }}
                                                            />
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedEntries(state.detailedTimeEntries?.[idx] || [])?.map((entry, entryIdx) => (
                                        <tr key={`entry_${entryIdx}`} style={{ borderBottom: '1px solid #eee' }}>
                                            <td
                                                key={`expanded_entry_checkbox_${entryIdx}`}
                                                style={{
                                                    padding: '10px',
                                                    verticalAlign: 'top',
                                                    paddingTop: 20,
                                                }}
                                            >
                                                {entry.status === 'locked' && state.summaryGrouping === 'client' ? (
                                                    <Flex
                                                        sx={{ ml: 10 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();

                                                            updateState({
                                                                ...state,
                                                                selectedTimeEntriesByClient:
                                                                    state.selectedTimeEntriesByClient?.[
                                                                        entry?.timeWeekEntry?.client?.uuid
                                                                    ]?.find((x) => x.id === entry.id)
                                                                        ? {
                                                                              ...state.selectedTimeEntriesByClient,
                                                                              [entry?.timeWeekEntry?.client?.uuid]:
                                                                                  state.selectedTimeEntriesByClient?.[
                                                                                      entry?.timeWeekEntry?.client?.uuid
                                                                                  ]?.filter((x) => x.id !== entry.id),
                                                                          }
                                                                        : {
                                                                              ...state.selectedTimeEntriesByClient,
                                                                              [entry?.timeWeekEntry?.client?.uuid]: [
                                                                                  ...(state
                                                                                      .selectedTimeEntriesByClient?.[
                                                                                      entry?.timeWeekEntry?.client?.uuid
                                                                                  ] || []),
                                                                                  entry,
                                                                              ],
                                                                          },
                                                            });
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={state.selectedTimeEntriesByClient?.[
                                                                entry?.timeWeekEntry?.client?.uuid
                                                            ]?.find((x) => x.id === entry.id)}
                                                            sx={{
                                                                bg: 'white',
                                                                '&:focus': {
                                                                    outline: 'none',
                                                                    boxShadow: 'none',
                                                                },
                                                            }}
                                                        />
                                                    </Flex>
                                                ) : null}
                                            </td>
                                            {getVisibleColumns().map((column) => {
                                                switch (column.id) {
                                                    case 'client_employee':
                                                        if (state.summaryGrouping === 'employee') {
                                                            return (
                                                                <td
                                                                    key={column.id}
                                                                    style={{
                                                                        padding: '10px',
                                                                        verticalAlign: 'top',
                                                                        paddingTop: 20,
                                                                    }}
                                                                >
                                                                    {entry.timeWeekEntry?.client?.name ||
                                                                        activityTypes?.find(
                                                                            (x) =>
                                                                                x.value ===
                                                                                entry.timeWeekEntry?.activityType
                                                                        )?.label ||
                                                                        ''}
                                                                </td>
                                                            );
                                                        } else {
                                                            return (
                                                                <td
                                                                    key={column.id}
                                                                    style={{
                                                                        padding: '10px',
                                                                        verticalAlign: 'top',
                                                                        paddingTop: 20,
                                                                    }}
                                                                >
                                                                    {entry.belongsTo?.firstName
                                                                        ? entry.belongsTo?.firstName +
                                                                          ' ' +
                                                                          entry.belongsTo?.lastName
                                                                        : ''}
                                                                </td>
                                                            );
                                                        }
                                                    case 'task':
                                                        return (
                                                            <td
                                                                key={column.id}
                                                                style={{
                                                                    padding: '10px',
                                                                    verticalAlign: 'top',
                                                                    paddingTop: 20,
                                                                    width: 130,
                                                                }}
                                                            >
                                                                {entry.timeWeekEntry?.task?.title}
                                                            </td>
                                                        );
                                                    case 'service':
                                                        return (
                                                            <td
                                                                key={column.id}
                                                                style={{
                                                                    padding: '10px',
                                                                    verticalAlign: 'top',
                                                                    paddingTop: 20,
                                                                }}
                                                            >
                                                                {entry.timeWeekEntry?.service &&
                                                                    state.organisation?.workflow_definition?.services?.[
                                                                        entry.timeWeekEntry?.service
                                                                    ]?.label}
                                                            </td>
                                                        );
                                                    case 'date':
                                                        return (
                                                            <td
                                                                key={column.id}
                                                                style={{
                                                                    padding: '10px',
                                                                    verticalAlign: 'top',
                                                                    whiteSpace: 'nowrap',
                                                                    paddingTop: 20,
                                                                }}
                                                            >
                                                                {moment
                                                                    .utc(entry.date, 'YYYY-MM-DDTHH:mm:ssZ')
                                                                    .format('Do MMMM YYYY')}
                                                            </td>
                                                        );
                                                    case 'description':
                                                        return (
                                                            <td
                                                                key={column.id}
                                                                style={{
                                                                    padding: '10px',
                                                                    verticalAlign: 'top',
                                                                    maxWidth: '300px',
                                                                    paddingTop: 20,
                                                                }}
                                                            >
                                                                {entry.timeWeekEntry?.description}
                                                            </td>
                                                        );
                                                    case 'time_spent':
                                                        return (
                                                            <td
                                                                key={column.id}
                                                                style={{
                                                                    padding: '10px',
                                                                    verticalAlign: 'top',
                                                                    whiteSpace: 'nowrap',
                                                                    paddingTop: 20,
                                                                }}
                                                            >
                                                                {convertMinutesToHours(entry.timeSpent)}
                                                            </td>
                                                        );
                                                    case 'hourly_rate':
                                                        return (
                                                            <td
                                                                key={column.id}
                                                                style={{
                                                                    padding: '10px',
                                                                    verticalAlign: 'top',
                                                                    whiteSpace: 'nowrap',
                                                                    paddingTop: 20,
                                                                }}
                                                            >
                                                                {entry.timeWeekEntry.billableAmount}
                                                            </td>
                                                        );
                                                    case 'amount':
                                                        return (
                                                            <td
                                                                key={column.id}
                                                                style={{
                                                                    padding: '10px',
                                                                    verticalAlign: 'top',
                                                                    whiteSpace: 'nowrap',
                                                                    paddingTop: 20,
                                                                }}
                                                            >
                                                                {new Decimal(entry.timeWeekEntry.billableAmount)
                                                                    .times(
                                                                        new Decimal(entry.timeSpent).div(
                                                                            new Decimal(60)
                                                                        )
                                                                    )
                                                                    .toNumber()
                                                                    .toFixed(2)}
                                                            </td>
                                                        );
                                                    case 'status':
                                                        return (
                                                            <td
                                                                key={column.id}
                                                                style={{ padding: '10px', verticalAlign: 'top' }}
                                                            >
                                                                <div onClick={(e) => e.stopPropagation()}>
                                                                    <ReactSelect
                                                                        styles={selectStyles}
                                                                        onMenuOpen={() => setIsAnyDropdownOpen(true)}
                                                                        onMenuClose={() => setIsAnyDropdownOpen(false)}
                                                                        // menuPortalTarget={document.body}
                                                                        // menuPosition="fixed"
                                                                        onChange={async (c) => {
                                                                            await updateStatus(
                                                                                [
                                                                                    {
                                                                                        status:
                                                                                            entry.status === 'locked'
                                                                                                ? 'open'
                                                                                                : 'locked',
                                                                                        id: entry.id,
                                                                                    },
                                                                                ],
                                                                                state,
                                                                                updateState
                                                                            );
                                                                            updateState({
                                                                                ...state,
                                                                                detailedTimeEntries: {
                                                                                    ...state.detailedTimeEntries,
                                                                                    [idx]: state.detailedTimeEntries[
                                                                                        idx
                                                                                    ].map((x) => {
                                                                                        if (x.id === entry.id) {
                                                                                            return {
                                                                                                ...x,
                                                                                                status: c.value,
                                                                                            };
                                                                                        }
                                                                                        return x;
                                                                                    }),
                                                                                },
                                                                            });
                                                                        }}
                                                                        placeholder="Set status"
                                                                        value={timeStatuses?.find(
                                                                            (x) => x.value === entry.status
                                                                        )}
                                                                        options={timeStatuses}
                                                                    />
                                                                </div>
                                                            </td>
                                                        );
                                                    default:
                                                        return null;
                                                }
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default ExpandableFlexLayout;
